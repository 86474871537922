<div class="container" style="height: 90vh">
  <app-loading></app-loading>
  <app-site-error></app-site-error>

  <div *ngIf="(status$ | async) as status">
     <div *ngIf="status.open_registration == 1">
        <ng-container *ngIf="registered; then checkEmail; else registerForm"></ng-container> 
     </div>
     <div *ngIf="status.open_registration == 0">
        <div class="row">
           <div class="col-6 m-auto py-5 text-center">
            <h4>New user registration temporarily closed</h4>
           </div>
        </div>

     </div>

  </div>

</div>



<ng-template #registerForm>
  <form class="col-6 m-auto py-5" [formGroup]="SignUpForm" >
    <div class="mb-3 row">
      <label for="newEmail" class="form-label fw-bold col-4">Email address:</label>
      <div class="col-8">
        <input type="email" class="form-control col-10" id="newEmail" aria-describedby="email" formControlName="newEmail">

        <div *ngIf="getFormControlError( SignUpForm, 'newEmail', submitted ); let errors"> 
          <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
            {{ error.value }} 
          </div>
        </div>

      </div>
    </div>
    <div class="mb-3 row">
      <label for="newAccName" class="form-label fw-bold col-4">Account Name:</label>
      <div class="col-8">
        <input type="text" class="form-control" id="newAccName" formControlName="newAccName">

        <div *ngIf="getFormControlError( SignUpForm, 'newAccName', submitted ); let errors"> 
          <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
            {{ error.value }} 
          </div>
        </div>

      </div>
    </div>
    <!--
    <div class="mb-3 row">
      <label for="newfname" class="form-label fw-bold col-4">First Name:</label>
      <div class="col-8">
        <input type="text" class="form-control" id="newfname" formControlName="newFName">
      </div>
    </div>
    <div class="mb-3 row">
      <label for="newlname" class="form-label fw-bold col-4">Last Name:</label>
      <div class="col-8">
        <input type="text" class="form-control" id="newlname" formControlName="newLName">
      </div>
    </div>
    -->
    <button type="submit" addDeBounce (deBounceClick)="doSignUp()"  class="btn btn-primary float-end" >Sign Up</button>
  </form>
</ng-template>

<ng-template #checkEmail>
  <div class="col-6 m-auto py-5 text-center">
   <h4>Check your email for activation link!</h4>
  </div>
</ng-template>
