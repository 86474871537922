import { BaseApiObject } from "../../../shared/model/base-api-object"; 
import { WithAttributes } from "../mixins/with-attributes.mixin";

export class Host extends WithAttributes(BaseApiObject) {

   public hostname!: string;
   public status!: string;
   public notes!: string;
   public ip_address!: string;
   public site!: string;
   public jobs!: Array<Object>
   public last_checkin!: string;
   
   public is_stub: boolean = false;

   public override get onCreate() { 
      return ['hostname'] 
   };

   public override get writable() { 
      return ['hostname','status','notes', this.updateAttributes.bind(this) ] 
   };
   public override writableAttributes = ['uri_key'];

   get last_checkin_diff(): string {
       if (this.last_checkin) {
          let checkInDateTime = new Date(this.last_checkin);
          let currentDateTime = new Date();

          let diff: number = (currentDateTime.getTime() - checkInDateTime.getTime()) / 1000;
          let unit: string = 'seconds';
          
          if ( diff > 86400 ) {
             unit= diff >= (86400  * 2) ?  'days' : 'day';
             diff = diff / 86400;
          }
          else if ( diff > 3600 ) {
             unit='hours'
             diff = diff / 3600;
          }
          else if ( diff > 60 ) {
             unit = diff >= (60  * 2) ? 'minutes': 'minute'
             diff = diff / 60;
          }
          else {
             return 'moments ago'
          }

          diff = Math.floor(diff);

          return `${diff} ${unit} ago` 
       }
       
       return 'Never'
   }

    get uriKey(): string {
      //return <string>this.attributes['uri_key'].value
      return <string>this.getAttribute('uri_key');
    }


    get clientData() {
       //let ret: { [ name: string ]: Object } = {};
       let ret = [];

       for ( const [name, attr_data] of Object.entries(this.attributes) ) {
           if ( attr_data.group == 'ClientData' ) {
               ret.push(attr_data)
           }
       }

       return ret;
    }
}

export class HostStub extends Host { 

   public override is_stub: boolean = true;

   constructor() {
     super();

     this.hostname   = '';
     this.status     = '';
     this.notes      = ''; 
     this.ip_address = '';
     this.site       = '';
     this.jobs       =  [];
     this.last_checkin = '';
     this.attributes   = { 
       uri_key:  { 
          name: 'uri_key',
          type: 'string',
          description: '',
          value:      '',
          group:  'CONFIG'
       } 
    }
   }


}