import { BaseApiObject } from "../../../shared/model/base-api-object";
import { SiteUserGroup } from "./site-user-group.model";

export class CommandUserGroup extends BaseApiObject {

    public execute!: number;
    public user_group!: SiteUserGroup;

    constructor(
       private  groupData?: CommandUserGroup 
    ) { 
       super(groupData);

       delete this.groupData;

       this.user_group = new SiteUserGroup(this.user_group);
    }

    public override get writable() { 
       return ['execute'] 
    };


}
