import { Component } from '@angular/core';

@Component({
  selector: 'app-guest-drop-down',
  template: `
    <div class="dropdown d-flex">
      <i class="bi bi-person-circle dropdown-toggle me-3" data-bs-toggle="dropdown"  style="font-size: 2rem; color: white;"></i>
      <ul class="dropdown-menu dropdown-menu-end">
        <li><a id="login_link" class="dropdown-item" routerLink="/login">Log In</a></li>
        <li><a class="dropdown-item" routerLink="/signup">Sign Up</a></li> 
      </ul>
    </div>
  `,
  styles: `
    .dropdown > ul > li > a:hover{
        background-color:#616060;
        color: white 
    } 
  `
})
export class GuestDropDownComponent {

}
