<div class="container" style="height: 90vh">
  <app-loading></app-loading>
  <app-site-error></app-site-error>
  <div class="col-sm-10 col-md-8 col-lg-6  m-auto py-5">
    <form  [formGroup]="ActivateForm" (ngSubmit)="doActivate()">
      <div class="mb-3 row">
        <label for="accActivate" class="form-label fw-bold col-5">Passowrd</label>
        <div class="col-7">
          <input type="password" class="form-control" id="password" aria-describedby="passowrd" formControlName="password">

          <div *ngIf="getFormControlError( ActivateForm, 'password', submitted ); let errors" class="d-inline-block"> 
            <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
              {{ error.value }} 
            </div>
          </div>

        </div>
      </div>
      <div class="mb-3 row">

        <label for="accActivate" class="form-label fw-bold col-5">Confirm Passowrd</label>
        <div class="col-7">
          <input type="password" class="form-control" id="confirmPassword" aria-describedby="confirmPassowrd" formControlName="confirmPassword">
        </div>
      </div>

      <div *ngIf="getFormControlError( ActivateForm, 'Group', submitted ); let errors" class="d-inline-block"> 
        <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
          {{ error.value }} 
        </div>
      </div>
      <button type="submit" 
              class="btn btn-primary float-end"
              [class.disabled]="f.invalid"
      >
      Activate Account
      </button>

    </form>
  </div>
</div>

