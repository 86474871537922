<div class="container ps-0">
  <div class="row justify-content-center">
    <div class="my-4">
      <ul class="nav nav-tabs mb-4 " id="HostDetailTabs" role="tablist">
          <li class="nav-item ps-2">
            <a class="nav-link active text-dark bg-white" 
                id="system-tab" 
            >
            New User  
            </a>
          </li>
      </ul>
    </div>

    <form  [formGroup]="newUserForm">
      <div class="col-10 ms-5 py-4" > 
        <div class="mb-3 row">
          <label for="email" class="my-auto form-label fw-bold col-1">Email</label>
          <div class="col-sm-8 col-md-5">
              <input type="text" 
                   class="form-control" 
                   id="email" 
                   aria-describedby="email" 
                   formControlName="email">
              <div *ngIf="getFormControlError( newUserForm, 'email', submitted ); let errors"> 
                <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
                  {{ error.value }} 
                </div>
              </div>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="role" class="my-auto form-label fw-bold col-1">Role</label>
          <div class="col-5">
            <select class="form-select" formControlName="role" id="role" >
                <option value="Administrator">
                  Administrator 
                </option>
                <option value="Service" selected>
                  Service User 
                </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
         <div class="col-5">
         </div>
         <div class="col-6">
            <button type="submit" 
                    class="btn btn-primary"
                    addDeBounce 
                    (deBounceClick)="inviteUser()" 
            >
            Invite User 
            </button>
         </div>
      </div>

    </form>
  </div>
</div>

