import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, switchMap, debounceTime, forkJoin, tap, first } from 'rxjs';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { SiteService } from '../../services/site.service';
import { Command } from '../../models/command.model';
import { CommandHost } from '../../models/command-host.model';
import { CommandService } from '../../services/command.service';
import { Host } from '../../models/host.model';

@Component({
  selector: 'app-command-hosts-tab',
  templateUrl: './command-hosts-tab.component.html',
  styleUrl: './command-hosts-tab.component.css'
})
export class CommandHostsTabComponent {

  public command$!: BehaviorSubject<Command>
  public commandHosts$!: BehaviorSubject<ApiCollection<CommandHost>>
  public availableHostsList$!: BehaviorSubject<ApiCollection<Host>>
  //public hosts!: ApiCollection<Host>
  private selectedHosts: { [ key: string ]: any } = {};
  private selectedCommandHosts: { [ key: string ]: any } = {};
  private existingCommandHosts: { [ key: string ]: any } = {};
  
  //private subscription: Subscription = new Subscription();
  //private hostListUpdates: Subject<number> = new Subject();
  //private commandHostListDeletes: Subject<number> = new Subject();


  constructor( 
    private CommandService: CommandService, 
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
  ) {

    //this.subscription.add( 
    //    this.hostListUpdates.pipe(
    //      debounceTime(1000)
    //    )
    //    .subscribe(() => {
    //       this.doAddCommandHosts() 
    //    })
    //)

    //this.subscription.add( 
    //    this.commandHostListDeletes.pipe(
    //      debounceTime(1000)
    //    )
    //    .subscribe(() => {
    //       this.doRemoveCommandHosts() 
    //    })
    //)

  }

  ngOnInit(){

    this.route.params
              .pipe(
                filter( params => params['command_id'] ),
                switchMap((params) => { 
                  return this.CommandService.getCurrentCommand(params['command_id'])  
                }),
                tap( command => this.command$ = new BehaviorSubject<Command>(command) ),
                switchMap((command) =>  forkJoin([

                      this.CommandService.getCommandHosts(command), //.pipe( first() ),
                      this.CommandService.getCommandAvailableHosts(command), //.pipe( first() ),
                ])), 
              )
              .subscribe( ([commandHosts, hosts]) => {
              
                this.commandHosts$ = new BehaviorSubject<ApiCollection<CommandHost>>(commandHosts);  
                //this.hosts = hosts;
                this.availableHostsList$ = new BehaviorSubject<ApiCollection<Host>>(hosts);  

              });

  }

  isHostSelected( host: Host) {
     return this.selectedHosts[host.id]
  }

  isCommandHostSelected( commandHost: CommandHost) {
     return this.selectedCommandHosts[commandHost.id]
  }


  toggleHostSelect(host: Host) {
    if ( this.selectedHosts[host.id] ) {
        this.selectedHosts[host.id] = false
    }
    else {
        this.selectedHosts[host.id] = true
    }

  }

  toggleCommandHostSelect(host: CommandHost) {
    if ( this.selectedCommandHosts[host.id] ) {
        this.selectedCommandHosts[host.id] = false
    }
    else {
        this.selectedCommandHosts[host.id] = true
    }

  }

  alreadyCommandHost(host:Host) {
     return this.existingCommandHosts[host.id]
  }

  //addSelectedHosts() {
  //  this.hostListUpdates.next(1);
  //}

  doAddCommandHosts() {
    let currentCommand = this.CommandService.currentCommand;

    let new_hosts: Array<string> = Object.keys(this.selectedHosts)

    this.selectedHosts = {};

    if ( new_hosts.length == 0 ) {
        console.log('no hosts selected');
        return
    }

    this.CommandService.addCommandHosts( currentCommand, new_hosts )
                       .pipe(
                           switchMap((commandHosts) =>  forkJoin([
                                 this.CommandService.getCommandHosts(currentCommand), 
                                 this.CommandService.getCommandAvailableHosts(currentCommand),
                           ])), 
                       )
                       .subscribe( ([commandHosts, hosts]) => {
                            this.commandHosts$.next(commandHosts);  
                            //this.hosts = hosts;
                            this.availableHostsList$.next(hosts);  


                       })
  }

  //removeSelectedCommandHosts() {
  //  this.commandHostListDeletes.next(1);
  //}

  doRemoveCommandHosts() {
    let currentCommand = this.CommandService.currentCommand;

    let del_hosts: Array<string> = Object.keys(this.selectedCommandHosts)

    this.selectedCommandHosts = {};

    if ( del_hosts.length == 0 ) {
        console.log('no command hosts selected');
        return
    }

    this.CommandService.removeCommandHosts( currentCommand, del_hosts )
                       .pipe(
                           switchMap((deletes) =>  forkJoin([
                                 this.CommandService.getCommandHosts(currentCommand), 
                                 this.CommandService.getCommandAvailableHosts(currentCommand),
                           ])), 
                       )
                       .subscribe( ([commandHosts, hosts]) => {
                            this.commandHosts$.next(commandHosts);  
                            //this.hosts = hosts;
                            this.availableHostsList$.next(hosts);  

                       })
  }


  //ngOnDestroy(): void {
  //   this.subscription.unsubscribe();
  //}
}
