import { Component } from '@angular/core';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggerService } from './shared/services/logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {

  title = 'ExCollect-Web-Panel-app';


  constructor(
      //private modalService: NgbModal,
      private loggerService: LoggerService
  ) {
     this.loggerService.debug('starting');
  }

  //public open(modal: any): void {
  //  this.modalService.open(modal);
  //}


}
