import { Component } from '@angular/core';
import { AccessApiService } from '../shared/services/access-api.service';
import { LoadingModalService } from '../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { CurrentUserService } from '../shared/services/current-user.service';
import { ServiceInstanceService } from '../account/services/service-instance.service';
import { subscribeOn, switchMap, of } from 'rxjs';
import { SiteErrorService } from '../shared/modules/rw-utils/modals/site-error/site-error.service';

@Component({
  selector: 'app-service-setup',
  templateUrl: './service-setup.component.html',
  styleUrl: './service-setup.component.css'
})
export class ServiceSetupComponent {

  constructor( 
    private accessApi: AccessApiService, 
    private LoadingModal: LoadingModalService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private currentUser: CurrentUserService,
    private ServiceInstnace: ServiceInstanceService,
    private SiteError: SiteErrorService
  ) {

  }

  ngOnInit(){
    this.LoadingModal.setLoading();

    this.ServiceInstnace.getPendingSetup()
                        .pipe( 
                           switchMap( (serviceCollection) => { 

                                let service   = serviceCollection.first();
                                let account   = this.currentUser.currentAccount;
                                let setupData = { name: account.name };

                                //return of(service)
                                return this.ServiceInstnace.setupService( service, setupData )
                           })
                        )
                        .subscribe({ 
                           next: (service) => { 
                               console.log(service)
                               this.LoadingModal.unsetLoading();

                               this.router.navigate(['/excollect']);
                           },
                           error: (error) => {
                              this.LoadingModal.unsetLoading();

                              let status = error.response.status;

                              // If there's no service to setup just try 
                              // redirectin to excollect service.
                              if ( status === 404 ) {
                                 this.router.navigate(['/excollect']);
                              }
                              else {
                                console.log(error);
                                this.SiteError.setApiError(error);

                              }
                           }
                        })
  }
}
