import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Logger, ILogObj } from "tslog";

interface logLevelString {
  [key: string]: number;
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  logger: Logger<ILogObj>; 

  levels: logLevelString = { 
    'silly':  0, 
    'trace':  1, 
    'debug':  2, 
    'info':   3, 
    'warn':   4, 
    'error':  5, 
    'fatal':  6,
    'off':    7 
  };

  constructor() { 
      this.logger = new Logger( { type: "pretty"}  );
      this.logger.settings.minLevel = this.levels[environment.log_level];
  }

  info( ...args: unknown[] ) {
      this.logger.info(this.format_msg(...args)) 
  }

  debug( ...args: unknown[] ) {
      this.logger.debug(this.format_msg(...args)) 
  }

  trace( ...args: unknown[]) {
      this.logger.trace(this.format_msg(args)) 
  }

  error( ...args: unknown[] ) {
      this.logger.error(this.format_msg(args)) 
  }

  format_msg( ...args: any[] ) {
     args = args.length == 1 ? args[0] : args;

     return JSON.stringify( args, null, 2 )
  }

  //log( level:string, ...args: unknown[]) {

  //     let msg = JSON.stringify( args, null, 2 )

  //     //let log_method = this.logger[level];
  //    let instance = this.logger;
  //    let logger = {instance};
  //     
  //    let getLoggerFunction = < T extends keyof typeof logger >( 
  //      objectName: T,
  //      propertyName: keyof typeof logger[T]
  //    ) => {
  //      return logger[objectName][propertyName];
  //    }
  
  //    let loggerFN = getLoggerFunction(  'instance', <keyof Logger<ILogObj>>level );

  //    loggerFN(msg)
  //}



}
