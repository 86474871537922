import { BaseApiObject } from "../../shared/model/base-api-object";

export class Account extends BaseApiObject {

   name!: string;
   opended!: string;
   updated!: string;
   closed!: string;
   free!: boolean;
   attributes: any;
}
