import { Component } from '@angular/core';
import { SiteErrorService } from '../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { LoadingModalService } from '../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { AccountUsersService } from '../../services/account-users.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { filter, switchMap } from 'rxjs';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrl: './user-detail.component.css'
})
export class UserDetailComponent {

}
