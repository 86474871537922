<div *ngIf="(currentCommand$ | async) as command" >
  <form  [formGroup]="InstanceConfigForm">
    <div class="row mt-4 mb-4">
       <div class="col-6">
       </div>
       <div class="col-4">
          <button addDeBounce 
                  (deBounceClick)="doAddInstance()" 
                  class="btn btn-primary"
          >
            Create
          </button>
       </div>
    </div>
    <div class="col-10 ms-5 pt-4" > 
      <div class="mb-3 row">
        <label for="name" class="form-label fw-bold col-1">Label</label>
        <div class="col-6">
            <input type="text" 
                 class="form-control" 
                 id="label" 
                 aria-describedby="label" 
                 formControlName="label">
        </div>
      </div>
      <div *ngIf="command.has_parameters()">
            <div *ngFor="let param of command.parameters" class="mb-3 row">
               <label [for]="param.name" class="form-label fw-bold col-1">{{ param.name }}</label>
               <div class="col-6">
                   <input type="text" 
                        class="form-control" 
                        [id]="param.name" 
                        [attr.aria-describedby]="param.name" 
                        [formControlName]="param.name"
                        [value]="param.default">
               </div>
               <!--
               <label *ngIf="param.default" class="ps-3 fw-bold col-4"> [{{ param.default }}]</label>
              -->
            </div>
      </div>
  
    </div>
  </form>
</div>

