import { BaseApiObject } from "../../shared/model/base-api-object";
import { WithAttributes } from "../../service/excollect/mixins/with-attributes.mixin";
import { localizeDateTime } from "../../utils/model-utils";
import { Service } from "./service.model";
import { ServiceLevel } from "./service-level.model";

export class ServiceInstance extends WithAttributes(BaseApiObject) {

    public status!: string;
    public free!: boolean;
    public opened!: string;
    public closed!: string;
    public service!: Service;
    public service_level!: ServiceLevel;

   constructor(
      private instanceData: ServiceInstance 
    ) { 
       super(instanceData);

       this.service       = new Service(this.service);
       this.service_level = new ServiceLevel(this.service_level);
    }


    get localOpenedDateTime() {
      return localizeDateTime(this.opened);
    }

    get localClosedDateTime() {
      return localizeDateTime(this.opened);
    }

}
