import { BaseApiObject } from "../../../shared/model/base-api-object";
import { WithAttributes } from "../mixins/with-attributes.mixin";

export class Command extends WithAttributes(BaseApiObject) {

    public is_stub: boolean = false;

    public type!: string;
    public name!: string;
    public description!: string;
    public command_string!: string
    public parameters!: Array<{ name: string, default?: string}>

    public override get onCreate() { 
       return ['type', 'name','description', 'command_string'] 
    };

    public override get writable() { 
       return [ 'name','description', 'command_string', this.updateAttributes.bind(this) ] 
    };

    public override writableAttributes = ['max_retry', 'command_priority'];

    get uriKey() {
      return this.getAttribute('uri_key');
    }

    public has_parameters(): Boolean {
       return ( this.parameters?.length > 0 ) ? true : false;
    } 

}

export class CommandStub extends Command { 

   public override is_stub: boolean = true;

   constructor() {
     super();

     this.name            = '';
     this.command_string  = '';
     this.description     = ''; 
     this.type            = '';
     this.attributes   = { 
       uri_key:  { 
          name: 'uri_key',
          type: 'string',
          description: '',
          value:      '',
          group:  'CONFIG'
       } 
    }
   }
}
