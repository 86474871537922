import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RwUtilsModule } from '../../shared/modules/rw-utils/rw-utils.module';
import { SiteComponent } from './components/site/site';
import { ExcollectNavbarComponent } from './components/navbar/navbar.component';

import { RouterModule, Routes } from '@angular/router';
import { HostsListComponent } from './components/hosts-list/hosts-list.component';
import { NotFoundComponent } from '../../shared/component/not-found.component';
import { ContainerComponent } from './container/container.component';
import { CommandsComponent } from './components/commands-list/commands-list.component';
import { UsersComponent } from './components/users/users.component';
import { UserGroupsComponent } from './components/user-groups/user-groups.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HostDetailComponent } from './components/host-detail/host-detail.component';
import { SystemTabComponent } from './components/host-detail/system-tab.component';
import { LogTabComponent } from './components/host-detail/log-tab.component';
import { TerminalTabComponent } from './components/host-detail/terminal-tab.component';
import { CreateHostComponent } from './components/create-host/create-host.component';
import { CommandCreateComponent } from './components/command-create/command-create.component';
import { CommandConfigComponent } from './components/command-config/command-config.component';
import { CommandDetailComponent } from './components/command-detail/command-detail.component';
import { CommandConfigTabComponent } from './components/command-detail/command-config-tab.component';
import { CommandInstancesTabComponent } from './components/command-detail/command-instances-tab.component';
import { CommandHostsTabComponent } from './components/command-detail/command-hosts-tab.component';
import { CommandUsersTabComponent } from './components/command-detail/command-users-tab.component';
import { InstancesListComponent } from './components/instances-list/instances-list.component';
import { InstancesDetailComponent } from './components/instances-detail/instances-detail.component';
import { InstanceConfigTabComponent } from './components/instances-detail/instance-config-tab.component';
import { InstanceHostsTabComponent } from './components/instances-detail/instance-hosts-tab.component';
import { HostJobComponent } from './components/instances-detail/host-job.component';
import { HostJobLogComponent } from './components/host-job-log/host-job-log.component';
import { CommandDocumentationTabComponent } from './components/command-detail/command-documentation-tab.component';
import { CommandInstancesTabSearchComponent } from './components/command-detail/command-instances-tab-search.component';
import { CommandInstancesTabCreateComponent } from './components/command-detail/command-instances-tab-create.component';
import { PagerComponent } from './components/pager/pager.component';
import { excollectGuard } from './excollect.guard';


const routes: Routes = [
  // execolllect/users access endpoint for list of sites
  // auto redirect to :id of default site
  { path: 'excollect', canActivate: [excollectGuard], 
    component: ContainerComponent, // canActivate: [AuthGuard],
    children: [
      { path: 'NotFound', component: NotFoundComponent },
      { path: ':site_id', component: SiteComponent, 
        children: [
          {
           path: '', redirectTo: 'hosts', pathMatch: 'full'
          },
          {
           path: 'dashboard', component: DashboardComponent,
          },
          {
           path: 'hosts', component: HostsListComponent,
          },
          {
           path: 'hosts/create', component: CreateHostComponent,
          },
          {
           path: 'hosts/:host_id', component: HostDetailComponent,
           children: [
              {
               path: '', redirectTo: 'system', pathMatch: 'full'
              },
              {
               path: 'system', component: SystemTabComponent,
              },
              {
               path: 'logs', component: LogTabComponent,
              },
              {
               path: 'terminal', component: TerminalTabComponent,
              },
           ]
          },
          {
           path: 'commands', component: CommandsComponent,
          },
          {
           path: 'commands/create', component: CommandCreateComponent,
          },
          {
           path: 'commands/:command_id', component: CommandDetailComponent,
           children: [
              {
               path: '', redirectTo: 'config', pathMatch: 'full'
              },
              {
               path: 'config', component: CommandConfigTabComponent,
              },
              {
               path: 'documentation', component: CommandDocumentationTabComponent,
              },
              {
               path: 'instances', component: CommandInstancesTabComponent,
               children: [
                  {
                   path: '', redirectTo: 'list', pathMatch: 'full'
                  },
                  {
                   path: 'list', component: CommandInstancesTabSearchComponent,
                  },
                  {
                   path: 'create', component: CommandInstancesTabCreateComponent,
                  },
               ]
              },
              {
               path: 'hosts', component: CommandHostsTabComponent,
              },
              {
               path: 'users', component: CommandUsersTabComponent,
              },

              //{
              // path: 'terminal', component: TerminalTabComponent,
              //},
           ]
          },
          {
           path: 'instances', component: InstancesListComponent
          },
          {
           path: 'instances/:instance_id', component: InstancesDetailComponent,
           children: [
              {
               path: '', redirectTo: 'config', pathMatch: 'full'
              },
              {
               path: 'config', component: InstanceConfigTabComponent,
              },
              {
               path: 'hosts', component: InstanceHostsTabComponent,
               children: [
                  {
                   path: ':host_job_id', component: HostJobComponent,
                  },
               ]
              },

           ]
          },
          {
           path: 'users', component: UsersComponent,
          },
          {
           path: 'groups', component: UserGroupsComponent,
          },
          {
           path: 'settings', component: SettingsComponent,
          },

          //{ path: '',   redirectTo: 'hosts', pathMatch: 'full' }, // replace with Dashboard
          { path: '**', component: NotFoundComponent }
        ]
      },

    ]
  },
];
@NgModule({
  declarations: [
    SiteComponent,
    ExcollectNavbarComponent,
    HostsListComponent,
    ContainerComponent,
    CommandsComponent,
    UsersComponent,
    UserGroupsComponent,
    SettingsComponent,
    DashboardComponent,
    HostDetailComponent,
    SystemTabComponent,
    LogTabComponent,
    TerminalTabComponent,
    CreateHostComponent,
    CommandCreateComponent,
    CommandConfigComponent,
    CommandDetailComponent,
    CommandConfigTabComponent,
    CommandInstancesTabComponent,
    CommandHostsTabComponent,
    CommandUsersTabComponent,
    InstancesListComponent,
    InstancesDetailComponent,
    InstanceConfigTabComponent,
    InstanceHostsTabComponent,
    HostJobLogComponent,
    CommandDocumentationTabComponent,
    CommandInstancesTabSearchComponent,
    CommandInstancesTabCreateComponent,
    PagerComponent,
  
  ],
  imports: [
    CommonModule,
    RwUtilsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ],
  exports: [
     RouterModule
  ]
})
export class ExcollectModule { }
