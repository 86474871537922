import { Injectable } from '@angular/core';
import { AccessApiService } from '../../shared/services/access-api.service';
import { CurrentUserService } from '../../shared/services/current-user.service';
import { Account } from '../models/account';
import { UserRole } from '../models/user-role.model';
import { ExcClientRequestCfg } from '../../shared/services/access-api.service';
import { sprintf } from 'sprintf-js';
import { switchMap, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountUsersService {

  constructor(
    private accessApi: AccessApiService,
    private currentUser: CurrentUserService
  ) { }

  public getUserRoles( cfg?: ExcClientRequestCfg )  {

     return  this.getUserRolesCollectionRequest( this.currentUser.currentAccount, cfg ) 
  }

  public getUserRole( userUriKey: string )  {
     
     let cfg = { searchArgs: { attributes: { uri_key: userUriKey } } };
     //let cfg = { searchArgs: { user:  { first_name: 'Testr' } } };

     return this.getUserRolesCollectionRequest( this.currentUser.currentAccount, cfg )
                .pipe(
                  map( (collection) => { return collection.first() })
                );
  }


  private getUserRolesCollectionRequest( account:  Account,  cfg?: ExcClientRequestCfg ) {

      let path = sprintf('/account_api/accounts/%s/users_roles', account.id );

      return this.accessApi
                 .getCollection(UserRole, path ,cfg )
  }


}
