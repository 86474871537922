import { Component } from '@angular/core';
import { CurrentUserService } from '../../shared/services/current-user.service';
import { Router, RouterPreloader } from '@angular/router';

@Component({
  selector: 'app-container',
  template: `
    <app-site-error></app-site-error>
    <app-loading></app-loading>
    <div class="container-fluid h-100 d-flex">
      <div class="row flex-grow-1">
        <div class="p-0 col-md-2" >
           <app-account-side-nav></app-account-side-nav>
        </div>
        <div class="p-0 col-md-10" >
           <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
  styles: `
  :host {
     height: 90vh;
     display: block;
  }
  `
})
export class ContainerComponent {

  constructor(
    //private accessApi: AccessApiService,
    private router:  Router,
    private currentUser: CurrentUserService
  ) {

  }

  ngOnInit(){

    this.currentUser.user$
        .subscribe( 
           (user) => {
               if ( user.hasAccounts == false ) {
                  console.log('here')
                  this.router.navigate([ '/NotFound' ]);
               }
           }
        )

  }
}
