import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[addDeBounce]'
})
export class AddDeBounceClickDirective {

  @Input() debounceTime = 500;
  @Output() deBounceClick = new EventEmitter();
  private clicks = new Subject();
  private subscription!: Subscription;

  constructor() { }

  ngOnInit() {
    //console.log('INITING');
    this.subscription = this.clicks.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => this.deBounceClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent( event: Event ) {
    //console.log('click');
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
