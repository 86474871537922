
<nav class="navbar navbar-expand-sm navbar-light bg-dark">
  <div class="container-fluid">
    <div>
      <span id="nav-title" class="navbar-brand text-light ms-2 fs-2">
        <a routerLink="{{url()}}">ExCollect</a>
      </span>
      <span class="dropdown">
        <button class="btn dropdown-toggle me-3" data-bs-toggle="dropdown"  style="font-size: 1rem; color: white;">
            Documentation
        </button>
        <ul class="dropdown-menu  dropdown-menu-end bg-dark text-light">
          <li><a class="dropdown-item text-light" target="_blank" href="https://wiki.raffiware.io/en/QuickStart">Quick Start</a></li>
          <li><a class="dropdown-item text-light" target="_blank" href="https://wiki.raffiware.io/">Wiki</a></li>
          <li><a class="dropdown-item text-light" target="_blank" href="https://github.com/raffiware-dev">GitHub</a></li>
        </ul>
      </span>
    </div>
    <ng-container *ngIf="loggedIn(); then loggedInDD; else loggedOutDD"></ng-container> 
  </div>

</nav>

<ng-template #loggedInDD>
  <app-logged-in-drop-down></app-logged-in-drop-down>
</ng-template>
<ng-template #loggedOutDD>
  <app-guest-drop-down></app-guest-drop-down>
</ng-template>
