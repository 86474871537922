import { BaseApiObject } from "../../../shared/model/base-api-object"; 
import { objectAttributes } from "../../../shared/interfaces/object-attributes";
import { SiteUser } from "./site-user.model";
import { WithAttributes } from "../mixins/with-attributes.mixin";

export class Site extends WithAttributes(BaseApiObject) {

    public name!: string;
    public status!: string;
    public created!: string;
    public updated!: string;
    public notes!: string;
    public users!: Array<SiteUser>;
    public hosts!: Array<Object>;
    //public attributes!: objectAttributes;

    get uriKey(): string {
      return <string>this.getAttribute('uri_key');
    }

    //public get_attribute(attribute: string): string | number | null  { 

    //     let type: string = this.attributes[attribute].type;

    //     if ( type === 'text' ) {
    //         return <string>this.attributes[attribute].value;
    //     }
    //     if ( type === 'int' ) {
    //         return <number>this.attributes[attribute].value;
    //     }

    //     return null

    //} 
}
