import { Injectable } from '@angular/core';
import { ApiCollectionResponse } from '../../../shared/interfaces/api-responses'; 
import { AccessApiService, ExcClientRequestCfg } from '../../../shared/services/access-api.service'; 
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { pipe } from 'rxjs';
import { first, tap, filter, map, switchMap, last } from 'rxjs/operators';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { Site } from '../models/site.model';
import { Host } from '../models/host.model';
import { SiteUser } from '../models/site-user.model';
import {sprintf} from "sprintf-js";
import { ApiCollection } from '../../../shared/model/api-coillection.model';
import { LoggerService } from '../../../shared/services/logger.service';
import { CurrentUserService } from '../../../shared/services/current-user.service';
//import { AccountUsersService } from '../../../account/services/account-users.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  activeSiteUser!: SiteUser;
  activeSiteUser$!: BehaviorSubject<SiteUser>;
  activeSiteHosts$!: BehaviorSubject<ApiCollection<Host>>;
  activeSiteUrl!: string;
  siteUsersCollection!: ApiCollection<SiteUser>;
  loaded$: BehaviorSubject<boolean> ; 
  siteLoaded$: BehaviorSubject<boolean> ; 

  uriMap: { [ uri_key: string ] : SiteUser };
  userIDMap: { [ id: string ] : SiteUser };

  constructor(
    private accessApi: AccessApiService,
    private loggerService: LoggerService,
    private currentUserService: CurrentUserService,
   // private UserService: AccountUsersService,
    private router: Router

  ) { 
      this.loaded$     = new BehaviorSubject<boolean>(false);
      this.siteLoaded$ = new BehaviorSubject<boolean>(false);

      this.uriMap = {};
      this.userIDMap = {};

  }

  public loadSiteUsers( activeSiteKey?: string ) {
     // limit to current account
     return this.currentUserService.user$
                .pipe(
                  filter( user => this.currentUserService.logged_in ), 
                  switchMap( user => { 

                     const account = this.currentUserService.currentAccount;
                     const cfg = account
                                   ? {
                                       searchAttrs: { account: account.id }
                                     }
                                   : {} 

                     return this.accessApi.getCollection(SiteUser, '/excollect_api/users', cfg )
                  }),
                  tap( (users) => {

                     this.siteUsersCollection = users; // new ApiCollection( SiteUser, response );  

                     this.siteUsersCollection.collection.map( ( siteUser: SiteUser ) => {
                        // console.log( siteUser.site.getAttribute('uri_key') )
                        this.uriMap[siteUser.site.uriKey] = siteUser;
                        
                        // move to site data loading function
                        // Used in command.creator map 
                        siteUser.site.users.map( user => { this.userIDMap[user.id] = user; }) 
                        //this.userIDMap[siteUser.id] = siteUser;
                     });

                     //this.initDefaultActiveSite();
                     this.loaded$.next(true);
                     return users; 
                     //return this.siteUsersCollection; 
                  }),

                );
                   //.subscribe({ 
                   //    next: response => {
                   //    
                   //       this.siteUsersCollection = new ApiCollection( SiteUser, response );  

                   //       this.siteUsersCollection.collection.map( ( siteUser: SiteUser ) => {
                   //          // console.log( siteUser.site.getAttribute('uri_key') )
                   //          this.uriMap[siteUser.site.uriKey] = siteUser;
                   //          
                   //          // move to site data loading function
                   //          siteUser.site.users.map( user => { this.userIDMap[user.id] = user; }) 
                   //          //this.userIDMap[siteUser.id] = siteUser;
                   //       });

                   //       this.initDefaultActiveSite();
                   //       this.loaded$.next(true);
                   //   },
                   //   error: error => {
                   //       this.loggerService.error('Failed to load SiteUsers')
                   //   }
                   //}) 
  }

  public isLoaded() {
    // Don't emit until we have a true value.
     return this.loaded$.pipe( filter( loaded => loaded ), first() );
  }

  public isSiteLoaded() {
    // Don't emit until we have a true value.
     return this.siteLoaded$.pipe( filter( loaded => loaded ), first() );
  }

  public setActiveSiteUserByUri( uri_key: string ): void {

      this.isLoaded().subscribe( () => {
          if ( this.uriMap[uri_key] == undefined ) {
             this.router.navigate([ '/excollect/NotFound' ]);
          }
          else {
             this.setActiveSiteUser(this.uriMap[uri_key]);
          }

      });
  }
  
  public initDefaultActiveSite(): void {

      this.activeSiteUser =  this.siteUsersCollection.collection[0]
      //this.activeSiteUser$ = new BehaviorSubject<SiteUser>(this.activeSiteUser);
      if ( !this.activeSiteUser ) {
         return ;
      }

      //this.activeSiteUrl  = sprintf('/excollect/%s', this.activeSiteUser.site.uriKey);
      this.setActiveSiteUser(this.activeSiteUser);
  }

  private setActiveSiteUser( siteUser: SiteUser ): void {
    this.activeSiteUrl  = sprintf('/excollect/%s', siteUser.site.uriKey);
    this.activeSiteUser = siteUser;
 
    // add call to load site data
    if ( this.activeSiteUser$ ) {
       this.activeSiteUser$.next(siteUser);
    }
    else {
       this.activeSiteUser$ = new BehaviorSubject<SiteUser>(siteUser);
    }
    
    this.siteLoaded$.next(true);
  }

  public getActiveSiteUser()  {
     
     return this.isSiteLoaded()
                .pipe( 
                   //filter( loaded => loaded ),
                   switchMap( () => this.activeSiteUser$ ),
                   first()
                );

  }

  public getActiveSite()  {
     
     return this.getActiveSiteUser()
                .pipe( 
                   switchMap( (siteUser) => of(siteUser.site) ),
                   first()
                );

  }

  public getSite( commandUriKey: string )  {
     
     let cfg = { searchArgs: { attributes: { uri_key: commandUriKey } } };

     return this.currentUserService.user$
                .pipe( 
                  switchMap( (site) => { return this.getSiteCollectionRequest(cfg) }),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }

  private getSiteCollectionRequest( cfg?: ExcClientRequestCfg ) {

      return this.accessApi.getCollection(Site, sprintf('/excollect_api/sites'), cfg)
  }

  public updateSite( site: Site )  {
     
     return this.currentUserService.user$
                .pipe( 
                  switchMap( (user) => { return this.updateSiteRequest( site, site.updateData() ) } ),
                  first()
                );
  }

  public updateSiteRequest( site: Site, data: Object ) {
    
      return this.accessApi.updateObject( Site, sprintf('/excollect_api/sites/%s', site.id), data )
  }



  public getActiveSiteHosts( ) {

    if ( this.activeSiteHosts$ ) {
       return this.activeSiteHosts$ 
    }
    else {
       return this.getHosts() 
                  .pipe(
                    tap(hostCollection => {
                       this.activeSiteHosts$ = new BehaviorSubject<ApiCollection<Host>>(hostCollection);
                    }) 
                  );
            //switchMap( hostsCollecction => {
            //    return of(hostsCollecction)
            //}) 
    }
  }

  public getHosts( cfg?: ExcClientRequestCfg )  {

     return this.getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getHostCollectionRequest(site, cfg) 
                  }),
                  //map( (collection) => { return collection.first() })
                );
  }

  private getHostCollectionRequest( site:  Site,  cfg?: ExcClientRequestCfg ) {

      let path = sprintf('/excollect_api/sites/%s/hosts', site.id );

      return this.accessApi
                 .getCollection(Host, path , cfg)
  }

  public getUsers( cfg?: ExcClientRequestCfg )  {

     return this.getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getUserCollectionRequest(site, cfg) 
                  }),
                );
  }

  private getUserCollectionRequest( site:  Site,  cfg?: ExcClientRequestCfg ) {

      let path = sprintf('/excollect_api/sites/%s/users', site.id );

      return this.accessApi
                 .getCollection(SiteUser, path , cfg)
                 .pipe( first() )
  }



  // get('uri', { params: { answer: 42 } } )
}
