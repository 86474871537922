import { BaseApiObject } from "../../../shared/model/base-api-object";
import { WithAttributes } from "../mixins/with-attributes.mixin";
import { CommandInstance, CommandInstanceStub } from "./command-instance.model"; 
import { localizeDateTime } from "../../../utils/model-utils";
import { Host, HostStub } from "./host.model"; 

export class HostJob extends WithAttributes(BaseApiObject) {

    public is_stub: boolean = false;

    public commandInstance!: CommandInstance;
    public host!: Host;
    public retries!: number; 
    public priority!: number;
    public status!: string;
    public command_string!: string;
    public start_datetime!: string;
    public complete_datetime!: string;

    constructor(
       private hostJobData: HostJob 
    ) { 
       super(hostJobData);

       this.host            = new Host(this.host);
       this.commandInstance = new CommandInstance(this.commandInstance);
    }

    public override get onCreate() { 
       return [] 
    };

    public override get writable() { 
       return [ this.updateAttributes.bind(this) ] 
    };

    public override writableAttributes = [];

    get uriKey() {
      return this.getAttribute('uri_key');
    }

    get localStartDateTime() {
      return localizeDateTime(this.start_datetime);
    }

    get localCompletedDateTime() {
      return localizeDateTime(this.complete_datetime);
    }

}

export class HostJobStub extends HostJob { 

   public override is_stub: boolean = true;

//    public commandInstance!: CommandInstance;
//    public host!: Host;
//    public retries!: number; 
//    public priority!: number;
//    public status!: string;
//    public command_string!: string;
//    public start_datetime!: string;
//    public complete_datetime!: string;
//


   constructor(
   ) {
     super( <HostJob>{} );

     this.id              = 'stub';
     this.commandInstance = new CommandInstanceStub()
     this.host            = new HostStub();
     this.priority        = 0;
     this.retries         = 0;

     this.status  = '';
     this.attributes   = { 
       uri_key:  { 
          name: 'uri_key',
          type: 'string',
          description: '',
          value:      '',
          group:  'CONFIG'
       } 
    }
   }
}

