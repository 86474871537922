<div *ngIf="(collection$ | async) as collection " class="row mt-auto mx-auto">
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item">
          <div class="page-link btn" 
               aria-label="Previous"
               [class.disabled]="collection.current_page==1"
               (click)="goToPage(collection.current_page - 1)"
           >

            <span aria-hidden="true">&laquo;</span>
          </div>
        </li>
        <li *ngFor="let page of pages; index as i" 
            class="page-item"
            [class.active]="collection.current_page==page.page_num"
            (click)="goToPage(page.page_num)"
        >
           <div class="page-link btn">{{ page.page_num }}</div>
        </li>
        <li class="page-item">
          <div class="page-link btn" 
               aria-label="Next"
               [class.disabled]="collection.current_page==collection.last_page"
               (click)="goToPage(collection.current_page + 1)"
          >
            <span aria-hidden="true">&raquo;</span>
          </div>
        </li>
      </ul>
    </nav>
  </div>

