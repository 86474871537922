<div *ngIf="(command$ | async) as command" >
  <app-command-config 
     *ngIf="initCfg != null"
     [formAction]="formAction" 
     [initCfg]="initCfg"
     (newCommandEvent)="updateCommand($event)">
  </app-command-config>
  <div *ngIf="command.has_parameters()">
    <div class="col-10 ms-5" > 
      <div class="mb-3 row">
        <label for="name" class="form-label fw-bold col-6">Parameters [default]</label>
        <div *ngFor="let param of command.parameters" >
           <label class="ps-3 fw-bold col-2">{{ param.name }}</label>
           <label *ngIf="param.default" class="ps-3 fw-bold col-4"> [{{ param.default }}]</label>
        </div>
      </div>
    </div>
  </div>
  <!-- Test instance widge here -->
</div>
