<div class="container ps-0 ms-0 h-100">
                                               <!-- fixes row breaking out of div-->
  <div class="row ps-0 justify-content-center"  style="margin: auto">

    <div *ngIf="(command$ | async) as command" class="px-3" >

      <div class="row">
        <div class="col pt-5">
          <div class="row d-flex" style="margin: auto;">
            <div class="col-5 flex-column flex-grow-1 text-center px-4">
               <h4>Users</h4>
            </div>
            <div class="col-1 flex-column flex-grow-1 ">
            </div>
            <div class="col-5 flex-column flex-grow-1 text-center">
               <h4 *ngIf="command.hasPermission('edit')" >Available Users</h4>
            </div>
          </div>
          <div class="row d-flex" style="min-height: 30em; margin: auto;">
            <div class="col-5 flex-column flex-grow-1 px-4">
              <div class="row" style="margin: auto;">
                <table class="table table-hover mb-0">
                    <thead class="table-dark">
                      <tr>
                        <th class="col-7 text-center">Username</th>
                        <th class="col-5 text-center">Execute</th>
                      </tr>   
                    </thead>
                </table>
                <div class="overflow-auto px-0" style="height: 80% !important">
                  <table class="table table-hover mb-0">
                     <tbody *ngIf="(users$ | async) as users" >
                       <tr *ngFor="let user of users.collection"
                           [class.table-primary]="isUserSelected(user)"
                          (click)="toggleUserSelect(user)" 
                       >
                         <td  class="col-7 text-center">{{ userMap[user.user.account_user].email_addr }}</td>
                         <td class="col-5 text-center"
                         >
                            <div class="form-check form-switch ms-3">
                                <input addDeBounce
                                       [disabled]="!command.hasPermission('edit')" 
                                       (deBounceClick)="toggleUserExecute(user, $event)"
                                       class="form-check-input mx-auto" 
                                       style="transform: scale(1.3);"
                                       type="checkbox" 
                                       role="switch" 
                                       id="toggleExecute" 
                                       [checked]="user.execute">
                             </div>

                             <!--
                             {{ userMap[user.user.account_user].status}}
                             -->
                         </td>
                       </tr>   
                     </tbody>
                  </table>
                </div>
              </div>
              <div class="row flex-grow-1">
              </div>
            </div>
      
            <div class="col-1  flex-column flex-grow-1">
              <div *ngIf="command.hasPermission('edit')"  class="row d-flex h-100">
                 <div class="col-12 d-flex justify-content-center my-auto">
                   <button addDeBounce 
                           class="btn btn-primary" 
                           (deBounceClick)="doAddUser()"
                   >
                     <i class="bi bi-box-arrow-in-left h2"></i>
                   </button>
                 </div>
                 <div class="col-12  d-flex justify-content-center my-auto">
                   <button addDeBounce 
                           class="btn btn-danger" 
                           (deBounceClick)="doRemoveUser()"
                   >
                     <i class="bi bi-box-arrow-in-right h2"></i> 
                   </button>
                 </div>
              </div>
            </div>
      
            <div class="col-5 flex-column flex-grow-1 px-2">
              <div *ngIf="command.hasPermission('edit')" class="row" style="margin: auto;">
                <table class="table table-hover mb-0">
                    <thead class="table-dark">
                      <tr>
                        <th class="col-7 text-center">Username</th>
                        <th class="col-5 text-center">Status</th>
                      </tr>   
                    </thead>
                </table>
                <div class="overflow-auto px-0" style="height: 80% !important">
                  <table class="table table-hover mb-0">
                     <tbody *ngIf="(available$ | async) as available" >
                       <tr *ngFor="let user of available.collection"
                           [class.table-primary]="isAvailableSelected(user)"
                           (click)="toggleAvailableSelect(user)"
                       >
                         <td class="col-7 text-center">{{ userMap[user.account_user].email_addr }}</td>
                         <td class="col-5 text-center">{{ userMap[user.account_user].status}}</td>
                       </tr>   
                     </tbody>
                  </table>
                </div>
              </div>
      
            </div>
      
          </div>
          <div class="row" style="margin: auto;">
            <div class="col-5 flex-column flex-grow-1">
      
              <div *ngIf="(users$ | async) as users" 
                    class="row justify-content-center"
                    style="margin: auto;"
              >
                <app-pager [collection]="users" (newPage)="loadUsersPage($event)"></app-pager>
              </div>
      
      
            </div>
            <div class="col-1 flex-column flex-grow-1 ">
            </div>
            <div class="col-5 flex-column flex-grow-1">
            
              <div *ngIf="( available$ | async) as available" 
                    class="row justify-content-center"
                    style="margin: auto;"
              >
                <app-pager *ngIf="command.hasPermission('edit')" [collection]="available" (newPage)="loadAvailablePage($event)"></app-pager>
              </div>
      
            </div>
          </div>
      
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col pt-5">
          <div class="row d-flex" style="margin: auto;">
            <div class="col-5 flex-column flex-grow-1 text-center px-4">
               <h4>Groups</h4>
            </div>
            <div class="col-1 flex-column flex-grow-1 ">
            </div>
            <div class="col-5 flex-column flex-grow-1 text-center">
               <h4 *ngIf="command.hasPermission('edit')" >Available Groups</h4>
            </div>
          </div>
          <div class="row d-flex" style="min-height: 30em; margin: auto;">
            <div class="col-5 flex-column flex-grow-1 px-4">
              <div class="row" style="margin: auto;">
                <table class="table table-hover mb-0">
                    <thead class="table-dark">
                      <tr>
                        <th class="col-7 text-center">Name</th>
                        <th class="col-5 text-center">Execute</th>
                      </tr>   
                    </thead>
                </table>
                <div class="overflow-auto px-0" style="height: 80% !important">
                  <table class="table table-hover mb-0">
                     <tbody *ngIf="(groups$ | async) as groups" >
                       <tr *ngFor="let group of groups.collection"
                           [class.table-primary]="isGroupSelected(group)"
                          (click)="toggleGroupSelect(group)" 
                       >
                         <td  class="col-7 text-center">{{ group.user_group.name }}</td>
                         <td class="col-5 text-center"
                         >
                            <div class="form-check form-switch ms-3">
                                <input addDeBounce
                                       [disabled]="!command.hasPermission('edit')" 
                                       (deBounceClick)="toggleGroupExecute(group, $event)"
                                       class="form-check-input mx-auto" 
                                       style="transform: scale(1.3);"
                                       type="checkbox" 
                                       role="switch" 
                                       id="toggleGroupExecute" 
                                       [checked]="group.execute">
                             </div>

                         </td>
                       </tr>   
                     </tbody>
                  </table>
                </div>
              </div>
              <div class="row flex-grow-1">
              </div>
            </div>
      
            <div class="col-1  flex-column flex-grow-1">
              <div *ngIf="command.hasPermission('edit')"  class="row d-flex h-100">
                 <div class="col-12 d-flex justify-content-center my-auto">
                   <button addDeBounce 
                           class="btn btn-primary" 
                           (deBounceClick)="doAddGroup()"
                   >
                     <i class="bi bi-box-arrow-in-left h2"></i>
                   </button>
                 </div>
                 <div class="col-12  d-flex justify-content-center my-auto">
                   <button addDeBounce 
                           class="btn btn-danger" 
                           (deBounceClick)="doRemoveGroup()"
                   >
                     <i class="bi bi-box-arrow-in-right h2"></i> 
                   </button>
                 </div>
              </div>
            </div>
      
            <div class="col-5 flex-column flex-grow-1 px-2">
              <div *ngIf="command.hasPermission('edit')" class="row" style="margin: auto;">
                <table class="table table-hover mb-0">
                    <thead class="table-dark">
                      <tr>
                        <th class="col-7 text-center">Name</th>
                      </tr>   
                    </thead>
                </table>
                <div class="overflow-auto px-0" style="height: 80% !important">
                  <table class="table table-hover mb-0">
                     <tbody *ngIf="(availableGroups$ | async) as availableGroups" >
                       <tr *ngFor="let group of availableGroups.collection"
                           [class.table-primary]="isAvailableGroupSelected(group)"
                           (click)="toggleAvailableGroupSelect(group)"
                       >
                         <td class="col-7 text-center">{{ group.name }}</td>
                       </tr>   
                     </tbody>
                  </table>
                </div>
              </div>
      
            </div>
      
          </div>
          <div class="row" style="margin: auto;">
            <div class="col-5 flex-column flex-grow-1">
      
              <div *ngIf="(groups$ | async) as groups" 
                    class="row justify-content-center"
                    style="margin: auto;"
              >
                <app-pager [collection]="groups" (newPage)="loadGroupsPage($event)"></app-pager>
              </div>
      
      
            </div>
            <div class="col-1 flex-column flex-grow-1 ">
            </div>
            <div class="col-5 flex-column flex-grow-1">
            
              <div *ngIf="( availableGroups$ | async) as availableGroups" 
                    class="row justify-content-center"
                    style="margin: auto;"
              >
                <app-pager *ngIf="command.hasPermission('edit')" [collection]="availableGroups" (newPage)="loadAvailableGroupPage($event)"></app-pager>
              </div>
      
            </div>
          </div>
      
        </div>
      </div>





    </div>
  </div>
</div>

