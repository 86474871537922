"use strict"; 


class Logger {

   error(...msg) {
     this.log_msg(msg)
   } 
   info(msg) {
     this.log_msg(msg)
   } 

   debug(msg) {
     this.log_msg(msg)
   }  

   fatal(msg) {
     this.log_msg(msg)
   } 

   trace(msg) {
     this.log_msg(msg)
   }

   log_msg(msg) {
     console.log(msg)
   }

}

var logger_utils = {
    "getLogger" : function () { return new Logger(); } 
}

export default logger_utils; 
