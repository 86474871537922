import { Injectable } from '@angular/core';
import { AccessApiService, ExcClientRequestCfg } from '../../../shared/services/access-api.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { SiteService } from './site.service';
import {sprintf} from "sprintf-js";
import { pipe, skipUntil, filter, of, map, forkJoin, BehaviorSubject } from 'rxjs';
import { ApiCollection } from '../../../shared/model/api-coillection.model';
import { Site } from '../models/site.model';
import { Host } from '../models/host.model';
import { Command, CommandStub } from '../models/command.model';
import { FormControl } from '@angular/forms';
import { CommandHost } from '../models/command-host.model';
import { SiteUser } from '../models/site-user.model';
import { SiteUserGroup } from '../models/site-user-group.model';
import { CommandInstance } from '../models/command-instance.model';
import { CommandUser } from '../models/command-user.model';
import { CommandUserGroup } from '../models/command-user-group.model';

type request_cfg = { [name: string]: any };

@Injectable({
  providedIn: 'root'
})
export class CommandService {


  currentCommand: Command = new CommandStub();
  currentCommand$: BehaviorSubject<Command> = new BehaviorSubject<Command>(new CommandStub);

  constructor(
    private accessApi: AccessApiService,
    private siteService: SiteService,
  ) { }


  public getCurrentCommand( commandUriKey: string )  {
     if ( this.currentCommand?.uriKey === commandUriKey ) {
        return of(this.currentCommand);
     }
     
     return this.getCommand(commandUriKey)
                .pipe( tap((command) => { 
                          this.currentCommand = command; 
                          this.currentCommand$.next(command) 
                       }) 
                );
  }

  public getCommands( cfg?: ExcClientRequestCfg )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getCommandCollectionRequest( site, cfg ) }),
                  first()
                );
  }

  public getCommand( commandUriKey: string )  {
     
     let cfg = { searchArgs: { attributes: { uri_key: commandUriKey } } };

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getCommandCollectionRequest(site, cfg) }),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }

  private getCommandCollectionRequest( site:  Site, cfg?: { [name: string]: any }) {

      return this.accessApi.getCollection(Command, sprintf('/excollect_api/sites/%s/commands', site.id), cfg)
  }

  public addCommand( command: Command )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.updateCommandCollectionRequest(site, [ command.onCreateData() ]) } ),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }

  public updateCurrentCommand( command: Command )  {
      return this.updateCommand(command)
                 .pipe( tap((command) => { this.currentCommand = command }) );

  }

  public updateCommand( command: Command )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.updateCommandCollectionRequest(site, [ command.updateData() ] ) } ),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }

  public updateCommandCollectionRequest( site:  Site, data: Array<Object> ) {
    
      return this.accessApi.updateCollection(Command, sprintf('/excollect_api/sites/%s/commands', site.id), data )
  }

  public getCommandHosts( command: Command , cfg?: request_cfg)  {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getCommandHostCollectionRequest(site, command, cfg) 
                  }),
                  first()
                  //map( (collection) => { return collection.first() })
                );
  }

  private getCommandHostCollectionRequest( site:  Site, command: Command, cfg?: request_cfg ) {

      let path = sprintf('/excollect_api/sites/%s/commands/%s/hosts', site.id, command.id );

      return this.accessApi
                 .getCollection(CommandHost, path , cfg)
  }

  public getCommandAvailableHosts( command: Command , cfg?: request_cfg)  {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                                                                           
                    let path = sprintf('/excollect_api/sites/%s/commands/%s/available_hosts', 
                                        site.id, command.id );

                    return this.accessApi.getCollection( Host, path , cfg)

                  }),
                  first()
                );
  }

  public addCommandHosts( command: Command, hosts: Array<string>, cfg?: request_cfg)  {

     let data = hosts.map( (host_id) => {
                   return { priority: 1, host: { id: host_id } }
                });

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 

                    let path = sprintf('/excollect_api/sites/%s/commands/%s/hosts', 
                                        site.id, command.id );

                    return this.accessApi.updateCollection( CommandHost, path, data, cfg) 
                  }),
                  first()
                  //map( (collection) => { return collection.first() })
                );
  }

  public removeCommandHosts( command: Command, command_hosts: Array<string>, cfg?: request_cfg)  {

     let build_obserables = (site_id: string) => { 

        return command_hosts.map( (command_host_id) => {
                              let path = sprintf('/excollect_api/sites/%s/commands/%s/hosts/%s', 
                                                  site_id, command.id, command_host_id );

                              return this.accessApi.delete( path, cfg) 
                            });
    }

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return forkJoin(build_obserables(site.id)) }),
                  first()
                );
  }

  public getInstances( command: Command , cfg?: request_cfg)  {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getInstancesCollectionRequest(site, command, cfg) 
                  }),
                  first()
                  //map( (collection) => { return collection.first() })
                );
  }

  private getInstancesCollectionRequest( site:  Site, command: Command, cfg?: request_cfg ) {

      let path = sprintf('/excollect_api/sites/%s/commands/%s/instances', site.id, command.id );

      return this.accessApi
                 .getCollection(CommandInstance, path , cfg)
  }

  public createInstance( command: Command, data: {}, cfg?: request_cfg)  {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    let path = sprintf('/excollect_api/sites/%s/commands/%s/instances', site.id, command.id );

                    return this.accessApi.updateCollection(CommandInstance, path, [data], cfg)
                  }),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }

  public getCommandUsers( command: Command , cfg?: request_cfg)  {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getCommandUsersCollectionRequest(site, command, cfg) 
                  }),
                  first()
                  //map( (collection) => { return collection.first() })
                );
  }

  private getCommandUsersCollectionRequest( site:  Site, command: Command, cfg?: request_cfg ) {

      let path = sprintf('/excollect_api/sites/%s/commands/%s/users', site.id, command.id );

      return this.accessApi
                 .getCollection(CommandUser, path , cfg)
  }

  public addCommandUsers( command: Command, newUsers: Array<string>, cfg?: ExcClientRequestCfg )  {

    let data = newUsers.map( (user_id) => {
                  return { user: { id: user_id } } 
               });

    return this.siteService
               .getActiveSite()
               .pipe( 
                 switchMap( (site) => { 

                   let path = sprintf('/excollect_api/sites/%s/commands/%s/users', 
                                       site.id, command.id );

                   return this.accessApi.updateCollection( SiteUser, path, data, cfg) 
                 }),
                 first()
               );
  }

  public removeCommandUsers( command: Command, userIds: Array<string> )  {

    const cfg = {
       searchArgs: { user: { id: { in: userIds  } } }
    }

    return this.siteService
               .getActiveSite()
               .pipe( 
                 switchMap( (site) => { 

                   let path = sprintf('/excollect_api/sites/%s/commands/%s/users', 
                                       site.id, command.id );

                   return this.accessApi.delete( path, cfg) 
                 }),
                 first()
               );
  }

  public getAvailableUsers( command: Command , cfg?: request_cfg)  {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                                                                           
                    let path = sprintf('/excollect_api/sites/%s/commands/%s/available_users', 
                                        site.id, command.id );

                    return this.accessApi.getCollection( SiteUser, path , cfg)

                  }),
                  first()
                );
  }

  public updateCommandUser( command: Command, user: CommandUser )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                      return this.updateCommandUserCollectionRequest(site, command, [ user.updateData() ] ) 
                  }),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }

  private updateCommandUserCollectionRequest( site:  Site, command: Command, data: Array<Object> ) {
    
      const path = sprintf('/excollect_api/sites/%s/commands/%s/users', site.id, command.id );

      return this.accessApi.updateCollection(CommandUser, path, data );
  }

  public getAvailableGroups( command: Command , cfg?: request_cfg)  {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                                                                           
                    let path = sprintf('/excollect_api/sites/%s/commands/%s/available_groups', 
                                        site.id, command.id );

                    return this.accessApi.getCollection( SiteUserGroup, path , cfg)

                  }),
                  first()
                );
  }

  public getCommandGroups( command: Command , cfg?: request_cfg)  {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getCommandGroupsCollectionRequest(site, command, cfg) 
                  }),
                  first()
                );
  }

  private getCommandGroupsCollectionRequest( site:  Site, command: Command, cfg?: request_cfg ) {

      let path = sprintf('/excollect_api/sites/%s/commands/%s/user_groups', site.id, command.id );

      return this.accessApi
                 .getCollection(CommandUserGroup, path , cfg)
  }

  public addCommandGroups( command: Command, newGroups: Array<string>, cfg?: ExcClientRequestCfg )  {

    let data = newGroups.map( (group_id) => {
                  return { user_group: { id: group_id } } 
               });

    return this.siteService
               .getActiveSite()
               .pipe( 
                 switchMap( (site) => { 

                   let path = sprintf('/excollect_api/sites/%s/commands/%s/user_groups', 
                                       site.id, command.id );

                   return this.accessApi.updateCollection( CommandUserGroup, path, data, cfg) 
                 }),
                 first()
               );
  }

  public removeCommandGroups( command: Command, groupIds: Array<string> )  {

    const cfg = {
       searchArgs: { user_group: { id: { in: groupIds  } } }
    }

    return this.siteService
               .getActiveSite()
               .pipe( 
                 switchMap( (site) => { 

                   let path = sprintf('/excollect_api/sites/%s/commands/%s/user_groups', 
                                       site.id, command.id );

                   return this.accessApi.delete( path, cfg) 
                 }),
                 first()
               );
  }

  public updateCommandUserGroup( command: Command, group: CommandUserGroup )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                      return this.updateCommandUserGroupCollectionRequest(site, command, [ group.updateData() ] ) 
                  }),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }

  private updateCommandUserGroupCollectionRequest( site:  Site, command: Command, data: Array<Object> ) {
    
      const path = sprintf('/excollect_api/sites/%s/commands/%s/user_groups', site.id, command.id );

      return this.accessApi.updateCollection(CommandUserGroup, path, data );
  }


}

