<div class="container h-100 d-flex flex-column ">

  <div class="row justify-content-center pt-5 " style="height: 400px !important;">
    <div class="col-md-6 text-center">
       <h4> Command Clients</h4>
    </div>
    <div class="col-md-1">
    </div>
    <div class="col-md-5 text-center">
       <h4>Available Clients</h4>
    </div>
    <div class="col-6 h-100">
      <div class="container h-100 d-flex flex-column">
        <div class="row">
          <table class="table table-hover mb-0">
              <thead class="table-dark">
                <tr>
                  <th class="col-7 text-center">Client</th>
                  <th class="col-2 text-center">Status</th>
                  <th class="col-3 text-center">Priority</th>
                </tr>   
              </thead>
          </table>
          <div class="h-100 overflow-auto px-0" style="height: 80% !important">
            <table class="table table-hover mb-0">
               <tbody *ngIf="(commandHosts$ | async) as commandHosts" >
                 <tr *ngFor="let commandHost of commandHosts.collection"
                     [class.table-primary]="isCommandHostSelected(commandHost)"
                     (click)="toggleCommandHostSelect(commandHost)"

                 >
                   <td class="col-7 text-center">{{ commandHost.host.hostname }}</td>
                   <td class="col-2 text-center">{{ commandHost.host.status }}</td>
                   <td class="col-3 text-center">{{ commandHost.priority }}</td>
                 </tr>   
               </tbody>
            </table>
          </div>
        </div>
        <div class="row flex-grow-1">
        </div>
        <!--
        <div class="row flex-grow-1">
          <div class="d-flex flex-column h-100 ">
            <div class="align-self-center mt-auto">
              <nav  aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        -->
      </div>
    </div>
    <div class="col-1 container d-flex h-100">
      <div class="row flex-grow-1 px-1">
         <button addDeBounce 
                 class="btn btn-primary align-self-center" 
                 (deBounceClick)="doAddCommandHosts()"
         >
           <h2 class="mb-0 ms-2"><i class="bi bi-box-arrow-in-left"></i></h2>
         </button>
         <button addDeBounce 
                 class="btn btn-danger align-self-start" 
                 (deBounceClick)="doRemoveCommandHosts()"
         >
           <h2 class="mb-0 ms-2"><i class="bi bi-box-arrow-in-right"></i></h2> 
         </button>
      </div>

    </div>
    <div class="col-5 h-100 px-3">
      <table class="table table-hover mb-0">
          <thead class="table-dark">
            <tr>
              <th class="col-6 text-center">Client</th>
            </tr>   
          </thead>
      </table>
      
      <div class="h-100 overflow-auto" style="height: 80% !important">
        <table class="table table-hover mb-0">
           <tbody *ngIf="(availableHostsList$ | async) as hosts" >
                <tr  *ngFor="let host of hosts.collection"
                     [class.table-primary]="isHostSelected(host)" 
                     (click)="toggleHostSelect(host)"
                >
                  <td class="text-center">{{ host.hostname }}</td>
                </tr>   
           </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row">
    Host Groups
    <!--
    <div class="col-6">
       <div>
         <div class="row">
           <div class="col pb-2 pe-4">
              <button class="btn btn-primary float-end">
                 <i class="bi bi-plus-square-fill me-1"></i> Add 
              </button>
           </div>
         </div>
         Host Groups
       </div>
    </div>
     -->
  </div>
</div>