import { BaseApiObject } from "../../shared/model/base-api-object";


export class Role extends BaseApiObject {

    public name!: string;
    public description!: string;


}
