import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, of, first, filter, switchMap, map } from 'rxjs';
import { AccessApiService } from '../../shared/services/access-api.service';
import { CurrentUserService } from '../../shared/services/current-user.service';
import { ApiCollection } from '../../shared/model/api-coillection.model';
import { Account } from '../models/account';
import { UserRole } from '../models/user-role.model';
import { ExcClientRequestCfg } from '../../shared/services/access-api.service';
import { sprintf } from 'sprintf-js';

@Injectable({
  providedIn: 'root'
})
export class AccountUsersService {

  loaded$!: BehaviorSubject<boolean> ; 
  userMap$!: BehaviorSubject<{[name:string]: Object}>; //= new Subject<{[name:string]: Object}>() ;

  constructor(
    private accessApi: AccessApiService,
    private currentUser: CurrentUserService
  ) { 
    
    this.loaded$ = new BehaviorSubject<boolean>(false);
    this.userMap$ = new BehaviorSubject<{[name:string]: Object}>({ user_map: {}});

    // TODO replace with refreshUserMap()
    this.currentUser.user$
        .pipe(
            switchMap( user => {
               if ( user.hasAccounts ) {
                  return this.getUserMap()
               }
               else {
                  return of(this.userMap$.value)
               }
            })
        )
        .subscribe( 
           (map) => {

              this.userMap$.next(map)
              this.loaded$.next(true);
           }
        )

  }

  public isLoaded() {
    // Don't emit until we have a true value.
     return this.loaded$.pipe( filter( loaded => loaded ), first() );
  }


  public getUserRoles( cfg?: ExcClientRequestCfg )  {

     return this.getUserRolesCollectionRequest( this.currentUser.currentAccount, cfg ) 
  }

  public getUserRole( userUriKey: string )  {
     
     const cfg = { searchArgs: { attributes: { uri_key: userUriKey } } };

     return this.getUserRolesCollectionRequest( this.currentUser.currentAccount, cfg )
                .pipe(
                  map( (collection) => { return collection.first() }),
                );
  }


  private getUserRolesCollectionRequest( account:  Account,  cfg?: ExcClientRequestCfg ) {

      const path = sprintf('/account_api/accounts/%s/users_roles', account.id );

      return this.accessApi
                 .getCollection(UserRole, path ,cfg )
                 .pipe(
                    first()
                 )
  }

  public updateUserRole( userRole: UserRole )  {

     const account = this.currentUser.currentAccount;

     return this.isLoaded()
                .pipe( 
                  switchMap( (site) => { 
                     return this.updateUserRoleCollectionRequest(account, [ userRole.updateData() ] ) } ),
                  map( (collection) => { return collection.first() })
                );
  }

  public updateUserRoleCollectionRequest( account:  Account, data: Array<Object> ) {
    
      return this.accessApi
                 .updateCollection(UserRole, sprintf('/account_api/accounts/%s/users_roles', account.id), data )
  }


  public getUserMap() {

    const account = this.currentUser.currentAccount;

    return this.accessApi.get(
                            sprintf('/account_api/accounts/%s/users_roles/user_map', account.id )
                         )
                         .pipe( 
                            first(),
                            switchMap( resp => {
                               return of( resp.data.message );  
                            })
                         );

  }

  public inviteUser( user_email: string, role_name: string ) {

    const account = this.currentUser.currentAccount;
    const path    = sprintf('/account_api/accounts/%s/users_roles', account.id );
    const data    = {
        invite_user: user_email,
        role: { name: role_name }
    };

    return this.accessApi
                 .updateCollection(UserRole, path, [data] )
                 .pipe(
                    map( (collection) => { return collection.first() }),
                 );

  }



}
