<div class="container ms-0 ps-0">
  <div class="row justify-content-center">
    <div class="my-4">
      <ul class="nav nav-tabs mb-4 " id="HostDetailTabs" role="tablist">
          <li class="nav-item ps-2">
            <a class="nav-link active text-dark bg-white" 
                id="system-tab" 
            >
            Add Group  
            </a>
          </li>
      </ul>
    </div>

    <form  [formGroup]="groupCreateForm">
      <div class="row px-2">
         <div class="col-6">
           <h5 class="mb-0 ps-2">Settings</h5>
         </div>
         <div class="col-6">
         </div>
      </div>
      <div class="col-10 ms-5 py-4" > 
        <div class="mb-3 row">
          <label for="name" class="form-label fw-bold col-2">Name</label>
          <div class="col-sm-8 col-md-5">
              <input type="text" 
                   class="form-control" 
                   id="name" 
                   aria-describedby="name" 
                   formControlName="name">

               <div *ngIf="getFormControlError( groupCreateForm, 'name', submitted ); let errors"> 
                 <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
                   {{ error.value }} 
                 </div>
               </div>

          </div>
        </div>
        <div class="mb-3 row">
          <label for="description" class="form-label fw-bold col-2">Description</label>
          <div class="col-sm-8 col-md-5">
              <input type="text" 
                   class="form-control" 
                   id="name" 
                   aria-describedby="description" 
                   formControlName="description">

               <div *ngIf="getFormControlError( groupCreateForm, 'description', submitted ); let errors"> 
                 <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
                   {{ error.value }} 
                 </div>
               </div>

          </div>
        </div>
      </div>
      <div class="row">
         <div class="col-5">
         </div>
         <div class="col-6">
            <button type="submit" 
                    class="btn btn-primary"
                    addDeBounce 
                    (deBounceClick)="createGroup()" 
            >
            Add 
            </button>
         </div>
      </div>
    </form>
  </div>
</div>

