import { Component } from '@angular/core';
import { Command } from '../../models/command.model';
import { CommandService } from '../../services/command.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-command-create',
  templateUrl: './command-create.component.html',
  styleUrl: './command-create.component.css'
})
export class CommandCreateComponent {

  public formAction: string = 'Create';

  constructor(
    private commandService: CommandService,
    private router: Router,
    private route: ActivatedRoute
  ) {}


  createCommand( newCommandCfg: Object) {

    let newCommand = new Command(newCommandCfg);

    console.log(newCommand)

    this.commandService
        .addCommand(newCommand)
        .subscribe({
          next: (command) => {
            this.router.navigate([ '../'+ command.uriKey ], { relativeTo: this.route });
          },
          error: (resp) => {
              console.log('error');
              console.log(resp)
          }
        });


  }
}
