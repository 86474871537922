<div class="container" style="height: 90vh">
  <app-loading></app-loading>
  <app-site-error></app-site-error>
  <form class="col-4 m-auto py-5" [formGroup]="ActivateForm" (ngSubmit)="doActivate()">
    <div class="mb-3 row">
      <label for="accActivate" class="form-label fw-bold col-5">Passowrd</label>
      <div class="col-7">
        <input type="password" class="form-control" id="password" aria-describedby="passowrd" formControlName="password">
      </div>
    </div>
    <div class="mb-3 row">

      <label for="accActivate" class="form-label fw-bold col-5">Confirm Passowrd</label>
      <div class="col-7">
        <input type="password" class="form-control" id="confirmPassword" aria-describedby="confirmPassowrd" formControlName="confirmPassword">
      </div>
    </div>

    <button type="submit" class="btn btn-primary float-end">Activate Account</button>
  </form>
</div>

