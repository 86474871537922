<div *ngIf="(host$ | async) as host" class="px-3" >
  <div class="col">
    <form  [formGroup]="systemConfigForm" (ngSubmit)="updateSystemConfig()">
      <fieldset [disabled]="host.id  && !host.hasPermission('edit')">
        <div class="row">
           <div class="col-6">
             <h5 class="mb-0 mt-2">Settings</h5> 
           </div>
           <div class="col-6">
              <button type="submit" class="btn btn-primary">Update</button>
           </div>
        </div>
        <div class="col-10 px-3 py-4" > 
          <div class="mb-3 row">
            <label for="hostname" class="form-label fw-bold col-2">Name</label>
            <div class="col-5">
                <input type="text" 
                     class="form-control col-10" 
                     id="hostname" 
                     aria-describedby="hostname" 
                     formControlName="hostname">
            </div>
          </div>
          <div class="mb-3 row">
            <label for="uriKey" class="form-label fw-bold col-2">URI Key</label>
            <div class="col-5">
              <input type="text" 
                     class="form-control" 
                     id="uriKey" 
                     formControlName="uriKey"> 
            </div>
          </div>
          <div class="mb-3 row">
            <label for="status" class="form-label fw-bold col-2">Status</label>
            <div class="col-5">
              <!---
              <input type="text" 
                     class="form-control" 
                     id="status" 
                     formControlName="status"
                     value="{{ host.status }}">
              -->
              <select class="form-select" formControlName="status" id="status" >
                  <option *ngIf="host.status == 'wait_reg'"  value="wait_reg">
                    Awaiting Registration 
                  </option>
                  <option value="active">
                    Active
                  </option>
                  <option value="disabled">
                    Disabled
                  </option>
              </select>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="notes" class="form-label fw-bold col-12">Notes</label>
            <div class="col-7">
              <textarea 
                     rows="5"
                     class="form-control" 
                     id="notes" 
                     formControlName="notes"> 
              </textarea>

            </div>
          </div>
        </div>
      </fieldset>
    </form>
    <hr class="me-5">
    <div *ngIf="host.status == 'wait_reg'"  class="col-12">
       <h5 class="mb-0 mt-2">Activation Token</h5>
       <div class="col-sm-8 col-md-5 me-5 my-3"  style="height: 50px;">
              <button *ngIf="!activationTokenVisible" class="btn btn-primary" (click)="showActivationToken()">Show Activation Token</button>
              <input *ngIf="activationTokenVisible"
                     class="form-control fs-5 fw-bold"  
                     type='text' 
                     value="{{ activationToken }}"
                     readonly >
       </div>
       <a href="https://wiki.raffiware.io/en/QuickStart#install-and-register-client-agent">How to install client agent</a>
       <hr class="me-5">
    </div>
  </div>
  <div *ngIf="host.status != 'wait_reg'" class="col-12">
    <div class="row">
        <div class="col-12">
          <h5 class="mb-3 mt-2">Client Data</h5>
        <div class="col px-3">
          <div *ngFor="let data_attr of host.clientData" class="mb-3 row">
            <label  class="form-label fw-bold col-3">{{ data_attr.name }}</label>
            <div class="col-4">
              <input type="text" 
                   class="form-control col-10 text-end" 
                   id="hostname" 
                   aria-describedby="hostname"
                   value="{{data_attr.value}}"
                   readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="me-5">
  </div>
  <div class="col-10">
    <div class="row">
       <div class="col-12">
         <h5 class="mb-0 mt-2">Recent Activity</h5>
       </div>
    </div>
    <div class="row p-4">
      <table class="table table-hover">
          <thead class="table-dark">
            <tr>
              <th class="col-3">Date</th>
              <th class="col-1">Level</th>
              <th class="col-8">Text</th>
            </tr>   
          </thead>
          <tbody *ngIf="(logs$ | async) as logs" >
               <tr *ngFor="let log of logs.collection; index as i">
                 <td>{{ log.localDateTime }}</td>
                 <td>{{ log.level }}</td>
                 <td>{{ log.text }}</td>
               </tr>   
          </tbody>
      </table>
    </div>
  </div>
</div>
