import { Component } from '@angular/core';
import { AccessApiService } from '../shared/services/access-api.service';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { CurrentUserService } from '../shared/services/current-user.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApiMessage } from '../shared/interfaces/api-responses';
import { SiteErrorService } from '../shared/modules/rw-utils/modals/site-error/site-error.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent {

  loginForm: FormGroup;
  //subbmitted:  Boolean = false
  //loading:     Boolean = false


  constructor( 
    private AccessApiService: AccessApiService, 
    private CurrentUserService: CurrentUserService,
    private fb: FormBuilder, 
    private storage: LocalStorageService,
    private SiteError: SiteErrorService
  ) {

      this.loginForm = this.fb.group({
        login_email:    ['', [Validators.required, Validators.email] ],
        login_password: ['', [Validators.required ]],
      });

  }

  ngOnInit(): void {
      this.AccessApiService.ping().subscribe( response => console.log(response) );
  }

  get f(): FormGroup { return this.loginForm; }

  public do_login() {
    //console.log(this.f.value);

    if (this.f.invalid) {
      console.log(this.f.errors);
      return;
    }

    this.CurrentUserService.login$( this.f.value.login_email, this.f.value.login_password  )
                           .subscribe({
                              next: () => { this.CurrentUserService.go_to_homepage(); }, 
                              error: (error) => { this.SiteError.setApiError(error); }
                           });

  }
}
