import { Router, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { CurrentUserService } from '../../shared/services/current-user.service';
import { ServiceInstanceService } from '../../account/services/service-instance.service';

export const excollectGuard: CanActivateFn = (route, state) => {
  const currentUserService: CurrentUserService = inject(CurrentUserService);
  const instanceService: ServiceInstanceService = inject(ServiceInstanceService);
  const router: Router = inject(Router);

   //check logged in, check open services
   if ( currentUserService.logged_in === false ) {
       return router.createUrlTree(['/login']);
   }

  return true;
};
