import { BaseApiObject } from "../../shared/model/base-api-object";
import { Account } from "./account";
import { Role } from "./role.model";

export class AccountRole extends BaseApiObject {

    public account!: Account; 
    public role!: Role;

    constructor(
       private accountRoleData?: AccountRole 
    ) { 
       super(accountRoleData);

       delete this.accountRoleData;

       this.account = new Account(this.account);
       this.role    = new Role(this.role);
    }
}
