<div class="container ps-0 ms-0">
  <div class="row justify-content-center">
    <div class="my-4">
      <ul class="nav nav-tabs mb-4 " id="HostDetailTabs" role="tablist">
          <li class="nav-item px-2">
            <a class="nav-link inactive_tab" 
                id="system-tab" 
                routerLink="system"
                routerLinkActive="active text-dark bg-white"
            >
            Settings 
            </a>
          </li>
          <!--
          <li class="nav-item mx-1">
            <a class="nav-link inactive_tab" 
               id="logs-tab" 
               routerLink="logs"
               routerLinkActive="active text-dark bg-white"
            >
            Logs 
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link inactive_tab" 
               id="temrinal-tab" 
               routerLink="terminal"
               routerLinkActive="active text-dark bg-white"
            >
            Terminal
            </a>
          </li>
          -->
      </ul>
    </div>

    <router-outlet></router-outlet> 

  </div>
</div>