"use strict";

//import moment from 'moment-timezone'; 

import log_utils from  './utils/logger.js'
import Signer from  './utils/signer.js'
import { getCurrentTimeStamp } from   './utils/misc.js' 

 
var Utils = function () {

  //var log4jsAPI = require('@log4js-node/log4js-api');
  //var log_utils  = require('./utils/logger');

  const signer = new Signer();
  function getSigner () {
     return signer;
  }
  
  // function generate_timestamp ( tz_string ) {
  // 
  //   const date_now = new Date();
  //   const now      = moment.tz( date_now, ( tz_string || 'utc' ) ); 
  // 
  //   // ISO 8601 with fractional seconds and time zone offset.
  //   return now.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  // } 

  return {
    "getLogger" : log_utils.getLogger,
    "getCurrentTimeStamp": getCurrentTimeStamp,
    "getSigner" : getSigner
  }

};


export default Utils(); 
