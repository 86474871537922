<div *ngIf="(user$ | async) as user" class="px-3" >
  <div class="col">
    <form  [formGroup]="userSettingsForm" (ngSubmit)="updateUserSettings()">
      <div class="row">
         <div class="col-6">
            <h5 class="mb-0 mt-2">User Data</h5> 
         </div>
         <div class="col-6">
            <button type="submit" class="btn btn-primary">Update</button>
         </div>
      </div>
      <div class="col-8 px-3 py-4" > 
        <div class="row p-3">
          <div class="col-md-5 col-sm-12">
            <input type="text" 
                   class="form-control" 
                   placeholder="First name" 
                   aria-label="First name"
                   formControlName="first_name"       
            >
          </div>
          <div class="col-md-5 col-sm-12">
            <input type="text" 
                   class="form-control" 
                   placeholder="Last name" 
                   aria-label="Last name"
                   formControlName="last_name"       
            >
          </div>
        </div>
        <div class="row p-3">
          <div class="col-md-5 col-sm-12">
            <input type="text" 
                   class="form-control" 
                   placeholder="Email Address" 
                   aria-label="Email Address"
                   formControlName="email_addr"       
            >
          </div>
        </div>
      </div>
    </form>
  </div>

</div>

