import { Component } from '@angular/core';
import { Subject, BehaviorSubject, filter, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SiteErrorService } from '../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { LoadingModalService } from '../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { AccountUsersService } from '../../../account/services/account-users.service';
import { AccountUser } from '../../../account/models/account-user.model';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { CurrentUser, CurrentUserLoggedIn } from '../../../shared/model/current-user';
import { matchValidator, getFormErrors } from '../../../utils/component-utils';


@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrl: './user-settings.component.css'
})
export class UserSettingsComponent {

  public user$!: BehaviorSubject<CurrentUser | CurrentUserLoggedIn>;

  public userSettingsForm: FormGroup  = new FormBuilder().group({
                                              first_name: ['', [Validators.required ] ],
                                              last_name: ['', [Validators.required ]],
                                              email_addr: ['', [Validators.required ]],
                                            });

  public userPasswordForm: FormGroup  = new FormBuilder().group(
                                             {
                                              oldPassword: ['', [Validators.required ] ],
                                              newPassword: ['', [Validators.required ]],
                                              confirmNewPassword: ['', [Validators.required ]],
                                             }, 
                                             {
                                               validators: matchValidator('newPassword', 'confirmNewPassword', 'Password do not match')
                                             }
                                            );



  constructor( 
    private route: ActivatedRoute, 
    private SiteError: SiteErrorService,
    private LoadingModal: LoadingModalService,
    private currentUser: CurrentUserService,
    private UserService: AccountUsersService
  ) {
  }

  ngOnInit(){

    this.currentUser.user$
        .subscribe( (user) => {
          
          this.userSettingsForm.setValue({ 
                                  first_name: user.first_name, 
                                  last_name:  user.last_name, 
                                  email_addr: user.email_addr,
                                });

          this.user$ = new BehaviorSubject<CurrentUser | CurrentUserLoggedIn>(user);
          
        });

  }

  get uf(): FormGroup { return this.userSettingsForm; }

  updateUserSettings() {

    if (this.uf.invalid) {

      // TODO generalize into some error reporting service
      const result: any[] = [];
      Object.keys(this.uf.controls).forEach(key => {
    
        const controlErrors = this.uf.get(key)?.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            result.push({
              'control': key,
              'error': keyError,
              'value': controlErrors[keyError]
            });
          });
        }
      });

      return;
    }

    this.LoadingModal.setLoading();

    const formVals    = this.uf.value; 
    const userData = {   
       id:         this.currentUser.user.id,
       first_name: formVals.first_name, 
       last_name:  formVals.last_name, 
       email_addr: formVals.email_addr,
    };
    const updatedUser = Object.assign( new AccountUser(), userData) ; 

    this.currentUser
        .updateUser(updatedUser)
        .subscribe({
          next: (user) => {
            //console.log('success');

            this.user$.next(user);
            this.LoadingModal.unsetLoading();
          },
          error: (error) => {
            this.LoadingModal.unsetLoading();
            this.SiteError.setApiError(error);
          }
        });

  }

  get pf(): FormGroup { return this.userPasswordForm; }



  updateUserPassword() {

    const errors = getFormErrors(this.userPasswordForm) 
    if(errors) {
        console.log(errors);
        return
    } 

    this.LoadingModal.setLoading();

    const formVals = this.pf.value; 

    this.currentUser
        .changePassword(formVals.oldPassword, formVals.newPassword)
        .subscribe({
          next: (resp) => {
            //console.log('success');
            this.pf.setValue({ 
                      oldPassword: '', 
                      newPassword:  '', 
                      confirmNewPassword: '',
                    });

            this.LoadingModal.unsetLoading();
          },
          error: (error) => {
            this.LoadingModal.unsetLoading();
            this.SiteError.setApiError(error);
          }
        });

  }
}
