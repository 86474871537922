  <div class="row " style="min-height: 90vh;" >
    <div class="p-0 col-md-2" >
       <excollect-navbar></excollect-navbar>
    </div>
    <div class="p-0 col-md-10" >
      <app-site-error></app-site-error>
      <app-loading></app-loading>

      <router-outlet></router-outlet>
    </div>
  </div>
