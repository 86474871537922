import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { switchMap, filter, combineLatestWith, first } from 'rxjs';
import { CommandInstanceService } from '../../services/command-instance.service';
import { HostJobService } from '../../services/host-job.service';

@Component({
  selector: 'app-host-job',
  templateUrl: './host-job.component.html',
  styleUrl: './host-job.component.css'
})
export class HostJobComponent {

  public uriKey!: string;

  constructor(
    private CommandInstanceService: CommandInstanceService, 
    private HostJobService: HostJobService, 
    private route: ActivatedRoute,
    private router: Router,
  ) {}


  ngOnInit(){

    this.route.params
              .pipe(
                filter( params => params['host_job_id'] ),
                combineLatestWith(this.CommandInstanceService.currentInstance$),
                filter( ([params, instance]) => instance.id !== 'stub' ),
                switchMap(([params, instance]) => { 
                  return this.CommandInstanceService.getJob(params['host_job_id'])  
                }),
                first() // always put this or subscribes persist.
              )
              .subscribe( hostJob => {
                 this.HostJobService.setCurrentHostJob(hostJob);
                 this.uriKey = <string>hostJob.uriKey
                 console.log('host job init')
              });

  }



}
