import { Component } from '@angular/core';
import { HostService } from '../../services/host.service';
import { SiteService } from '../../services/site.service';
import { BehaviorSubject, of } from 'rxjs';
import { Host, HostStub } from '../../models/host.model'; 
import { first, tap, filter, switchMap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { SiteError, SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';

@Component({
  selector: 'app-create-host',
  templateUrl: './create-host.component.html',
  styleUrl: './create-host.component.css'
})
export class CreateHostComponent {

  public systemCreateForm!: FormGroup; 

  constructor( 
    private HostService: HostService, 
    private siteService: SiteService,
    private router: Router,
    private route:  ActivatedRoute,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService
  ) {
       this.systemCreateForm = new FormBuilder().group({
                                     hostname: [ '', 
                                                 [ Validators.required, 
                                                   // this.HostService.hostnameValidator 
                                                 ] 
                                               ],
                                   });
  }

  get cf(): FormGroup { return this.systemCreateForm; }

  createSystem() {


    if (this.cf.invalid) {

      console.log(this.cf.controls);
      
      return;
    }
    
    this.LoadingModal.setLoading();

    let formVals    = this.cf.value; 
    let newHost  = new Host({ 
                            hostname: formVals.hostname, 
                          }); 

    this.HostService
        .addHost(newHost)
        .subscribe({
          next: (host) => {
            this.LoadingModal.unsetLoading();
            this.router.navigate([ '../'+ host.uriKey ], { relativeTo: this.route });
          },
          error: (error) => {
              this.LoadingModal.unsetLoading();
              this.SiteError.setApiError(error);
          }
        });

  }

}
