import { Component } from '@angular/core';
import { HostService } from '../../services/host.service';
import { SiteService } from '../../services/site.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, BehaviorSubject, of, forkJoin, combineLatestWith } from 'rxjs';
import { first, tap, filter, switchMap, subscribeOn } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { SiteUserGroup } from '../../models/site-user-group.model';
import { UserGroupService } from '../../services/user-group.service';
import { SiteUser } from '../../models/site-user.model';
import { AccountUsersService } from '../../../../account/services/account-users.service';


@Component({
  selector: 'app-user-group-detail',
  templateUrl: './user-group-detail.component.html',
  styleUrl: './user-group-detail.component.css'
})
export class UserGroupDetailComponent {

  public group$!: BehaviorSubject<SiteUserGroup>; // = new Subject<SiteUserGroup>();  
  public members$!: BehaviorSubject<ApiCollection<SiteUser>>; // = new Subject<ApiCollection<SiteUser>>();  
  public available$!: BehaviorSubject<ApiCollection<SiteUser>>; // = new Subject<ApiCollection<SiteUser>>();  

  //public members$: Subject<ApiCollection<SiteUser>> = new Subject<ApiCollection<SiteUser>>();  
  public userMap!: { [name: string]: any };

  // FIXME init in constructor 
  private selectedAvailable: { [ key: string ]: any } = {};
  private selectedGroupUsers: { [ key: string ]: any } = {};
  private existingGroupUser: { [ key: string ]: any } = {};


  public groupConfigForm: FormGroup  = new FormBuilder().group({
                                              name: ['', [Validators.required ] ],
                                              //uriKey: ['', [Validators.required ]],
                                              description: ['', [Validators.required ]],
                                              //notes: ['', []]
                                            });

  public user_get_cfg = { 
    searchAttrs: { 
       limit: 10, 
       page:  1 
    } 
  };



  constructor( 
    private AccountUserService: AccountUsersService, 
    private UserGroupService: UserGroupService, 
    private route: ActivatedRoute,
    private siteService: SiteService,
    private LoadingModal: LoadingModalService

  ) {}

  ngOnInit(){

    const cfg = { searchAttrs: { 
                     limit: 10, 
                     page:  1 
                  } 
                };


    this.route.params
              .pipe(
                filter( params => params['site_group_id'] ),
                combineLatestWith( 
                   this.AccountUserService.isLoaded() 
                ),
                switchMap(([ params, loaded ]) => { 
                  console.log(loaded);
                  return this.UserGroupService.getGroup(params['site_group_id'])  
                }),
                switchMap((group) =>  forkJoin([
                      of(group),
                      of(this.AccountUserService.userMap$.value),
                      this.UserGroupService.getGroupMembers(group, cfg ), //.pipe( first() ),
                      this.UserGroupService.getAvailableUsers(group, cfg ), //.pipe( first() ),
                      //this.UserGroupService.getCommandAvailableHosts(command), //.pipe( first() ),
                ])), 

              )
              .subscribe( ([group, userMap, members, available]) => {

                 //console.log(userMap);
               
                 this.groupConfigForm.setValue({ 
                                         name:         group.name, 
                                         description:  group.description,
                                       });

                 this.userMap = userMap['user_map'];
                 this.group$   = new BehaviorSubject(group); 
                 this.members$ = new BehaviorSubject(members);
                 this.available$ = new BehaviorSubject(available)

              });

    // order_by => { asc => 'created' }

  }

  toggleAvailableSelect(user: SiteUser) {
    if ( this.selectedAvailable[user.id] ) {
        this.selectedAvailable[user.id] = false
    }
    else {
        this.selectedAvailable[user.id] = true
    }

  }

  isAvailableSelected( user: SiteUser) {
     return this.selectedAvailable[user.id]
  }

  toggleGroupUserSelect(user: SiteUser) {
    if ( this.selectedGroupUsers[user.id] ) {
        this.selectedGroupUsers[user.id] = false
    }
    else {
        this.selectedGroupUsers[user.id] = true
    }

  }

  isGroupUserSelected( user: SiteUser) {
     return this.selectedGroupUsers[user.id]
  }

  updateGroupConfig() {

  }

  doAddMember() {
    const group     = this.group$.value;

    const newMembers: Array<string> = Object.keys(this.selectedAvailable)

    this.selectedAvailable = {};

    if ( newMembers.length == 0 ) {
        console.log('no hosts selected');
        return
    }

    this.UserGroupService
        .addGroupUsers( group, newMembers )
        .pipe(
            switchMap((groupUsers) =>  forkJoin([
             this.UserGroupService.getGroupMembers(group, this.user_get_cfg ),
             this.UserGroupService.getAvailableUsers(group, this.user_get_cfg ),

            ])), 
        )
        .subscribe( ([groupMembers, availableUsers]) => {
             this.members$.next(groupMembers);  
             this.available$.next(availableUsers);  
        })

  }

  doRemoveMember() {
    const group = this.group$.value;

    const removeMembers: Array<string> = Object.keys(this.selectedGroupUsers)

    this.selectedGroupUsers = {};

    if ( removeMembers.length == 0 ) {
        console.log('no hosts selected');
        return
    }

    this.UserGroupService
        .removeGroupUsers( group, removeMembers )
        .pipe(
            switchMap((groupUsers) =>  forkJoin([
             this.UserGroupService.getGroupMembers(group, this.user_get_cfg ),
             this.UserGroupService.getAvailableUsers(group, this.user_get_cfg ),

            ])), 
        )
        .subscribe( ([groupMembers, availableUsers]) => {
             this.members$.next(groupMembers);  
             this.available$.next(availableUsers);  
        })

  }

  loadAvailablePage( page: number ) {
      
      const available = this.available$.value;
      const group     = this.group$.value;

      const cfg = { searchAttrs: { 
                       limit: available.searchAttrs.limit, 
                       page: page 
                    } 
                  };
     
      this.UserGroupService.getAvailableUsers( group, cfg )
                           .subscribe( (userCollection) => {
                               this.available$.next(userCollection);  
                           });


  }

  loadMembersPage( page: number ) {
      
      const members     = this.members$.value;
      const group     = this.group$.value;

      const cfg = { searchAttrs: { 
                       limit: members.searchAttrs.limit, 
                       page: page 
                    } 
                  };
     
      this.UserGroupService.getGroupMembers( group, cfg )
                           .subscribe( (memberCollection) => {
                               this.members$.next(memberCollection);  
                           });


  }


}
