import { Component } from '@angular/core';

@Component({
  selector: 'app-management-nav',
  templateUrl: './management-nav.component.html',
  styleUrl: './management-nav.component.css'
})
export class ManagementNavComponent {

}
