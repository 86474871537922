import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators } from '@angular/forms';
import { SiteService } from '../../services/site.service';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { UserGroupService } from '../../services/user-group.service';
import { SiteUserGroup } from '../../models/site-user-group.model';
import { getFormControlError } from '../../../../utils/component-utils';

@Component({
  selector: 'app-new-group',
  templateUrl: './new-group.component.html',
  styleUrl: './new-group.component.css'
})
export class NewGroupComponent {

  public groupCreateForm!: FormGroup; 
  public getFormControlError = getFormControlError;
  public submitted: Boolean = false;

  constructor( 
    private siteService: SiteService,
    private groupService: UserGroupService,
    private router: Router,
    private route:  ActivatedRoute,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService
  ) {
       this.groupCreateForm = new FormBuilder().group({
                                     name: [ '', [ Validators.required, ] ],
                                     description: [ '', [ Validators.required, ] ],
                                   });
  }

  get gf(): FormGroup { return this.groupCreateForm; }

  createGroup () {

    this.submitted = true;

    if (this.gf.invalid) {
      return;
    }
    
    this.LoadingModal.setLoading();

    const formVals  = this.gf.value; 
    const newGroup  = new SiteUserGroup(); 
   
    newGroup.name        = formVals.name, 
    newGroup.description = formVals.description,

    this.groupService
        .addGroup(newGroup)
        .subscribe({
          next: (group) => {
            this.LoadingModal.unsetLoading();
            this.router.navigate([ '../'+ group.uriKey ], { relativeTo: this.route });
          },
          error: (error) => {
              this.LoadingModal.unsetLoading();
              this.SiteError.setApiError(error);
          }
        });

  }

}
