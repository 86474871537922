import { Component } from '@angular/core';

@Component({
  selector: 'app-terminal-tab',
  templateUrl: './terminal-tab.component.html',
  styleUrl: './terminal-tab.component.css'
})
export class TerminalTabComponent {

}
