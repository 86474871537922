import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import $ from 'jquery';
//import * as bootstrap from 'bootstrap';
import Modal from 'bootstrap/js/dist/modal'; // Drop down menus break if we just from boostrap 
import { LoadingModalService } from './loading-modal.service';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.css'
})
export class LoadingComponent {

  private loadingSubscription: Subscription = new Subscription();
  private bsModal: any;


  constructor( 
    private LoadingModal: LoadingModalService 
  ) {
    //let modal = $('#loadingModal');
    //this.bsModal = new bootstrap.Modal(modal[0],{} );
  }

  ngOnInit(){
      this.loadingSubscription = this.LoadingModal.loadingStatus$
                                             .subscribe( (status) => { 
                                            
                                              if ( status === true ) {

                                                this.bsModal = new Modal('#loadingModal',{} );
                                                this.bsModal.show();
                                              }
                                              else if ( this.bsModal ) {
                                                this.bsModal.hide();
                                              }
                                             })
  }

  ngOnDestroy(): void {
     this.loadingSubscription.unsubscribe();
  }


}
