import { BaseApiObject } from "../../../shared/model/base-api-object";
import { WithAttributes } from "../mixins/with-attributes.mixin";

export type Parameter = { name: string, type: string, opts: ParamOpts, default?: string };
export type ParamOpts = { regex?:string, options?: Array<string>, lower?: number, upper?: number };
export class Command extends WithAttributes(BaseApiObject) {

    public is_stub: boolean = false;

    public type!: string;
    public name!: string;
    public description!: string;
    public command_string!: string
    public creator!: string
    public parameters!: Array<Parameter>

    constructor(
       private commandData?: Command 
    ) { 
       super(commandData);

       delete this.commandData;

       this.parameters = this.parameters
                            ?.sort((a, b) =>{ 
                                  if (a.name < b.name ) {
                                    return -1;
                                  }
                                  if (a.name > b.name ) {
                                    return 1;
                                  }
                                  return 0;
                              }); 
    }



    public override get onCreate() { 
       return ['type', 'name','description', 'command_string', this.updateAttributes.bind(this) ] 
    };

    public override get writable() { 
       return [ 'name','description', 'command_string', 'parameters', this.updateAttributes.bind(this) ] 
    };

    public override writableAttributes = ['max_retry', 'command_priority', 'execute_type', 
                                          'script_url', 'script_src' ];
    get uriKey() {
      return this.getAttribute('uri_key');
    }

    get executeType() {
      return this.getAttribute('execute_type');
    }


    public has_parameters(): Boolean {
       return ( this.parameters?.length > 0 ) ? true : false;
    } 

}

export class CommandStub extends Command { 

   public override is_stub: boolean = true;

   constructor() {
     super();

     this.name            = '';
     this.command_string  = '';
     this.description     = ''; 
     this.type            = '';
     this.attributes   = { 
       uri_key:  { 
          name: 'uri_key',
          type: 'string',
          description: '',
          value:      '',
          group:  'CONFIG'
       }, 
       execute_type:  { 
          name: 'execute_type',
          type: 'string',
          description: '',
          value:      'bin',
          group:  'CONFIG'
       } 

    },
    this.user_permissions = {};
   }
}
