import { Injectable } from '@angular/core';
import { AccessApiService, ExcClientRequestCfg  } from '../../../shared/services/access-api.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { SiteService } from './site.service';
import { CommandInstanceService } from './command-instance.service';
import {sprintf} from "sprintf-js";
import { HostJob, HostJobStub } from '../models/host-job.model';
import { Site } from '../models/site.model';
import { Command } from '../models/command.model';
import { HostJobLog } from '../models/host-job-log.model';

@Injectable({
  providedIn: 'root'
})
export class HostJobService {

  public currentHostJob!: HostJob;
  public currentHostJob$: BehaviorSubject<HostJob> = new BehaviorSubject<HostJob>(new HostJobStub);  

  constructor(
    private accessApi: AccessApiService,
    private siteService: SiteService,
    private commandInstanceService: CommandInstanceService
  ) { }

  public setCurrentHostJob( hostJob: HostJob )  {

     if ( this.currentHostJob?.uriKey !== hostJob.uriKey ) {
        console.log('new current');
        this.currentHostJob$.next(hostJob)
        this.currentHostJob = hostJob;

     }
     
     //return this.commandInstanceService.getJob(hostJobUriKey)
     //           .pipe( tap((hostJob) => { this.currentHostJob = hostJob }) );
  }

   public clearCurrentHostJob() {
        
        this.currentHostJob = new HostJobStub;
        this.currentHostJob$.next(this.currentHostJob);
   }

   public getJobLogs( job: HostJob, cfg?: ExcClientRequestCfg)  {

     console.log(cfg);
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getJobLogsCollectionRequest(site, job, cfg) 
                  }),
                  first()
                );
  }

  private getJobLogsCollectionRequest( site:  Site, job: HostJob, cfg?: ExcClientRequestCfg ) {

      let path = sprintf('/excollect_api/sites/%s/host_jobs/%s/job_logs', site.id, job.id );

      return this.accessApi
                 .getCollection(HostJobLog, path , cfg)
  }

}
