<div class="container h-100 ms-0 ps-0 d-flex flex-column ">
  <div class="row justify-content-center">
    <div class="pt-4">
      <ul class="nav nav-tabs " id="HostDetailTabs" role="tablist">
        <li class="nav-item ps-2">
          <a class="nav-link inactive_tab" 
              id="config-tab" 
              routerLink="config"
              routerLinkActive="active text-dark bg-white"
          >
          Controls
          </a>
        </li>
        <li class="nav-item ps-1">
          <a class="nav-link inactive_tab" 
             id="hosts-tab" 
             routerLink="hosts"
             routerLinkActive="active text-dark bg-white"
          >
          Client Jobs 
          </a>
        </li>
      </ul>
    </div>
  </div>
  <router-outlet></router-outlet> 

</div>