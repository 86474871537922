<div id='mgmnt-side-nav' class="h-100 bg_secondary">

  <ul class="nav align-items-start flex-column h-100 ms-2 ">
    <li class="nav-item ">
      <a class="nav-link" 
         routerLink="user"
         routerLinkActive="text-bg-secondary"
      >
      User 
      </a>
    </li>
    <!--
    <li class="nav-item ">
      <a class="nav-link" 
         routerLink="accounts"
         routerLinkActive="text-bg-secondary"
      >
      Accounts 
      </a>
    </li>
    -->
  </ul>

</div>

