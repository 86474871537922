<div class="container d-flex flex-column h-100">
  <div class="row px-4">
    <div class="col-8 ps-4 pt-4">
      <a [routerLink]="['create']">         
         <button class="btn btn-primary">
            <i class="bi bi-plus-square-fill me-1"></i> Add Client 
         </button>
      </a>
    </div>
    <div class="col-4  px-4 pt-4">
      <!--
       <input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search...">
       -->
    </div>
  </div>
  <div class="row p-4">
    <table class="table table-hover">
        <thead class="table-dark">
          <tr>
            <th scope="col">Client</th>
            <th scope="col">Status</th>
            <th scope="col">Last Check-In</th>
          </tr>   
        </thead>
        <tbody *ngIf="(hosts$ | async) as hosts; else loadingBlock" >
             <tr *ngFor="let host of hosts.collection; index as i" (click)="loadHostDetail(host)">
               <td>{{ host.hostname }}</td>
               <td *ngIf="host.status!=='wait_reg' else waitRegBlock"  >{{ host.status }}</td>
               <td>{{ host.last_checkin_diff }}</td>
             </tr>   
        </tbody>
    </table>
  </div>
  <div *ngIf="(hosts$ | async) as hosts " class="row mt-auto mx-auto">
    <app-pager [collection]="hosts" (newPage)="loadPage($event)"></app-pager>
  </div>
  <ng-template #loadingBlock>
    <p>Loading...</p>
  </ng-template>
  <ng-template #waitRegBlock>
     <td>Awaiting Registration</td>
  </ng-template>

</div>