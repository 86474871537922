
<nav class="navbar navbar-expand-sm navbar-light bg-dark">
  <div class="container-fluid">
    <div id="nav-title" class="navbar-brand text-light ms-2 fs-2">
      <a routerLink="{{url()}}"><span>ExCollect</span></a>
    </div>
    <ng-container *ngIf="loggedIn(); then loggedInDD; else loggedOutDD"></ng-container> 
  </div>

</nav>

<ng-template #loggedInDD>
  <app-logged-in-drop-down></app-logged-in-drop-down>
</ng-template>
<ng-template #loggedOutDD>
  <app-guest-drop-down></app-guest-drop-down>
</ng-template>
