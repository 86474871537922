import { Injectable } from '@angular/core';
import { ApiCollectionResponse } from '../../../shared/interfaces/api-responses'; 
import { AccessApiService, ExcClientRequestCfg } from '../../../shared/services/access-api.service'; 
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { pipe } from 'rxjs';
import { first, tap, filter, switchMap } from 'rxjs/operators';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { Site } from '../models/site.model';
import { Host } from '../models/host.model';
import { SiteUser } from '../models/site-user.model';
import {sprintf} from "sprintf-js";
import { ApiCollection } from '../../../shared/model/api-coillection.model';
import { LoggerService } from '../../../shared/services/logger.service';
import { CurrentUserService } from '../../../shared/services/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  activeSiteUser!: SiteUser;
  activeSiteUser$!: BehaviorSubject<SiteUser>;
  activeSiteHosts$!: BehaviorSubject<ApiCollection<Host>>;
  activeSiteUrl!: string;
  siteUsersCollection!: ApiCollection<SiteUser>;
  loaded$: BehaviorSubject<boolean> ; 
  uriMap: { [ uri_key: string ] : SiteUser };

  constructor(
    private accessApi: AccessApiService,
    private loggerService: LoggerService,
    private currentUserService: CurrentUserService,
    private router: Router

  ) { 
      this.loaded$ = new BehaviorSubject<boolean>(false);
      this.uriMap = {};
  }

  public loadSiteUsers( activeSiteKey?: string ): void {
     this.accessApi.get('/excollect_api/users')
                   .subscribe({ 
                       next: response => {
                       
                          this.siteUsersCollection = new ApiCollection( SiteUser, response );  

                          this.siteUsersCollection.collection.map( ( siteUser: SiteUser ) => {
                             // console.log( siteUser.site.getAttribute('uri_key') )
                              this.uriMap[siteUser.site.uriKey] = siteUser });

                          this.initDefaultActiveSite();
                          this.loaded$.next(true);
                      },
                      error: error => {
                          this.loggerService.error('Failed to load SiteUsers')
                      }
                   }) 
  }

  public isLoaded() {
    // Don't emit until we have a true value.
     return this.loaded$.pipe( filter( loaded => loaded ), first() );
  }

  public setActiveSiteUserByUri( uri_key: string ): void {

      this.isLoaded().subscribe( () => {
          if ( this.uriMap[uri_key] == undefined ) {
             this.router.navigate([ '/excollect/NotFound' ]);
          }
          else {
             this.setActiveSiteUser(this.uriMap[uri_key]);
          }
      });
  }
  
  private initDefaultActiveSite(): void {

      this.activeSiteUser =  this.siteUsersCollection.collection[0]
      //this.activeSiteUser$ = new BehaviorSubject<SiteUser>(this.activeSiteUser);
     if ( !this.activeSiteUser ) {
        this.router.navigate([ '/excollect/NotFound' ]);
     }

      //this.activeSiteUrl  = sprintf('/excollect/%s', this.activeSiteUser.site.uriKey);
      this.setActiveSiteUser(this.activeSiteUser);
  }

  private setActiveSiteUser( siteUser: SiteUser ): void {
    this.activeSiteUrl  = sprintf('/excollect/%s', siteUser.site.uriKey);
    this.activeSiteUser = siteUser;
 
    if ( this.activeSiteUser$ ) {
       this.activeSiteUser$.next(siteUser);
    }
    else {
       this.activeSiteUser$ = new BehaviorSubject<SiteUser>(siteUser);
    }
    
  }

  public getActiveSiteUser()  {
     
     return this.isLoaded()
                .pipe( 
                   //filter( loaded => loaded ),
                   switchMap( () => this.activeSiteUser$ )
                );

  }

  public getActiveSite()  {
     
     return this.getActiveSiteUser()
                .pipe( 
                   switchMap( (siteUser) => of(siteUser.site) )
                );

  }

  public getActiveSiteHosts( ) {

    if ( this.activeSiteHosts$ ) {
       return this.activeSiteHosts$ 
    }
    else {
       return this.getHosts() 
                  .pipe(
                    tap(hostCollection => {
                       this.activeSiteHosts$ = new BehaviorSubject<ApiCollection<Host>>(hostCollection);
                    }) 
                  );
            //switchMap( hostsCollecction => {
            //    return of(hostsCollecction)
            //}) 
    }
  }

  public getHosts( cfg?: ExcClientRequestCfg )  {

     return this.getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getHostCollectionRequest(site, cfg) 
                  }),
                  //map( (collection) => { return collection.first() })
                );
  }

  private getHostCollectionRequest( site:  Site,  cfg?: ExcClientRequestCfg ) {

      let path = sprintf('/excollect_api/sites/%s/hosts', site.id );

      return this.accessApi
                 .getCollection(Host, path , cfg)
  }

  // get('uri', { params: { answer: 42 } } )
}
