import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccessApiService } from '../../../../shared/services/access-api.service';
import { HostService } from '../../services/host.service';
import { Observable, switchMap, of, Subject, first, BehaviorSubject } from 'rxjs';
import { Host } from '../../models/host.model';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';


@Component({
  selector: 'app-hosts-list',
  templateUrl: './hosts-list.component.html',
  styleUrl: './hosts-list.component.css'
})
export class HostsListComponent {

  public hosts$!: BehaviorSubject<ApiCollection<Host>>

  constructor( 
    private HostService: HostService, 
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(){
      
      let cfg = { searchAttrs: { limit: 15, order_by: { asc: 'last_checkin' } } };

      this.HostService.getHosts(cfg).subscribe( (hostCollection) => {
          
          this.hosts$ = new BehaviorSubject<ApiCollection<Host>>(hostCollection);  

      });

  }

  loadHostDetail(host: Host): void {
    this.router.navigate([ host.uriKey ], { relativeTo: this.route });
  }

  loadPage( page: number ) {
      let hosts = this.hosts$.value;

      let cfg = { searchAttrs: { 
                     order_by: { desc: 'last_checkin' },
                     limit: hosts.searchAttrs.limit, 
                     page: page 
                  } 
                };
      
      this.HostService.getHosts(cfg).subscribe( (hostCollection) => {
          this.hosts$.next(hostCollection);  
      });


  }
}
