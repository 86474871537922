import { Component } from '@angular/core';

@Component({
  selector: 'excollect-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class ExcollectNavbarComponent {

}
