import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { FrontPageComponent } from './front-page/front-page.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { NotFoundComponent } from './shared/component/not-found.component'; 
import { AccountActivateComponent } from './register/account-activate.component';
import { ServiceSetupComponent } from './register/service-setup.component';
import { notLoggedInGuard } from './current-user/not-logged-in.guard';

export const routes: Routes = [
  { path: 'home', component: FrontPageComponent },
  { path: 'login',  
    canActivate: [notLoggedInGuard],
    component: LoginComponent 
  },
  { path: 'signup', 
    canActivate: [notLoggedInGuard],
    component: RegisterComponent },
  { path: 'activate', 
    canActivate: [notLoggedInGuard],
    component: AccountActivateComponent 
  },
  { path: 'service_setup', component: ServiceSetupComponent },
  { path: '', redirectTo: '/excollect', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent }
];

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
