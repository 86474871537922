import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingModalService } from '../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { SiteErrorService, SiteError } from '../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { AccountUsersService } from '../../services/account-users.service';
import { getFormControlError } from '../../../utils/component-utils';
import { combineLatestWith, of } from 'rxjs';



@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrl: './user-add.component.css'
})
export class UserAddComponent {

  public newUserForm!: FormGroup; 
  public getFormControlError = getFormControlError;
  public submitted: Boolean = false;

  constructor( 
    private router: Router,
    private route:  ActivatedRoute,
    private UserService: AccountUsersService,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService
  ) {
       this.newUserForm = new FormBuilder().group({
                                              email: [ '', 
                                                          [ Validators.required, 
                                                            Validators.email
                                                            // this.HostService.hostnameValidator 
                                                          ] 
                                                        ],
                                              role: ['Service', [ Validators.required] ]
                                            });

      //this.newUserForm.setValue({ role: 'Service' });
  }

  get nf(): FormGroup { return this.newUserForm; }

  inviteUser() {

    if (this.nf.invalid) {
      
      return;
    }

    this.submitted = true;
    
    this.LoadingModal.setLoading();

    let formVals    = this.nf.value; 

    this.UserService
        .inviteUser( formVals.email, formVals.role )
        .pipe (
          combineLatestWith( 
             of(this.UserService.userMap$.value),
          )
        )
        .subscribe({
          next: ([userRole, userMap]) => {

            // this doesn't really help since the SiteUser wont exist until
            // the user is activated.
            const map = <{[name: string]: any}>userMap['user_map'];
            map[userRole.user.id] = {
              email_addr: userRole.user.email_addr,
              first_name: userRole.user.first_name,
              last_name:  userRole.user.last_name,
              role:       userRole.role.name,
              status:     userRole.status
            }

            this.LoadingModal.unsetLoading();
            this.router.navigate([ '../'+ userRole.uriKey ], { relativeTo: this.route });
          },
          error: (error) => {
              this.LoadingModal.unsetLoading();
              this.SiteError.setApiError(error);
          }
        });



  }

}
