import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingModalService {

  loadingStatus$: Subject<Boolean> = new Subject();

  constructor() { }

  public setLoading() {
      this.loadingStatus$.next(true);
  }


  public unsetLoading() {
      this.loadingStatus$.next(false);
  }
}
