import { BaseApiObject } from "../../shared/model/base-api-object";
import { Account } from "./account";
import { Role } from "./role.model";
import { AccountUser } from "./account-user.model";
import { WithAttributes } from "../../service/excollect/mixins/with-attributes.mixin";

export class UserRole extends WithAttributes(BaseApiObject) {
    public user!: AccountUser; 
    public role!: Role;
    public status!: string;
    public activation_link!: string;

    constructor(
       private userRoleData?: UserRole 
    ) { 
       super(userRoleData);

       delete this.userRoleData;

       this.user  = new AccountUser(this.user);
       this.role  = new Role(this.role);
    }

    public override get writable() { 
        return ['status', 'role' ] 
    };


    get uriKey(): string {
      //return <string>this.attributes['uri_key'].value
      return <string>this.getAttribute('uri_key');
    }


}
