import { BaseApiObject } from "../../../shared/model/base-api-object";
import { localizeDateTime } from "../../../utils/model-utils";

export class HostJobLog extends BaseApiObject {

   public level!: string;
   public text!: string;
   public datetime!: string;
   public site!: string;

   get localDateTime() {
      return localizeDateTime(this.datetime);
   }

}
