"use strict";

import nonce from 'nonce';
import log_utils from  './logger.js';
import { getCurrentTimeStamp } from './misc.js';
import { sha256  } from  'js-sha256'
import jsrsasign from 'jsrsasign'
import { hexToUint8Array, uint8ArrayToBase64 } from 'uint8array-extras';


function Signer() {

  const logger = log_utils.getLogger(); 

  /*
  *  key_id 
  *  time_offset
  *  priv_key
  *
  */
  this.sign_api_request = function ( req_cfg, opts )  {

      install_sig_headers( req_cfg, opts );

      return req_cfg;
  }

  function install_sig_headers ( req_cfg, opts ) {

    const tokens = get_tokens_from_request( req_cfg, opts);

    //logger.debug(tokens);

    req_cfg.headers['X-EXC-KeyID']      = tokens['KeyID'];
    req_cfg.headers['X-EXC-Nonce']      = tokens['Nonce'];
    req_cfg.headers['X-EXC-TimeOffset'] = tokens['TimeOffset']; 
    req_cfg.headers['X-EXC-TimeStamp']  = tokens['TimeStamp'];
    req_cfg.headers['X-EXC-Signature']  = generate_signature( tokens, opts['priv_key'] );

  } 

  function get_tokens_from_request ( req_cfg, opts ) {

    //const request_url    = req_cfg.baseURL + req_cfg.url; 
    const url            = new URL( req_cfg.baseURL + req_cfg.url );
    //console.log('fffuuuff');
    //console.log([req_cfg.url]);

    // query_string in request path must be URI encoded before signing.
    const method          = req_cfg.method.toLowerCase();

                            // Axios unescapes ':' so we have to unscape it for our 
                            // token value too so everything matches up on the server.
                            // See: axios/lib/helpers/buildURL.js
    const query_string    = new URLSearchParams(req_cfg.params).toString()
                                                               .replace(/%3A/gi, ':')
                                                               .replace(/,/gi, '%2C');
                                                               //.replace(/%24/g, '$')
                                                               //.replace(/%20/g, '+')
                                                               //.replace(/%5B/gi, '[')
                                                               //.replace(/%5D/gi, ']');


    const request_path    = url.pathname + ( query_string ? '?'+  query_string : '' );
    const request_content = req_cfg.data === undefined 
                                ? '' 
                                : req_cfg.data; 
    //console.log('content: ', request_content);

    const hashed_content = sha256.create()
                                 .update( request_content )
                                 .hex(); 

    const tokens = {
      'Content'      : hashed_content,
      'KeyID'        : opts['key_id'],
      'Nonce'        : generate_nonce(),
      'RequestMethod': method,
      'Resource'     : req_cfg.baseURL,
      'ResourcePath' : request_path,
      'TimeOffset'   : opts['time_offset'],
      'TimeStamp'    : getCurrentTimeStamp()
    };
    //console.log(tokens);

    return tokens;
  }

  this.sign_command_instance = function ( tokens, priv_key_string ) {
      return generate_signature( tokens, priv_key_string );
  }

  function generate_signature ( tokens, priv_key_string ) {

     const msg     = generate_msg_from_tokens( tokens );

     //console.log('command msg', msg );
     //  SHA256withRSA uses v1.5 padding;
     const sig     = new jsrsasign.Signature({"alg": "SHA256withRSAandMGF1"}); 
     const privkey = jsrsasign.KEYUTIL.getKey(priv_key_string);

     sig.init(privkey);
     sig.updateString(msg);

     return uint8ArrayToBase64( hexToUint8Array( sig.sign() ) ); 
  }

  function generate_msg_from_tokens ( tokens ) {
      return Object.keys(tokens).sort().map( (t) => { return tokens[t] } ).join(',');
  } 

  function generate_nonce () {
    const n = nonce(10);

    return n();
  } 

}


export default Signer;
