import { Injectable } from '@angular/core';
import { AccessApiService } from '../../../shared/services/access-api.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { SiteService } from './site.service';
import {sprintf} from "sprintf-js";
import { pipe, skipUntil, filter, of, map } from 'rxjs';
import { ApiCollection } from '../../../shared/model/api-coillection.model';
import { Host, HostStub } from '../models/host.model';
import { Site } from '../models/site.model';
import { HostLog } from '../models/host-log.model';
import { FormControl } from '@angular/forms';
import { ExcClientRequestCfg } from '../../../shared/services/access-api.service';
import { SiteUserGroup } from '../models/site-user-group.model';
import { SiteUser } from '../models/site-user.model';


@Injectable({
  providedIn: 'root'
})
export class UserGroupService {

  constructor(
    private accessApi: AccessApiService,
    private siteService: SiteService,
  ) { 
    //this.siteService.loaded.subscribe();
  }

  public getGroup( groupUriKey: string )  {
     
     let cfg = { searchArgs: { attributes: { uri_key: groupUriKey } },
                searchAttrs: { limit: 1 } 
               };

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getUserGroupsCollectionRequest(site, cfg) }),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }

  
  public getGroupMembers( group: SiteUserGroup, cfg?: ExcClientRequestCfg ) {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                     return this.getGroupMembersCollectionRequest(site, group, cfg) 
                  }),
                );

  }

  private getGroupMembersCollectionRequest( site: Site, group: SiteUserGroup, cfg?: ExcClientRequestCfg ) {

    const path = sprintf('/excollect_api/sites/%s/groups/%s/members', site.id, group.id );

    return this.accessApi.getCollection(SiteUser, path, cfg)
  }

  public getAvailableUsers( group: SiteUserGroup, cfg?: ExcClientRequestCfg ) {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                     return this.getAvailableUsersCollectionRequest(site, group, cfg) 
                  }),
                );

  }

  private getAvailableUsersCollectionRequest( site: Site, group: SiteUserGroup, cfg?: ExcClientRequestCfg ) {

    const path = sprintf('/excollect_api/sites/%s/groups/%s/available_users', site.id, group.id );

    return this.accessApi.getCollection( SiteUser, path, cfg )
  }

  public addGroupUsers( group: SiteUserGroup, newMembers: Array<string>, cfg?: ExcClientRequestCfg )  {

    let data = newMembers.map( (user_id) => {
                  return { id: user_id } 
               });

    return this.siteService
               .getActiveSite()
               .pipe( 
                 switchMap( (site) => { 

                   let path = sprintf('/excollect_api/sites/%s/groups/%s/members', 
                                       site.id, group.id );

                   return this.accessApi.updateCollection( SiteUser, path, data, cfg) 
                 }),
                 first()
                 //map( (collection) => { return collection.first() })
               );


  }

  public removeGroupUsers( group: SiteUserGroup, memberIds: Array<string> )  {

    //onst memberIds: Array<string> = members.map((member) => { return member.id })

    const cfg = {
       searchArgs: { id: { in: memberIds } }
    }

    return this.siteService
               .getActiveSite()
               .pipe( 
                 switchMap( (site) => { 

                   let path = sprintf('/excollect_api/sites/%s/groups/%s/members', 
                                       site.id, group.id );

                   return this.accessApi.delete( path, cfg) 
                 }),
                 first()
                 //map( (collection) => { return collection.first() })
               );



  }

  public getUserGroups( cfg?: ExcClientRequestCfg )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getUserGroupsCollectionRequest(site, cfg ) }),
                  first()
                );
  }

  private getUserGroupsCollectionRequest( site:  Site, cfg?: { [name: string]: any }) {
 
    const path = '/excollect_api/sites/%s/groups';

    return this.accessApi.getCollection(SiteUserGroup, sprintf(path, site.id), cfg)
  }

  public addGroup( group: SiteUserGroup )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.updateGroupsCollectionRequest(site, [ group.onCreateData() ]) } ),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }
  private updateGroupsCollectionRequest( site:  Site, data: Array<Object> ) {

      let path = sprintf('/excollect_api/sites/%s/groups', site.id);
    
      return this.accessApi
                 .updateCollection(SiteUserGroup, path, data )
  }


}
