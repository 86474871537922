<div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
  <div class="modal-dialog h-75 d-flex align-items-center">
    <div class="modal-content">
      <div class="modal-header text-bg-danger">
        <h5 class="modal-title" id="errorModalLabel">{{ errorHeader }}</h5>
        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         {{ errorMsg }}
      </div>
    </div>
  </div>
</div>