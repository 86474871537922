import { BaseApiObject } from "../../../shared/model/base-api-object";
import moment from "moment";

export class SiteLog extends BaseApiObject {

   public level!: string;
   public text!: string;
   public date_time!: string;
   public site!: string;

   get localDateTime() {
      return  moment(this.date_time).local().format('YYYY-MM-DD HH:mm:ss')
   } 

}