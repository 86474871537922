//import { BaseApiObject } from "./base-api-object";
import { AccountUser } from "../../account/models/account-user.model";
import { Account } from "../../account/models/account";

export class CurrentUser extends AccountUser {

    authenticated: boolean = false;
    private_key!: string;
    public_key!: string;
    currentAccount!: Account; 
    apiKey!: { id: string, private_key: string, public_key: string };

    //public id: null = null; 

    //public serialize(): string {
    //    return ''
    //} 
}


export class CurrentUserLoggedIn extends AccountUser {

    authenticated: boolean = true;
    private_key!: string;
    public_key!: string;
    currentAccount!: Account; 
    apiKey!: { id: string, private_key: string, public_key: string };
}
