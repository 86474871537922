import { BaseApiObject } from "../../../shared/model/base-api-object"; 
import { objectAttributes } from "../../../shared/interfaces/object-attributes";
import { SiteUser } from "./site-user.model";
import { Host } from "./host.model";
import { WithAttributes } from "../mixins/with-attributes.mixin";

export class Site extends WithAttributes(BaseApiObject) {

   public name!: string;
   public status!: string;
   public created!: string;
   public updated!: string;
   public notes!: string;
   public users!: Array<SiteUser>;
   public hosts!: Array<Object>;

   constructor(
      private  siteData?: Site 
   ) { 
      super(siteData);

      delete this.siteData;
      
      this.users = this.users?.map( user => new SiteUser(user) );
      this.hosts = this.hosts?.map( host => new Host(host) );

   }

    get uriKey(): string {
      return <string>this.getAttribute('uri_key');
    }

   public override get writable() { 
      return ['name', 'notes' ] 
   };

}
