import { BaseApiObject } from "./base-api-object";

export class CurrentUserState extends BaseApiObject {

    current_account!: number;

    //constructor( 
    //   opts: { current_account: number }
    //) { 
    //    super()

    //    this.current_account = opts.current_account;
    //}

}
