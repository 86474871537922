<div class="container">
  <div class="row justify-content-center">
    <div class="my-4">
      <ul class="nav nav-tabs mb-4 " id="HostDetailTabs" role="tablist">
          <li class="nav-item px-1">
            <a class="nav-link active text-dark bg-white" 
                id="system-tab" 
            >
            Add Client  
            </a>
          </li>
      </ul>
    </div>

    <form  [formGroup]="systemCreateForm">
      <div class="row">
         <div class="col-6">
           <h5 class="mb-0 ps-2">Client Settings</h5>
         </div>
         <div class="col-6">
         </div>
      </div>
      <div class="col-10 ms-5 py-4" > 
        <div class="mb-3 row">
          <label for="hostname" class="form-label fw-bold col-2">Name</label>
          <div class="col-sm-8 col-md-5">
              <input type="text" 
                   class="form-control" 
                   id="hostname" 
                   aria-describedby="hostname" 
                   formControlName="hostname">
          </div>
        </div>
      </div>
      <div class="row">
         <div class="col-5">
         </div>
         <div class="col-6">
            <button type="submit" 
                    class="btn btn-primary"
                    addDeBounce 
                    (deBounceClick)="createSystem()" 
            >
            Add Client 
            </button>
         </div>
      </div>
    </form>
  </div>
</div>
