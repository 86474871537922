<div *ngIf="(instance$ | async) as instance" class="row px-3" >
  <div class="col-10">
    <div class="row mt-4">
       <div class="col-md-8 col-sm-12">
         <div class="row ps-3">
           <div class="col-12">
             <h5 class="mb-0 mt-1">{{ instance.command_string }} </h5>
           </div>
         </div>

       </div>
       <div class="col-md-4 pb-3 col-sm-12">
          <button *ngIf="instance.status=='init'"
                  type="submit" 
                  class="btn btn-success me-3"
                  addDeBounce 
                  (deBounceClick)="startInstance()" 
          >
             <h5 class="my-auto">Start</h5>
          </button>
          <button *ngIf="instance.status=='queuing'"
                  type="submit" 
                  class="btn btn-info text-light me-3"
                  addDeBounce 
                  (deBounceClick)="pauseInstance()" 
          >
             <h5 class="my-auto">Pause</h5>
          </button>
          <button *ngIf="instance.status=='paused'"
                  type="submit" 
                  class="btn btn-success me-3"
                  addDeBounce 
                  (deBounceClick)="unpauseInstance()" 
          >
             <h5 class="my-auto">Unpause</h5>
          </button>

          <button *ngIf="instance.status=='init'"
                  type="submit" 
                  class="btn btn-danger"
                  addDeBounce 
                  (deBounceClick)="cancelInstance()" 
          >
            <h5 class="my-auto">Cancel</h5>
          </button>
          <button *ngIf="['paused','queuing'].includes(instance.status)"
                  type="submit" 
                  class="btn btn-primary"
                  addDeBounce 
                  (deBounceClick)="completeInstance()" 
          >
            <h5 class="my-auto">Complete</h5>
          </button>

       </div>
    </div>
    <hr>
    <form  [formGroup]="instanceConfigForm" 
           class="disabled"
    >
      <fieldset [disabled]="['complete', 'canceled'].includes(instance.status)">
        <div class="row mt-4">
           <div class="col-7">
             <h5 class="mb-0 mt-2 ">Settings</h5>
           </div>
           <div class="col-5">
              <button *ngIf="!['complete', 'canceled'].includes(instance.status)"
                      type="submit" 
                      class="btn btn-primary"
                      addDeBounce 
                      (deBounceClick)="updateInstanceConfig()" 
              >
                Update
              </button>
           </div>
        </div>
        <div class="col-md-12 ps-3 py-4" > 
          <div class="mb-3 row">
            <label for="name" class="form-label fw-bold col-md-2">Label</label>
            <div class="col-md-6">
                <input type="text" 
                     class="form-control text-end" 
                     id="label" 
                     aria-describedby="label" 
                     formControlName="label">
            </div>
          </div>

          <div class="mb-3 row">
            <label for="command" class="form-label fw-bold col-md-3">Priority</label>
            <div class="col-md-5">
                <div class="row">
                   <div class="col-md-6"></div>
                   <div class="col-md-6">
                      <input type="number" 
                           class="form-control text-end" 
                           id="command_priority" 
                           min="1"
                           max="99"
                           aria-describedby="priority" 
                           formControlName="command_priority">
                    </div>
                </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="command" class="form-label fw-bold col-md-3">Max Retry</label>
            <div class="col-md-5">
                <div class="row">
                   <div class="col-md-6"></div>
                   <div class="col-md-6">
                      <input type="number" 
                           class="form-control text-end" 
                           (change) ="onRetryChange"
                           id="max_retry" 
                           min="0"
                           max="10"
                           aria-describedby="max_retry" 
                           formControlName="max_retry">
                    </div>
                </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="command" 
                   class="form-label fw-bold col-md-3"
            >
                Retry Delay
            </label>
            <div class="col-md-5">
                <div class="row">
                   <div class="col-md-6"></div>
                   <div class="col-md-6">
                      <input type="number" 
                           class="form-control text-end" 
                           id="retry_delay" 
                           min="0"
                           max="3600"
                           [readonly]="retryDisabled"
                           [class.disabled]="retryDisabled"
                           aria-describedby="retry_delay" 
                           formControlName="retry_delay" >
                    </div>
                </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="command" class="form-label fw-bold col-md-3">Max Queuing</label>
            <div class="col-md-5">
                <div class="row">
                   <div class="col-md-6"></div>
                   <div class="col-md-6">
                      <input type="number" 
                           class="form-control text-end" 
                           id="max_executing" 
                           min="1"
                           aria-describedby="max_executing" 
                           formControlName="max_executing">
                    </div>
                </div>
            </div>
          </div>

        </div>
      </fieldset>
    </form>
    <hr>
  </div>
  <div class="col-10">
    <div class="row">
       <div class="col-12">
         <h5 class="mb-0 mt-2 ">Recent Activity</h5>
       </div>
    </div>
    <div class="row p-4">
      <table class="table table-hover">
          <thead class="table-dark">
            <tr>
              <th class="col-3">Date</th>
              <th class="col-1">Level</th>
              <th class="col-8">Text</th>
            </tr>   
          </thead>
          <tbody *ngIf="(logs$ | async) as logs" >
            <tr *ngFor="let log of logs.collection">
              <td>{{ log.localDateTime }}</td>
              <td>{{ log.level }}</td>
              <td>{{ log.text }}</td>
            </tr>   
          </tbody>
      </table>
    </div>
  </div>
</div>