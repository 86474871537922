import { Site } from "./site.model";
import { BaseApiObject } from "../../../shared/model/base-api-object";
import { WithAttributes } from "../mixins/with-attributes.mixin";

import { objectAttributes } from "../../../shared/interfaces/object-attributes"
export class SiteUser extends WithAttributes(BaseApiObject) {

    public status!: string;
    public account_user!: string;
    public site!: Site;
    public groups!: Array<Object>;
    //public attributes!: objectAttributes;

    constructor(
       private  userData: SiteUser 
    ) { 
       super(userData);

       this.site = new Site(this.site);
    }


}
