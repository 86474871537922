import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, filter, switchMap } from 'rxjs';
import { Command, CommandStub } from '../../models/command.model';
import { CommandService } from '../../services/command.service';
import { SiteService } from '../../services/site.service';

@Component({
  selector: 'app-command-config-tab',
  templateUrl: './command-config-tab.component.html',
  styleUrl: './command-config-tab.component.css'
})
export class CommandConfigTabComponent {

  public command$: BehaviorSubject<Command> = new BehaviorSubject<Command>(new CommandStub);  
  public formAction: string = 'Update';
  public initCfg!: { [key: string]: any }; 
  
//  {
//        name: '',
//        command_string: '',
//        description: ''
//  };
//

  constructor( 
    private CommandService: CommandService, 
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
  ) {}

  ngOnInit(){

    this.route.params
              .pipe(
                filter( params => params['command_id'] ),
                switchMap((params) => { 
                  return this.CommandService.getCurrentCommand(params['command_id'])  
                })
              )
              .subscribe( command => {
               
                this.initCfg = { 
                  name:           command.name, 
                  command_string: command.command_string, 
                  description:    command.description  
                };

                this.command$.next(command);
              });

  }

  updateCommand(commandCfg: { [ key:string ]: any }) {

     let currentCommand = this.CommandService.currentCommand;

     currentCommand.name           = commandCfg['name'];
     currentCommand.command_string = commandCfg['command_string'];
     currentCommand.description    = commandCfg['description'];

     this.CommandService
        .updateCurrentCommand(currentCommand)
        .subscribe({
          next: (command) => {

            this.initCfg = { 
              name:           command.name, 
              command_string: command.command_string, 
              description:    command.description  
            };

            this.command$.next(command)
          },
          error: (resp) => {
              console.log('error');
              console.log(resp)
          }
        });


  }


}
