import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessApiService } from '../shared/services/access-api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SiteErrorService } from '../shared/modules/rw-utils/modals/site-error/site-error.service';
import { LoadingModalService } from '../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { Validators } from '@angular/forms';
import { first, switchMap } from 'rxjs';
import { ValidatorFn } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { CurrentUserService } from '../shared/services/current-user.service';
import { matchValidator, getFormControlError, PasswordValidator } from '../utils/component-utils';

@Component({
  selector: 'app-account-activate',
  templateUrl: './account-activate.component.html',
  styleUrl: './account-activate.component.css'
})
export class AccountActivateComponent {

  ActivateForm: FormGroup;
  userEmail!: string;
  userCode!: string;
  submitted!: boolean;

  getFormControlError = getFormControlError;

  constructor( 
    private accessApi: AccessApiService, 
    private fb: FormBuilder, 
    private SiteError: SiteErrorService,
    private LoadingModal: LoadingModalService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private currentUser: CurrentUserService
  ) {

      this.ActivateForm = this.fb.group(
        {
          password: ['', [Validators.required, PasswordValidator.strong ] ],
          confirmPassword: ['', [Validators.required] ],
        }, 
        {
          validators: matchValidator('password', 'confirmPassword', 'Passwords do not match')
        }
      );
  }


  ngOnInit(){

    this.activeRoute.queryParams              
              .pipe(
                first()
              )
              .subscribe( params => {
                this.userEmail = params['email'];
                this.userCode  = params['code'];

                if ( this.userEmail === undefined || this.userCode === undefined ) {
                  this.router.navigate(['/signup']);
                }
                console.log(params) 
              });

  }



  get f(): FormGroup { return this.ActivateForm; }

  public doActivate() {

    if (this.f.invalid) {
      //console.log(this.f.errors);
      return;
    }

    this.LoadingModal.setLoading();

    let formVals   = this.f.value; 
    let data = {
      code:      this.userCode, 
      email:     this.userEmail,
      password:  formVals.password,
    };

    this.accessApi.post( '/account_api/users/activate_user', data ) 
                  .pipe(
                    first(),
                    switchMap( (resp) => {
                      console.log(resp)
                      return this.currentUser.login$( this.userEmail, formVals.password  )
                    })
                  ) 
                  .subscribe({
                    next: (resp) => {
                      this.LoadingModal.unsetLoading();
                      //console.log(resp)
                      // redirect to setup service page
                      this.router.navigate(['/service_setup']);

                    },
                    error: (error) => {
                      this.LoadingModal.unsetLoading();
                      this.SiteError.setApiError(error);
                    }
                  })

  }

}
