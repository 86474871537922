import { Component } from '@angular/core';
import { CurrentUserService } from '../shared/services/current-user.service';
import { SiteService } from '../service/excollect/services/site.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent {

  constructor( 
      private currentUser: CurrentUserService,
      private siteService: SiteService
  ) {

  }

  public url(): string {
     if (this.siteService.activeSiteUrl) {
       return  this.siteService.activeSiteUrl
     }
     else {
       return '/excollect'
     }
  }

  public loggedIn(): boolean {
      return this.currentUser.logged_in 
  }

}
