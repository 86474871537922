import moment from "moment"

export function localizeDateTime( iso_8601_dt?:string ) {
      if ( iso_8601_dt ) {
         return  moment(iso_8601_dt).local().format('YYYY-MM-DD HH:mm:ss')
      }
      else {
         return 'Not Set'
      }

}