import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  template: `
    <div class="container" style="height: 90vh">
      <div class="row">
         <div class="col-6 m-auto py-5 text-center">
          <h4>Not Found</h4>
         </div>
      </div>
    </div>


  `,
  styles: ``
})
export class NotFoundComponent {

}
