import { Component } from '@angular/core';
import { ExcollectNavbarComponent } from '../navbar/navbar.component'
import { SiteService } from '../../services/site.service';
import { Site } from '../../models/site.model';
import { ActivatedRoute, Router } from '@angular/router';
import {  combineLatestWith, map, first } from 'rxjs';


@Component({
  selector: 'app-excollect-site',
  templateUrl: './site.html',
  styleUrl: './site.css'
})
export class SiteComponent {

  constructor(
     private siteService: SiteService,
     private route: ActivatedRoute,
     private router: Router
  ) { 
     
  }

  ngOnInit(){

      //this.siteService.loadSiteUsers();

      this.route.params
                .pipe(
                //   combineLatestWith( this.siteService.isLoaded() ) ,
                //   map( ([params, loaded]) => params  ),
                   first()
                )
                .subscribe( params => { 
                    this.siteService.setActiveSiteUserByUri(params['site_id']);
                }); 
  }

}
