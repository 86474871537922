import { Component } from '@angular/core';

@Component({
  selector: 'app-management-container',
  templateUrl: './management-container.component.html',
  styleUrl: './management-container.component.css'
})
export class ManagementContainerComponent {

}
