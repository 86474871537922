import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Account } from '../../account/models/account'; 
import { AccountUser } from '../../account/models/account-user.model';
import { CurrentUser, CurrentUserLoggedIn } from '../model/current-user';
import { CurrentUserState } from '../model/current-user-state';
import { ApiMessage } from '../interfaces/api-responses';
import { AccessApiService } from './access-api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { first, tap, map, catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { CommandInstance } from '../../service/excollect/models/command-instance.model';
import { ExcClientResponse, ExcClientRequestCfg } from './access-api.service';
import { Tab } from 'bootstrap';


@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  state!: CurrentUserState;
  user!: CurrentUser | CurrentUserLoggedIn;
  currentAccount!: Account;

  logged_in: boolean = false;
  public user$: BehaviorSubject<CurrentUser | CurrentUserLoggedIn> = new BehaviorSubject<CurrentUser | CurrentUserLoggedIn>(new CurrentUser()); 

  constructor( 
    private storage: LocalStorageService,
    private accessApi: AccessApiService,
    private router: Router,
    private route: ActivatedRoute,

  ) { 
    this.init_from_storage(); 

  }

  public login$( email: string, password: string ): Observable<any> {

    return this.accessApi.login( email, password )
                         .pipe(
                            first(),
                            catchError( (error): Observable<ExcClientResponse> => { 
                              console.log('login failed'); 
                              
                              return throwError( () => error ) 
                            }), 

                            tap( response => {
                              this.init_from_login( <ApiMessage>response.data );
                              this.logged_in = true; 
                            })
                         );
               
  }

  public logout(): void {

    this.clear_user_data();
    this.router.navigate(['/login']);

  }

  private clear_user_data(): void {
    this.accessApi.logout();
    this.storage.clearData();

    this.logged_in = false; 
    this.user      = new CurrentUser(); 
    this.state     = new CurrentUserState();

  }

  public go_to_homepage(): void {
    this.router.navigate(['/excollect']);
  }

  //public signCommandInstnace( instance: CommandInstance ) {
  //    return {
  //      signer_user_id: this.user.id,
  //      signature:      'FAKESIG'
  //    }
  //}

  private init_from_login( resp: ApiMessage ) {

    //this.account = new Account().deserialize( resp.message.account );
    //console.log(resp.message.data);
    //this.user    = new CurrentUser().deserialize( resp.message.data );
    this.user    = Object.assign( new CurrentUserLoggedIn(), resp.message['data']);
    this.state   = Object.assign( new CurrentUserState(), { current_account: 0 } );

    this.currentAccount = this.user.accounts_roles[0].account;

    this.user$.next(this.user);
    this.update_storage();
  }

  private update_storage(): void {
    this.storage.saveData( 'current_user', this.user.serialize() );
    this.storage.saveData( 'user_state', this.state.serialize() );

    if ( this.accessApi.logged_in ) {
       this.storage.saveData( 'private_key', this.accessApi.private_key );
    }
  }

  private init_from_storage(): void {
    
    const user_raw    = this.storage.getData('current_user');
    const state       = this.storage.getData('user_state');
    const private_key = this.storage.getData('private_key')

    //console.log('here');
    if ( user_raw && private_key ) {
      this.user  = new CurrentUserLoggedIn().deserialize(user_raw);
      this.state = new CurrentUserState().deserialize(state);
    //console.log(this.user);

      //console.log(this.user);
      this.currentAccount = this.user.accounts_roles[0].account;

      //if ( "id" in this.user ) {
      this.accessApi.set_auth_data( this.user.id, private_key );
      //TODO run some authenticated ping request here.
      this.logged_in = true; 
      //}
      this.user$.next(this.user);

    }
    else { 
      this.clear_user_data();
    }

  }

  public getUser()  {

     return this.getUserRolesCollectionRequest()
                .pipe(
                  map( (collection) => { return collection.first() })
                );
  }

  private getUserRolesCollectionRequest( cfg?: ExcClientRequestCfg ) {

      return this.accessApi
                 .getCollection(AccountUser, '/account_api/users', cfg );
  }

  public updateUser( accountUser: AccountUser ) {
    
      return this.accessApi
                 .updateCollection(CurrentUserLoggedIn, '/account_api/users', [ accountUser.updateData() ] )
                 .pipe(
                    map( (collection) => { return collection.first() }),
                    tap( (user) => {

                       // Only login request returns API key data
                       // so we have to preserve it.
                       const apiKey = this.user.apiKey;
                       this.user = user;
                       this.user.apiKey = apiKey;
                       this.update_storage();

                    })
                 )
  }

  public changePassword( oldPassword: string, newPassword: string, cfg?: ExcClientRequestCfg ) {

       const passData = {
         old_password: oldPassword, 
         new_password: newPassword
       };

      return this.accessApi
                 .post( '/account_api/users/change_password', passData, cfg )
                 .pipe(
                    tap( (resp) => {
                       this.init_from_login(<ApiMessage>resp.data)
                       //console.log('updated user data')
                    })
                 )

  }
}
