import { BaseApiObject } from "../../../shared/model/base-api-object";
import { Host } from "./host.model"; 
export class CommandHost extends BaseApiObject {
    public host!: Host;
    public priority!: number;

    public override get onCreate() { 
       return ['host', 'priority' ] 
    };

    public override get writable() { 
       return [ 'priority' ] 
    };




}
