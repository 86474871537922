import { Component } from '@angular/core';
import { Subject, BehaviorSubject, filter, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SiteErrorService } from '../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { LoadingModalService } from '../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { AccountUsersService } from '../../services/account-users.service';
import { AccountUser } from '../../models/account-user.model';
import { UserRole } from '../../models/user-role.model';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';


@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrl: './user-settings.component.css'
})
export class UserSettingsComponent {

  public user$: Subject<UserRole> = new Subject<UserRole>(); // = new BehaviorSubject<UserRole>(new HostStub);  

  public userSettingsForm: FormGroup  = new FormBuilder().group({
                                              first_name: ['', [Validators.required ] ],
                                              last_name: ['', [Validators.required ]],
                                              email_addr: ['', [Validators.required ]],
                                            });


  constructor( 
    private route: ActivatedRoute, 
    private SiteError: SiteErrorService,
    private LoadingModal: LoadingModalService,
    private currentUser: CurrentUserService,
    private UserService: AccountUsersService
  ) {

  }

  ngOnInit(){

          this.route
              .params
              .pipe(
                filter( params => params['user_id'] ),
                switchMap((params) => { 
                  return this.UserService.getUserRole(params['user_id'])  
                })
              )
              .subscribe( user => {
                 this.userSettingsForm.setValue({ 
                                         first_name: user.user.first_name, 
                                         last_name:  user.user.last_name, 
                                         email_addr: user.user.email_addr,
                                       });


                 this.user$.next(user)
                 console.log(user)
              })

  }

  get uf(): FormGroup { return this.userSettingsForm; }

  updateUserSettings() {

    if (this.uf.invalid) {

      // TODO generalize into some error reporting service
      const result: any[] = [];
      Object.keys(this.uf.controls).forEach(key => {
    
        const controlErrors = this.uf.get(key)?.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            result.push({
              'control': key,
              'error': keyError,
              'value': controlErrors[keyError]
            });
          });
        }
      });

      return;
    }

    const formVals    = this.uf.value; 
    const userData = {   
       first_name: formVals.first_name, 
       last_name:  formVals.last_name, 
       email_addr: formVals.email_addr,
    };
    const updatedUser = Object.assign( new AccountUser(), userData) ; 



  }


}
