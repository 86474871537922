import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, switchMap, debounceTime } from 'rxjs';
import { SiteService } from '../../services/site.service';
import { CommandService } from '../../services/command.service';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { CommandInstance } from '../../models/command-instance.model';
import { CommandInstanceService } from '../../services/command-instance.service';


@Component({
  selector: 'app-instances-list',
  templateUrl: './instances-list.component.html',
  styleUrl: './instances-list.component.css'
})
export class InstancesListComponent {

  public commandInstances$!: BehaviorSubject<ApiCollection<CommandInstance>>

  constructor( 
    private CommandInstanceService: CommandInstanceService, 
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
  ) {}

  ngOnInit() {
    this.CommandInstanceService  
        .getInstances()
        .subscribe( (commandInstances) => {
        
          this.commandInstances$ = new BehaviorSubject<ApiCollection<CommandInstance>>(commandInstances);  
 
        });

  }

  loadCommandInstanceDetail(commandInstance: CommandInstance) {
    this.router.navigate([ commandInstance.uriKey ], { relativeTo: this.route });
  }

  loadPage( page: number ) {
      let hosts = this.commandInstances$.value;

      let cfg = { searchAttrs: { limit: hosts.searchAttrs.limit, page: page } };

      this.CommandInstanceService.getInstances(cfg).subscribe( (instanceCollection) => {
          this.commandInstances$.next(instanceCollection);  
      });


  }


}
