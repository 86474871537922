<app-loading></app-loading>
<app-site-error></app-site-error>
<div class="container-fluid">
  <div class="row" style="min-height: 90vh;">
    <div class="p-0 col-md-2" >
       <app-management-nav></app-management-nav>
    </div>
    <div class="p-0 col-md-10" >

      <router-outlet></router-outlet>
    </div>
  </div>
</div>
