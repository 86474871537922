<div class="p-3">
<form  [formGroup]="commandConfigForm" (ngSubmit)="updateConfig()">
  <div class="row mt-4 mb-4">
     <div class="col-8">
       <h5 class="mb-0 mt-2">Settings</h5>
     </div>
     <div class="col-4">
        <button type="submit" class="btn btn-primary">{{ formAction }}</button>
     </div>
  </div>
  <div class="col-10 ms-5 pt-4" > 
    <div class="mb-3 row">
      <label for="name" class="form-label fw-bold col-2">Name</label>
      <div class="col-4">
          <input type="text" 
               class="form-control" 
               id="name" 
               aria-describedby="name" 
               formControlName="name">
      </div>
    </div>
    <div class="mb-3 row">
      <label for="description" class="form-label fw-bold col-2">Description</label>
      <div class="col-4">
          <input type="text" 
               class="form-control" 
               id="description" 
               aria-describedby="description" 
               formControlName="description">
      </div>
    </div>
    <div class="mb-3 row">
      <label for="command" class="form-label fw-bold col-2">Command</label>
      <div class="col-8">
          <input type="text" 
               class="form-control" 
               id="command" 
               aria-describedby="command" 
               formControlName="command_string">
      </div>
    </div>

  </div>
</form>
</div>
