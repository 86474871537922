import { Injectable } from '@angular/core';
import { AccessApiService } from '../../shared/services/access-api.service';
import { ExcClientRequestCfg } from '../../shared/services/access-api.service';
import { ServiceInstance } from '../models/service-instance.model';
import { AccountUser } from '../models/account-user.model';
import { Account } from '../models/account';
import { sprintf } from 'sprintf-js';
import { CurrentUserService } from '../../shared/services/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceInstanceService {

  constructor(
    private accessApi: AccessApiService,
    private currentUser: CurrentUserService

  ) { }

  public getServiceInstances( cfg?: ExcClientRequestCfg )  {

     return  this.getServiceInstancesCollectionRequest( this.currentUser.currentAccount, cfg ) 
  }

  private getServiceInstancesCollectionRequest( account:  Account,  cfg?: ExcClientRequestCfg ) {

      let path = sprintf('/account_api/accounts/%s/service_instances', account.id );

      return this.accessApi
                 .getCollection(ServiceInstance, path ,cfg )
  }

  public getPendingSetup(  cfg?: ExcClientRequestCfg ) {

      let account = this.currentUser.currentAccount;
      let path    = sprintf('/account_api/accounts/%s/service_instances/pending_setup', account.id );

      return this.accessApi
                 .getCollection(ServiceInstance, path ,cfg )
  }

  public setupService( serviceInstance: ServiceInstance, setupData: Object, cfg?: ExcClientRequestCfg ) {

      let account = this.currentUser.currentAccount;
      let path    = sprintf('/account_api/accounts/%s/service_instances/%s/setup', 
                             account.id, serviceInstance.id );

      return this.accessApi
                 .post( path, setupData, cfg )
  }


}
