import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ApiMessage } from '../shared/interfaces/api-responses';
import { SiteErrorService } from '../shared/modules/rw-utils/modals/site-error/site-error.service';
import { LoadingModalService } from '../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { AccessApiService } from '../shared/services/access-api.service';
import { getFormControlError } from '../utils/component-utils';
import { CurrentUserService } from '../shared/services/current-user.service';

type StatusData = { open_registration: number };

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent {

  public SignUpForm: FormGroup;
  public registered: Boolean = false 
  public submitted: Boolean = false;
  public getFormControlError = getFormControlError;

  public status$!: BehaviorSubject<StatusData>


  constructor( 
    private accessApi: AccessApiService, 
    private fb: FormBuilder, 
    private SiteError: SiteErrorService,
    private LoadingModal: LoadingModalService,
    private currentUser: CurrentUserService,
    private router: Router,

  ) {

      this.SignUpForm = this.fb.group({
        newEmail: ['', [Validators.required, Validators.email] ],
        newAccName: ['', [Validators.required] ],
        //newFName: ['', [Validators.required] ],
        //newLName: ['', [Validators.required] ],

      });

  }

  ngOnInit(){
      
       this.currentUser.accountAPIStatus()
                       .subscribe({
                           next: (resp) => {
                              console.log(resp)
                              this.status$ = new BehaviorSubject( resp.data.message)
                           },
                           error: (error) => {
                             
                             const status: StatusData = { open_registration: 0 };
                             this.status$ = new BehaviorSubject(status)

                           }

                       }) 
                      
  }


  get f(): FormGroup { return this.SignUpForm; }

  public doSignUp() {
    //console.log(this.f.value);
    this.submitted = true;


    if (this.f.invalid) {
      console.log(this.f.errors);
      return;
    }

    this.LoadingModal.setLoading();

    let formVals   = this.f.value; 
    let data = {
      email:        formVals.newEmail, 
      name:         formVals.newAccName, 
      first_name:   '', //formVals.newFName, 
      last_name:    '', //formVals.newLName, 
      init_service: environment.default_service  
    };

    this.accessApi.post( '/account_api/users/create_account', data ) 
                  .pipe(
                    first()
                  ) 
                  .subscribe({
                    next: (resp) => {
                      this.LoadingModal.unsetLoading();
                      console.log(resp)
                      // change to check email for activation link
                      this.registered = true;
                    },
                    error: (error) => {
                      this.LoadingModal.unsetLoading();
                      this.SiteError.setApiError(error);
                    }
                  })
  }



}
