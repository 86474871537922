import { Component } from '@angular/core';

@Component({
  selector: 'app-account-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.css'
})
export class SideNavComponent {

}
