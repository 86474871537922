import { Injectable } from '@angular/core';
import { AxiosResponse, AxiosPromise, AxiosRequestConfig, AxiosError } from 'axios';
import { Observable, of, throwError, timer } from 'rxjs';
import ExCollectClient from "excollect-client";
import { environment } from '../../../environments/environment';
import { first, tap, catchError, switchMap } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { ApiCollection } from '../model/api-coillection.model';
import { sprintf } from 'sprintf-js';
import { CommandInstance } from '../../service/excollect/models/command-instance.model';
import { SiteUser } from '../../service/excollect/models/site-user.model';
import { ApiCollectionResponse } from '../interfaces/api-responses';


export type ExcClient           = typeof ExCollectClient;
export type ExcClientResponse   = AxiosResponse;
export type ExcClientObservable = Observable<ExcClientResponse>;
export type AxiosRetryRequestConfig = AxiosRequestConfig & { 'axios-retry'?: {} };
export type ExcClientRequestCfg     = { [name: string]: any };

@Injectable({
  providedIn: 'root'
})
export class AccessApiService {

  public readonly client: ExcClient;

  constructor(
    private loggerService: LoggerService
  ) { 
     this.client = new ExCollectClient({ api_url: environment.access_api_url });
  }

  public get logged_in() {
    return this.client.logged_in();
  }

  public get private_key() {
    return this.client.private_key;
  }

  public set_auth_data( key_id: string, private_key: string ): void {
    this.client.key_id      = key_id;
    this.client.private_key = private_key;
  }

  public login( email: string, password: string, config?: AxiosRequestConfig ): ExcClientObservable  {
 
    if ( this.logged_in ) {
        return new Observable( (subscriber: any) => {
          subscriber.next({});
          subscriber.complete();
        });
    }

    return this.createObservable( this.client.login.bind(this.client), email, password, config );
  }

  public logout(): void {
    this.client.log_out();
  }

  public getCollection<T>( type: new (...args: any[]) => T, path: string, cfg?: { [name: string]: any }) {

      //console.log(cfg);
      return this.get(path, cfg )
                 .pipe( 
                    switchMap(  resp => {
                       return of( new ApiCollection( type, resp ));  
                    }),
                    first(),
                 )
  }

  public updateCollection<T>( type: new (...args: any[]) => T, path: string, data: Array<Object>, cfg?: { [name: string]: any }) {
      return this.post(path, data, cfg )
                 .pipe( 
                    switchMap(  resp => {
                       return of( new ApiCollection( type, resp ));  
                    }),
                    first(),
                 )
  }

  public updateObject<T>( type: new (...args: any[]) => T, path: string, data: Object, cfg?: ExcClientRequestCfg ) {
      return this.patch(path, data, cfg )
                 .pipe( 
                    switchMap(  resp => {
                       const resp_data = resp.data as ApiCollectionResponse<T>;
                       const message   = resp_data.message;

                       return of( new type( message ));  
                    }),
                    first(),
                 )
  }

  public signCommandInstance( commandInstance: CommandInstance, user: SiteUser ): string {

       const instanceCfg = {
              id:               commandInstance.id,
              command:          commandInstance.command.id,
              site:             user.site.id,
              signed_by:        user.id,
              created_datetime: commandInstance.created_datetime,
              command_string:   commandInstance.command_string,
              execute_type:     commandInstance.executeType,
              script_src:       commandInstance.scriptSrc,
       }

      console.log(instanceCfg);

       return this.client.signCommandInstance(instanceCfg)
  }

  public ping<T>(): ExcClientObservable {
    return this.get('/ping');
  }

  public get<T>( url: string , config?: { [name: string]: any }): ExcClientObservable {
     return this.createObservable(this.client.get.bind(this.client), url, config );
  }

  public post<T>( url: string , data?: any, config?: AxiosRequestConfig ): ExcClientObservable {
     console.log('Post Data', data);
     return this.createObservable(this.client.post.bind(this.client), url, data, config );
  }

  public put<T>( url: string , data?: any, config?: AxiosRequestConfig ): ExcClientObservable {
     console.log('Put Data', data);
     return this.createObservable(this.client.put.bind(this.client), url, data, config );
  }

  public patch<T>( url: string , data?: any, config?: AxiosRequestConfig ): ExcClientObservable {
     console.log('Patch Data', data);
     return this.createObservable(this.client.patch.bind(this.client), url, data, config );
  }

  public delete<T>( url: string , config?: { [name: string]: any }): ExcClientObservable {
     return this.createObservable(this.client.delete.bind(this.client), url, config );
  }

  private createObservable<T>( promiseFactory: (...promise_args: any[]) => AxiosPromise<T>, ...args: any[]  ): ExcClientObservable {

    let config = new ApiCollectionSearchCfg(args[args.length - 1]).axiosCfg();
  
    //this.loggerService.debug('Req Cfg', config.params); 
    //console.log(config.params)
    //console.log(args[args.length - 1])
    //let config: AxiosRetryRequestConfig = args[args.length - 1];
    //config = config ? {...config} : {};
    args[args.length - 1] = config;

    const controller = new AbortController();
    config.signal = controller.signal;
    config.timeout = 5000;
    config['axios-retry'] = {
      retries: 3,
      retryDelay: () => { return 1000 },
      //shouldResetTimeout: false
    }

    // start using observable$ style 
    const observable: ExcClientObservable = new Observable((subscriber: any) => {
      
      promiseFactory(...args)
        .then( response => {
          subscriber.next(response);
          subscriber.complete();
        })

        // AxiosError 
        .catch( error => subscriber.error(error));
    });

    const _subscribe = observable.subscribe.bind(observable);

    // Wrap around sub/unsub functions to send abort signal to requestg
    // if unsubscribe is called.
    observable.subscribe = (...args2: any[]) => {

      const subscription = _subscribe(...args2);
      const _unsubscribe = subscription.unsubscribe.bind(subscription);
  
      subscription.unsubscribe = () => {
        controller.abort()
        _unsubscribe();
      };

      return subscription;
    };


    let service = this;

    return observable.pipe(
                        catchError( (error): Observable<ExcClientResponse> => { 
                          service.loggerService.error(
                             'API Reuqest error: ', error,
                             'API Request Cfg', config.params
                          );
                          
                          return throwError( () => error ) 
                        }), 
                        first(),
                        tap( resp => {
                          // careful not to access resp.request here. violates 'strict mode';
                          service.loggerService.debug(
                                  'API Request: ' + resp.config.url,
                                  'API Request Cfg', config.params,
                                  'API Response', { status: resp.status, data: resp.data } );
                        }),
                      )
  }

}

export class ApiCollectionSearchCfg {

  public searchArgs!: Object | undefined;
  public searchAttrs!: Object | undefined;
  public filter!: Object | undefined;

  constructor(
    options: { searchArgs?: Object, searchAttrs?: Object, filter?: Object } = {}
  ) { 
    this.searchArgs  = options['searchArgs'];
    this.searchAttrs = options['searchAttrs'];
    this.filter      = options['filter'];
  }

  axiosCfg(): AxiosRetryRequestConfig { 
  
    let cfg: AxiosRetryRequestConfig = { params: {} };

    if ( this.searchArgs ) {
        cfg['params']['search_args'] = JSON.stringify(this.searchArgs);
    }
    if ( this.searchAttrs ) {
        cfg['params']['search_attrs'] = JSON.stringify(this.searchAttrs);
    }
    if ( this.filter ) {
        cfg['params']['filter'] = JSON.stringify(this.filter);
    }



    return cfg;
  }
}
