import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Account } from '../../account/models/account'; 
import { AccountUser } from '../../account/models/account-user.model';
import { CurrentUser, CurrentUserLoggedIn } from '../model/current-user';
import { CurrentUserState } from '../model/current-user-state';
import { ApiMessage } from '../interfaces/api-responses';
import { AccessApiService } from './access-api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { first, tap, map, catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject, throwError, switchMap, of } from 'rxjs';
import { CommandInstance } from '../../service/excollect/models/command-instance.model';
import { ExcClientResponse, ExcClientRequestCfg } from './access-api.service';
import { Tab } from 'bootstrap';
import { UserRole } from '../../account/models/user-role.model';
import { AccountRole } from '../../account/models/account-role.model';


@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  state!: CurrentUserState;
  user!: CurrentUser | CurrentUserLoggedIn;
  currentAccount!: Account;
  currentAccountRole!: AccountRole;

  logged_in: boolean = false;
  public user$: BehaviorSubject<CurrentUser | CurrentUserLoggedIn> = new BehaviorSubject<CurrentUser | CurrentUserLoggedIn>(new CurrentUser()); 

  constructor( 
    private storage: LocalStorageService,
    private accessApi: AccessApiService,
    private router: Router,
    private route: ActivatedRoute,

  ) { 
    this.init_from_storage(); 

  }

  public login$( email: string, password: string ) {

    return this.accessApi.login( email, password )
                         .pipe(
                            first(),
                            catchError( (error): Observable<ExcClientResponse> => { 
                              console.log('login failed'); 
                              
                              return throwError( () => error ) 
                            }), 
                            switchMap( (response) => {
                              return of(this.init_from_login( <ApiMessage>response.data ));
                            })
                            //tap( response => {
                            //  this.init_from_login( <ApiMessage>response.data );
                            //})
                         );
               
  }

  public logout(): void {

    this.clear_user_data();
    this.router.navigate(['/login']);

  }

  private clear_user_data(): void {
    this.accessApi.logout();
    this.storage.clearData();

    this.logged_in = false; 
    this.user      = new CurrentUser(); 
    this.state     = new CurrentUserState();
    this.currentAccount     = new Account();
    this.currentAccountRole = new AccountRole();

  }

  public go_to_homepage(user : CurrentUser): void {

    console.log(user.accounts_roles);
    if ( typeof user.accounts_roles[0] !== 'undefined' ) {
        this.router.navigate(['/excollect']);
    }
    else {
        this.router.navigate(['/manage/user/settings']);
    }
  }

  //public signCommandInstnace( instance: CommandInstance ) {
  //    return {
  //      signer_user_id: this.user.id,
  //      signature:      'FAKESIG'
  //    }
  //}

  private init_from_login( resp: ApiMessage ) {

    this.user  = Object.assign( new CurrentUserLoggedIn(), resp.message['data']);
    this.state = Object.assign( new CurrentUserState(), { current_account: 0 } );

    // verify account_role  here

    if (  typeof this.user.accounts_roles[0] !== 'undefined' ) {
        this.currentAccount     = this.user.accounts_roles[0].account;
        this.currentAccountRole = this.user.accounts_roles[0];
    }

    this.logged_in = true; 

    this.user$.next(this.user);
    this.update_storage();

    return this.user
  }

  private update_storage(): void {
    this.storage.saveData( 'current_user', this.user.serialize() );
    this.storage.saveData( 'user_state', this.state.serialize() );

    if ( this.accessApi.logged_in ) {
       this.storage.saveData( 'private_key', this.accessApi.private_key );
    }
  }

  private init_from_storage(): void {
    
    const user_raw    = this.storage.getData('current_user');
    const state       = this.storage.getData('user_state');
    const private_key = this.storage.getData('private_key')

    if ( user_raw && private_key ) {
      this.user  = new CurrentUserLoggedIn().deserialize(user_raw);
      this.state = new CurrentUserState().deserialize(state);

      if (  typeof this.user.accounts_roles[0] !== 'undefined' ) {
          this.currentAccount     = this.user.accounts_roles[0].account;
          this.currentAccountRole = this.user.accounts_roles[0];
      }

      this.accessApi.set_auth_data( this.user.id, private_key );
      //TODO run some authenticated ping request here.
      this.logged_in = true; 
      
      this.user$.next(this.user);

    }
    else { 
      this.clear_user_data();
    }

  }

  public get isAdmin (): boolean {
 
    const role = this.currentAccountRole.role;

    if (!role) {
      return false
    }
    else if (role.name === 'Owner') {
      return true
    }
    else if (role.name === 'Administrator') {
      return true
    }

    return false
  }

  public get isOwner (): boolean {
 
    const role = this.currentAccountRole.role;

    if (!role) {
      return false
    }
    else if (role.name === 'Owner') {
      return true
    }

    return false
  }



  public getUser()  {

     return this.getUserRolesCollectionRequest()
                .pipe(
                  map( (collection) => { return collection.first() })
                );
  }

  private getUserRolesCollectionRequest( cfg?: ExcClientRequestCfg ) {

      return this.accessApi
                 .getCollection(AccountUser, '/account_api/users', cfg );
  }

  public updateUser( accountUser: AccountUser ) {
    
      return this.accessApi
                 .updateCollection(CurrentUserLoggedIn, '/account_api/users', [ accountUser.updateData() ] )
                 .pipe(
                    map( (collection) => { return collection.first() }),
                    tap( (user) => {

                       // Only the login request returns API key data
                       // so we have to manually copy it back into 
                       // our newly returned user object.
                       const apiKey = this.user.apiKey;
                       this.user = user;
                       this.user.apiKey = apiKey;
                       this.update_storage();

                    })
                 )
  }

  public changePassword( oldPassword: string, newPassword: string, cfg?: ExcClientRequestCfg ) {

      const passData = {
        old_password: oldPassword, 
        new_password: newPassword
      };

      return this.accessApi
                 .post( '/account_api/users/change_password', passData, cfg )
                 .pipe(
                    tap( (resp) => {
                       this.init_from_login(<ApiMessage>resp.data)
                    })
                 )

  }

  // get API status
  public accountAPIStatus() {

      return this.accessApi
                 .get( '/account_api/status' )
                 .pipe(
                   first()
                 )

  }


}
