import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Command } from '../../models/command.model';

@Component({
  selector: 'app-command-config',
  templateUrl: './command-config.component.html',
  styleUrl: './command-config.component.css'
})
export class CommandConfigComponent {

  @Input() formAction = 'Update';
  @Input() initCfg    = {};
  //      name: '',
  //      command_string: '',
  //      description: ''
  //};

  @Output() newCommandEvent = new EventEmitter<{ [key: string]: any }>();

  public commandConfigForm!: FormGroup; 
   

  constructor() {

       this.commandConfigForm = new FormBuilder().group({
                                      name: [ '', [ Validators.required, ] ], 
                                      command_string: [ '', [ Validators.required, ] ], 
                                      description: [ '', [ Validators.required, ] ],
                                     // max_retries: [ '', [ Validators.required, ] ], 
                                    });
  }

  ngOnInit(){
     console.log('init here');
     this.commandConfigForm.setValue(this.initCfg);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['initCfg']) {
       this.commandConfigForm.setValue(changes['initCfg'].currentValue);
 
    }
 
  }

  get cf(): FormGroup { return this.commandConfigForm; }

  updateConfig() {


    if (this.cf.invalid) {

      console.log(this.cf.controls);
      
      return;
    }

    let formVals = this.cf.value; 

    let commandConfig = {
         type: 'job',
         name: formVals.name, 
         command_string: formVals.command_string, 
         description: formVals.description, 
    }

    this.newCommandEvent.emit(commandConfig);

  }


}
