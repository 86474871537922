import { ApiCollectionResponse } from "../interfaces/api-responses";
import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { ExcClientResponse } from "../services/access-api.service";
import { Observable, of } from "rxjs";
import { ApiCollectionMessage } from "../interfaces/api-responses";
import { IApiCollectionSearchAttrs } from "../interfaces/api-responses";

export class ApiCollection<T> {

    public collection: Array<T>;
    public location: string;
    public current_page: number;
    public last_page: number;
    public searchArgs: Object;
    public searchAttrs: IApiCollectionSearchAttrs;
    public total: number;
    
    public collection$!: Observable<Array<T>>

    constructor(
       objectClass:  new (...args: any[]) => T, 
       resp:  ExcClientResponse, 
    ) { 

       let resp_data = resp.data as ApiCollectionResponse<T>;
       let message   = resp_data.message;

       this.location     = message.location;
       this.current_page = message.current_page;
       this.last_page    = message.last_page;
       this.searchArgs   = message.search_args;
       this.searchAttrs = message.search_attrs;
       this.total        = message.total;

       this.collection = this.init_objects( objectClass, resp );

       this.collection$ = of(this.collection); 
    }

    first(): T  {
      return this.collection[0];
    }

    map( func: Function ): Array<any> {
        return this.collection.map( object => func(object) );
    }

    protected init_objects( objectClass: new (...args: any[]) => T, resp:  ExcClientResponse ) {
       // Give objects a shallow copy of the collection so they know what searchArgs/searchAttrs
       // they were fetched with. 
       let  stubCollection: ApiCollection<T> = new ApiCollectionShallow<T>( objectClass, resp);

       return stubCollection.collection.map( object => new objectClass({...object, collection: stubCollection }) );

    }
}

export class ApiCollectionShallow<T> extends ApiCollection<T> {

     protected override init_objects( objectClass: new (...args: any[]) => T,  resp:  ExcClientResponse ) {

       let resp_data = resp.data as ApiCollectionResponse<T>;
       let message   = resp_data.message;

       return message.collection.map( object => new objectClass({...object }) );

    }
}

export class ApiCollectionStub<T> extends ApiCollection<T>{

    public override collection: Array<T> = [];
    public override location: string     = '';
    public override current_page: number = 0;
    public override last_page: number    = 0;
    public override searchArgs: Object   = {};
    public override searchAttrs: IApiCollectionSearchAttrs  = {  limit: 1, page: 0, order_by: {} };
    public override total: number        = 0;
    

}