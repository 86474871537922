<div class="container">
  <div class="row justify-content-center">
    <div class="my-4">
      <ul class="nav nav-tabs mb-4 " id="HostDetailTabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active text-dark bg-white" 
                id="system-tab" 
            >
            Add Command  
            </a>
          </li>
      </ul>
    </div>
    <app-command-config [formAction]="formAction" (newCommandEvent)="createCommand($event)"></app-command-config>

  </div>
</div>

