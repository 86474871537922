import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, switchMap, last, debounceTime } from 'rxjs';
import { SiteService } from '../../services/site.service';
import { CommandService } from '../../services/command.service';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { CommandInstance } from '../../models/command-instance.model';

@Component({
  selector: 'app-command-instances-tab-search',
  templateUrl: './command-instances-tab-search.component.html',
  styleUrl: './command-instances-tab-search.component.css'
})
export class CommandInstancesTabSearchComponent {

  public commandInstances$!: BehaviorSubject<ApiCollection<CommandInstance>>

  constructor( 
    private CommandService: CommandService, 
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
  ) {}

  ngOnInit() {
      
    this.route
        .params
        .pipe(
          filter( params => params['command_id'] ),
          switchMap((params) => { 
            return this.CommandService.getCurrentCommand(params['command_id'])  
          }),
          switchMap((command) => { 
              //let cfg = { searchAttrs: { limit: 1, page: 1 } };

              return this.CommandService.getInstances(command)
          }), 
        )
        .subscribe( (commandInstances) => {
        
          this.commandInstances$ = new BehaviorSubject<ApiCollection<CommandInstance>>(commandInstances);  
 
        });

  }

  loadCommandInstanceDetail(commandInstance: CommandInstance) {
    this.router.navigate([ '../../../../instances/'+ commandInstance.uriKey ], { relativeTo: this.route });
  }
 
  loadPage( page: number ) {
      let instances = this.commandInstances$.value;

      let cfg = { searchAttrs: { limit: instances.searchAttrs.limit, page: page } };

      this.CommandService.currentCommand$
                         .pipe(
                           switchMap(command => this.CommandService.getInstances( command, cfg ) ),
                         )
                         .subscribe( (instanceCollection) => {
                             console.log('here ff')
                             this.commandInstances$.next(instanceCollection);  
                         });


  }

}
