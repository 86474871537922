import { Injectable } from '@angular/core';
import { AccessApiService } from '../../../shared/services/access-api.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { SiteService } from './site.service';
import {sprintf} from "sprintf-js";
import { pipe, skipUntil, filter, of, map } from 'rxjs';
import { ApiCollection } from '../../../shared/model/api-coillection.model';
import { Host, HostStub } from '../models/host.model';
import { Site } from '../models/site.model';
import { HostLog } from '../models/host-log.model';
import { FormControl } from '@angular/forms';
import { ExcClientRequestCfg } from '../../../shared/services/access-api.service';


@Injectable({
  providedIn: 'root'
})
export class HostService {

  currentHost: Host = new HostStub();

  constructor(
    private accessApi: AccessApiService,
    private siteService: SiteService,
  ) { 
    //this.siteService.loaded.subscribe();
  }

  public getHosts( cfg?: ExcClientRequestCfg )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getHostCollectionRequest(site, cfg ) })
                );
  }


  public getCurrentHost( hostUriKey: string )  {
     if ( this.currentHost.uriKey === hostUriKey ) {
        return of(this.currentHost);
     }
     
     return this.getHost(hostUriKey).pipe( tap((host) => { this.currentHost = host }) );
  }


  public getHost( hostUriKey: string )  {
     
     let cfg = { searchArgs: { attributes: { uri_key: hostUriKey } } };

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getHostCollectionRequest(site, cfg) }),
                  map( (collection) => { return collection.first() })
                );
  }



  private getHostCollectionRequest( site:  Site, cfg?: { [name: string]: any }) {

      return this.accessApi.getCollection(Host, sprintf('/excollect_api/sites/%s/hosts', site.id), cfg)
  }

  public getHostLogs(host: Host, cfg?: { [name: string]: any } ) {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 

                    let path = sprintf('/excollect_api/sites/%s/hosts/%s/logs', site.id, host.id);

                    return this.accessApi.getCollection(HostLog, path, cfg)
                    //return this.getHostLogsCollectionRequest(site, host) 
                  })
                );
  }


  //private getHostLogsCollectionRequest( site:  Site, host: Host, cfg?: { [name: string]: any }) {

  //    let path = sprintf('/excollect_api/sites/%s/hosts/%s/logs', site.id, host.id);

  //    return this.accessApi.getCollection(Host, path, cfg)
  //}


  //private getCollectionRequest<T>( type: new (...args: any[]) => T, path: string, cfg?: { [name: string]: any }) {

  //    return this.accessApi.get(sprintf('/excollect_api/sites%s', path), cfg )
  //                         .pipe( 
  //                            first(),
  //                            switchMap(  resp => {
  //                               return of( new ApiCollection( type, resp ));  
  //                            }),
  //                         )


  //}



  public updateCurrentHost( host: Host )  {
      return this.updateHost(host)
                 .pipe( tap((host) => { this.currentHost = host }) );

  }

  public addHost( host: Host )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.updateHostCollectionRequest(site, [ host.onCreateData() ]) } ),
                  map( (collection) => { return collection.first() })
                );
  }

  public updateHost( host: Host )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.updateHostCollectionRequest(site, [ host.updateData() ] ) } ),
                  map( (collection) => { return collection.first() })
                );
  }

  public updateHostCollectionRequest( site:  Site, data: Array<Object> ) {
    
      return this.accessApi.updateCollection(Host, sprintf('/excollect_api/sites/%s/hosts', site.id), data )
  }

  public getActivationToken( host: Host ) {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getActivationTokenRequest(site, host) } )
                );
  } 

  private getActivationTokenRequest( site: Site, host: Host ) {

      return this.accessApi.get(
                              sprintf('/excollect_api/sites/%s/hosts/%s/activation_token', site.id, host.id )
                           )
                           .pipe( 
                              first(),
                              switchMap(  resp => {
                                 return of( resp.data.message );  
                              })
                           )

  }


  hostnameValidator(control: FormControl): { [key: string]: any } | null {
    const hostname = control.value;
    const hostnameRegex = /^(?!-)[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*\.[A-Za-z]{2,}$/;

    if (!hostnameRegex.test(hostname)) {
      return { 'invalidHostname': true };
    }

    return null;
  }

}
