<div *ngIf="(user$ | async) as user" class="px-3" >
  <div class="col">
    <form  [formGroup]="userSettingsForm" (ngSubmit)="updateUserSettings()">
      <div class="row">
         <div class="col-6">
            <h5 class="mb-0 mt-2">Profile</h5> 
         </div>
         <div class="col-6">
            <button type="submit" class="btn btn-primary">Update</button>
         </div>
      </div>
      <div class="col-8 px-3 py-4" > 
        <div class="row p-3">
          <label for="name" class="form-label fw-bold col-2">Name</label>
          <div class="col-md-5 col-sm-12">
            <input type="text" 
                   class="form-control" 
                   placeholder="First name" 
                   aria-label="First name"
                   formControlName="first_name"       
            >
          </div>
          <div class="col-md-5 col-sm-12">
            <input type="text" 
                   class="form-control" 
                   placeholder="Last name" 
                   aria-label="Last name"
                   formControlName="last_name"       
            >
          </div>
        </div>
        <div class="row p-3">
          <label for="name" class="form-label fw-bold col-2">Email</label>
          <div class="col-md-5 col-sm-12">
            <input type="text" 
                   class="form-control" 
                   placeholder="Email Address" 
                   aria-label="Email Address"
                   formControlName="email_addr"       
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <hr />
  <div class="col-12">
    <form  [formGroup]="userPasswordForm" (ngSubmit)="updateUserPassword()">
      <div class="row">
         <div class="col-6">
            <h5 class="mb-0 mt-2">Change Password</h5> 
         </div>
         <div class="col-6">
            <button type="submit" class="btn btn-primary">Submit</button>
         </div>
      </div>
      <div class="col-8 px-3 py-4" > 
        <input
          type="text"
          name="email"
          value="{{ user.email_addr }}"
          autocomplete="username email"
          style="display: none;"
        >
        <div class="mb-3 row px-3">
          <label for="newPassord" class="form-label fw-bold col-5">Old Password</label>
          <div class="col-6">
            <input type="password" 
                   class="form-control" 
                   id="old_password" 
                   autocomplete="current-password"
                   aria-describedby="old passowrd" 
                   formControlName="oldPassword"
            >
          </div>
        </div>

        <div class="mb-3 row px-3">
          <label for="newPassword" class="form-label fw-bold col-5">New Password</label>
          <div class="col-6">
            <input type="password" 
                   class="form-control" 
                   id="new_password" 
                   autocomplete="new-password"
                   aria-describedby="new passowrd" 
                   formControlName="newPassword"
            >
          </div>
        </div>
        <div class="mb-3 row px-3">

          <label for="newPassword" class="form-label fw-bold col-5">Confirm New Password</label>
          <div class="col-6">
            <input type="password" 
                   class="form-control" 
                   id="confirmNewPassword" 
                   autocomplete="new-password"
                   aria-describedby="Confirm New Passowrd" 
                   formControlName="confirmNewPassword"
            >
          </div>
        </div>
      </div>

    </form>
  </div>
</div>

