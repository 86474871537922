import { Component } from '@angular/core';

@Component({
  selector: 'app-command-detail',
  templateUrl: './command-detail.component.html',
  styleUrl: './command-detail.component.css'
})
export class CommandDetailComponent {

}
