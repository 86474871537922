import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, filter, switchMap } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountUsersService } from '../../../../account/services/account-users.service';
import { SiteService } from '../../services/site.service';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { Site } from '../../models/site.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
export class SettingsComponent {

  public site$!: BehaviorSubject<Site>;
  public siteConfigForm!: FormGroup;

  constructor( 
    private AccountUserService: AccountUsersService, 
    private siteService: SiteService,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService,
    private route: ActivatedRoute

  ) {

      this.siteConfigForm = new FormBuilder().group({
                              id: ['', [Validators.required ] ],
                              name: ['', [Validators.required ] ],
                              notes: ['', []],
                            });



  }

  ngOnInit(){

    this.route
        .params
        .pipe(
          filter( params => params['site_id'] ),
          switchMap((params) => { 
            return this.siteService.getSite(params['site_id'])  
          })
        )
        .subscribe({
           next: (site) => {

             //console.log(site)
             this.siteConfigForm.setValue({ 
                                     id:    site.id,
                                     name:  site.name, 
                                     notes: site.notes,
                                   });

             this.site$ = new BehaviorSubject(site)
           },
           error: (error) => {

           }   
        });

  }

  get sf(): FormGroup { return this.siteConfigForm; }

  updateSiteConfig() {
    if (this.sf.invalid) {
       return;
    }

    this.LoadingModal.setLoading();

    const formVals    = this.sf.value; 

    const updateSite = new Site();

    updateSite.id    = formVals.id;
    updateSite.name  = formVals.name;
    updateSite.notes = formVals.notes;

    this.siteService
        .updateSite(updateSite)
        .subscribe({
          next: (site) => {
            //console.log('success');
            this.site$.next(site);
            this.LoadingModal.unsetLoading();

          },
          error: (error) => {
            this.LoadingModal.unsetLoading();
            this.SiteError.setApiError(error);
          }
        });


  }

}
