import { Component } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';
// import { BehaviorSubject, Subject, Subscription } from 'rxjs';
// import { filter, switchMap, debounceTime } from 'rxjs';
// import { SiteService } from '../../services/site.service';
// import { CommandService } from '../../services/command.service';
// import { ApiCollection } from '../../../../shared/model/api-coillection.model';
// import { CommandInstance } from '../../models/command-instance.model';


@Component({
  selector: 'app-command-instances-tab',
  templateUrl: './command-instances-tab.component.html',
  styleUrl: './command-instances-tab.component.css'
})
export class CommandInstancesTabComponent {

  // public commandInstances$!: BehaviorSubject<ApiCollection<CommandInstance>>
  // private commandInstanceCreate$: Subject<number> = new Subject();
  // private subscription: Subscription = new Subscription();



  // constructor( 
  //   private CommandService: CommandService, 
  //   private route: ActivatedRoute,
  //   private router: Router,
  //   private siteService: SiteService,
  // ) {
  //   //this.subscription.add( 
  //   //    this.commandInstanceCreate$.pipe(
  //   //      debounceTime(1000)
  //   //    )
  //   //    .subscribe(() => {
  //   //       this.doAddInstance() 
  //   //    })
  //   //)



  // }

  // ngOnInit() {
  //     
  //   this.route
  //       .params
  //       .pipe(
  //         filter( params => params['command_id'] ),
  //         switchMap((params) => { 
  //           return this.CommandService.getCurrentCommand(params['command_id'])  
  //         }),
  //         switchMap((command) => { 
  //             return this.CommandService.getInstances(command)
  //         }), 
  //       )
  //       .subscribe( (commandInstances) => {
  //       
  //         this.commandInstances$ = new BehaviorSubject<ApiCollection<CommandInstance>>(commandInstances);  
 
  //       });

  // }
  
  // addInstance() {
  //   this.commandInstanceCreate$.next(1);
  // }

  // doAddInstance() {
  //    let currentCommand = this.CommandService.currentCommand;

  //    this.CommandService.createInstance(currentCommand)
  //                       .subscribe((instance) => {
  //                         this.loadCommandInstanceDetail(instance);
  //                       })
  // }

  // loadCommandInstanceDetail(commandInstance: CommandInstance) {
  //   this.router.navigate([ '../../../instances/'+ commandInstance.uriKey ], { relativeTo: this.route });
  // }

  // ngOnDestroy(): void {
  //    this.subscription.unsubscribe();
  // }

}
