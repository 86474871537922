import { Component } from '@angular/core';
import { SiteService } from '../services/site.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { combineLatestWith, map, Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-excollect-container',
  template: `
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div> 
  `,
  styles: `
    :host {
       min-height: 90vh;
       display: block;
    } 
  `
})
export class ContainerComponent {

  routeSub: any;

  constructor(
     private siteService: SiteService,
     private loggerService: LoggerService,
     private route: ActivatedRoute,
     private router: Router
  ) { 
  }

  ngOnInit(){

      //this.siteService.loadSiteUsers();

      this.route.params
                .pipe(
                   // making the params event dependent on 
                   // siteService.isLoaded event so activeSiteURL
                   // is set.
                   //combineLatestWith( this.siteService.isLoaded() ) ,
                  combineLatestWith( this.siteService.loadSiteUsers() ),
                  //map( ([params, users]) => params  ),
                  // first()
                )
                .subscribe( ([params, users]) => { 
                    if ( users.total == 0 ) {
                        this.router.navigate([ '/excollect/NotFound' ]);
                        return;
                    }

                    if ( this.route.firstChild?.snapshot.params['site_id'] == undefined ) {
                       //  console.log('REDIRECT');
                       //  console.log(loaded)
                      this.siteService.initDefaultActiveSite();
                      this.router.navigate([ this.siteService.activeSiteUrl ]);
                    }
                  
                    //console.log(this.route.firstChild?.snapshot.params)
                }); 
      //this.siteService.isLoaded().subscribe( ()  => {
      //   if ( !this.route.firstChild?.snapshot.params['site_id'] ) {
      //       this.siteService.set_active_site_user_default();
      //       this.router.navigate([ this.siteService.activeSiteUrl ]);
      //   }
      //});

      //this.router.events
      //          .pipe(
      //             combineLatestWith( this.siteService.isLoaded()  ) ,
      //             map( ([params, loaded]) => params  ) 
      //          )
      //          .subscribe( events => {
      //              //let site_id = params['site_id'];
      //              //console.log(events)
  
      //              //if ( site_id ) { 
      //              //  this.siteService.set_active_site_user_by_uri(site_id);
      //              //}
      //              //else {
      //              //  this.siteService.set_active_site_user_default();
      //              //  this.router.navigate([ this.siteService.activeSiteUrl ]);
      //              //}
      //              
      //           }); 


         //        .subscribe(params => {
         ////console.log(params);
         // let site_id = params['site_id'];
         // if ( !site_id ) { 
         //    //this.siteService.loadSiteUsers();
         //    this.siteService.loaded$
         //                    .pipe(
         //                      filter( loaded =>  loaded ),
         //                      switchMap(() => this.siteService.activeSiteUser$ ) 
         //                    )
         //                    .subscribe( site_user => {
         //                       this.router.navigate([ this.siteService.activeSiteUrl ]);
         //                    }) 
         // }
        //  });

  }
}
