<div class="container">
  <div class="row justify-content-center">
    <div class="my-4">
      <ul class="nav nav-tabs mb-4 " id="usersDetailTabs" role="tablist">
         <li class="nav-item">
           <a class="nav-link inactive_tab" 
               id="settings-tab" 
               routerLink="settings"
               routerLinkActive="active text-dark bg-white"
           >
           Settings 
           </a>
         </li>
      </ul>
    </div>

    <router-outlet></router-outlet> 

  </div>
</div>
