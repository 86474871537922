import { Component } from '@angular/core';

@Component({
  selector: 'app-command-users-tab',
  templateUrl: './command-users-tab.component.html',
  styleUrl: './command-users-tab.component.css'
})
export class CommandUsersTabComponent {

}
