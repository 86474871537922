import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { CommandService } from '../../services/command.service';
import { Command } from '../../models/command.model';

@Component({
  selector: 'app-commands',
  templateUrl: './commands-list.component.html',
  styleUrl: './commands-list.component.css'
})
export class CommandsComponent {

  public commands$!: BehaviorSubject<ApiCollection<Command>>

  constructor( 
    private CommandService: CommandService, 
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(){
      
      this.CommandService.getCommands().subscribe( (commandCollection) => {
          
          this.commands$ = new BehaviorSubject<ApiCollection<Command>>(commandCollection);  
      });
  }

  loadCommandDetail(command: Command) {
    this.router.navigate([ command.uriKey ], { relativeTo: this.route });
  }

  loadPage( page: number ) {
      let hosts = this.commands$.value;

      let cfg = { searchAttrs: { limit: hosts.searchAttrs.limit, page: page } };

      this.CommandService.getCommands(cfg).subscribe( (hostCollection) => {
          this.commands$.next(hostCollection);  
      });


  }

}
