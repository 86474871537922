import { ValidatorFn, AbstractControl, FormGroup, FormControl  } from '@angular/forms';

export function matchValidator(
                   controlName: string, 
                   matchingControlName: string,
                   errorMsg: string = 'Fields do not match'
                ): ValidatorFn {

  return (abstractControl: AbstractControl) => {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);

      if (matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']) {
          return null;
      }

      if (control!.value !== matchingControl!.value) {
        const error = { mismatch: { msg: errorMsg } };
        matchingControl!.setErrors(error);
        return error;
      } else {
        matchingControl!.setErrors(null);
        return null;
      }
  }
}

export function getFormErrors( f: FormGroup ): Array<{}>|undefined{

    if (f.invalid) {

      // TODO generalize into some error reporting service
      const result: any[] = [];
      Object.keys(f.controls).forEach(key => {
    
        const controlErrors = f.get(key)?.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            result.push({
              'control': key,
              'error': keyError,
              'value': controlErrors[keyError]
            });
          });
        }
      });

      return result;
    }

    return undefined
}

export function getFormControlError( f: FormGroup, key: string, submitted: Boolean ): Array<{error: string, value: string}>|undefined{

    const control = key == 'Group' ? f : f.get(key);

    if ( !(control?.invalid && ( submitted || control?.dirty || control?.touched ) ) ) {
      return undefined; 
    }

    const controlErrors = control?.errors;

    const result: Array<{ error: string, value: string}> = []; 

    if ( controlErrors ) {
          
          Object.keys(controlErrors).forEach(keyError => {

            let err_msg: string;
            if ( keyError == 'required' ) { 
               err_msg = 'This field is required'
            }
            else if (keyError == 'pattern') {
               err_msg = 'Value must match: '+ controlErrors[keyError].requiredPattern

            }
            else if (keyError == 'min') {
               err_msg = 'Value must be at least '+ controlErrors[keyError].min
            }
            else if (keyError == 'max') {
               err_msg = 'Value cannot exceed '+ controlErrors[keyError].max
            }
            else if (keyError == 'email') {
               err_msg = 'Valid Email Address Required'
            }
            else if (keyError == 'mismatch') {
               err_msg = controlErrors[keyError].msg
            }
            else if (keyError == 'passwordStrength') {
               err_msg = controlErrors[keyError].weakness.join(',')
            }

            else {
              err_msg = 'Error'
            }

            result.push({
              'error': keyError,
              'value': err_msg 
            });
          });

      return result;
    }

    return undefined
}

export interface ValidationResult {
    [key: string]: { weakness?: Array<string>};
}

// sub validate_password_checks {
//   local $_=pop;
// 
//   my $s='!#+,-./:=@_'; #allowed special chars
// 
//   grep $_,
//   /^[a-z\d$s]+$/i        ? 0 : "Password must be just nums, letters and special chars $s",
//   length()>=10           ? 0 : "Minimum length of the password is 10",
//   length()<=32           ? 0 : "Maximum length of the password is 32",
//   !/(.)\1{9}/            ? 0 : "Same char 10 or more in a row",
//   /^[a-zA-Z0-9]/         ? 0 : "First character can not be special character",
//   1 < /[a-z]/i + /\d/ + /[$s]/ ? 0 : "Must contain at least 2 two types of characters from letters, numbers or '$s'";
// }

export class PasswordValidator {

    public static strong(control: FormControl): ValidationResult | null {
        const password = <string>control.value;

        const onlyChars = new RegExp( '^[a-z0-9!#+,-./:=@_]+$' , "i").test(password);

        const minLength = `${password}`.length >= 10;
        const maxLength = `${password}`.length <= 32;

        const hasNumber  = /[0-9]/.test(password);
        const hasUpper   = /[A-Z]/.test(password);
        const hasLower   = /[a-z]/.test(password);
        const hasSpecial = new RegExp('[!#+,-./:=@_]').test(password);
        const minType    = [hasNumber, hasUpper, hasNumber, hasSpecial ].filter( is_true => is_true )

        const weakness: Array<string> = [];

        if (!onlyChars ) {
            weakness.push('Password must contain letters, or "!#+,-./:=@_"');
        }        
        if (!minLength ) {
            weakness.push('Password must be at least 10 characters long');
        }
        if (!maxLength ) {
            weakness.push('Password cannot be more than 32 characters long');
        }
        if (minType.length < 2 ) {
            weakness.push('Password must contain at least two types of characters');
        }

        if (weakness.length) {
            return { passwordStrength: { weakness: weakness } };
        }

        return null;
    }
}