<div class="container ps-0 ms-0 d-flex flex-column h-100">
                                                 <!-- fixes row breaking out of div-->
  <div class="row ms-0 ps-0 justify-content-center"  style="margin: auto">

    <div class="my-4 ps-0">
      <ul class="nav nav-tabs mb-4 " id="HostDetailTabs" role="tablist">
          <li class="nav-item px-2">
            <a class="nav-link active text-dark bg-white" 
                id="setting-tab" 
            >
            Settings 
            </a>
          </li>
      </ul>
    </div>

  </div>
  <div *ngIf="(site$ | async) as site; else loadingBlock" class="row  h-100 mx-0" style="margin: auto">
    <div class="col-12">
        <form  [formGroup]="siteConfigForm" (ngSubmit)="updateSiteConfig()">
            <div class="row pb-3">
               <div class="col-6">
                 <h5 class="mb-0 mt-2">Settings</h5> 
               </div>
               <div class="col-6">
                  <button type="submit" class="btn btn-primary">Update</button>
               </div>
            </div>
            <div class="mb-3 row ps-2">
              <label for="name" class="form-label fw-bold col-2">Name</label>
              <div class="col-5">
                  <input type="text" 
                       class="form-control col-10" 
                       id="name" 
                       aria-describedby="name" 
                       formControlName="name">
              </div>
            </div>
            <div class="mb-3 row ps-2">
              <label for="notes" class="form-label fw-bold col-12">Notes</label>
              <div class="col-7 ps-5">
                <textarea 
                       rows="5"
                       class="form-control" 
                       id="notes" 
                       formControlName="notes"> 
                </textarea>


              </div>
            </div>

        </form>
    </div>
  </div>
</div>

<ng-template #loadingBlock>
  <div class="row flex-grow-1"  style="margin: auto">
    <app-tab-loading></app-tab-loading>
  </div>
</ng-template>
