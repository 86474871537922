import { Component } from '@angular/core';
import { BehaviorSubject, switchMap, forkJoin, of, combineLatestWith } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { SiteService } from '../../services/site.service';
import { SiteUser } from '../../models/site-user.model';
import { SiteUserGroup } from '../../models/site-user-group.model';
import { AccountUsersService } from '../../../../account/services/account-users.service';
import { UserGroupService } from '../../services/user-group.service';


@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrl: './user-groups.component.css'
})
export class UserGroupsComponent {

  public groups$!: BehaviorSubject<ApiCollection<SiteUserGroup>>
  public userMap!: { [name: string]: any };
  public activeUser!: SiteUser;


  constructor( 
    private SiteService: SiteService, 
    private SiteGroupService:  UserGroupService,
    private AccountUserService: AccountUsersService, 
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(){
      
      let cfg = { searchAttrs: { limit: 15 } }; //, order_by: { asc: 'last_checkin' } } };

      this.AccountUserService.isLoaded()
                      .pipe(
                        switchMap( (loaded) => {
                              return this.SiteGroupService.getUserGroups(cfg); 
                           } 
                        )
                      ) 
                      .subscribe({
                        next: (groupCollection) => {
                          this.groups$ = new BehaviorSubject<ApiCollection<SiteUserGroup>>(groupCollection);  
                          this.activeUser = this.SiteService.activeSiteUser$.value;
                        },
                        error: (error) => {
                          console.log(error)
                        }
                      });

  }

  loadGroupDetail(group: SiteUserGroup): void {
    this.router.navigate([ group.uriKey ], { relativeTo: this.route });
  }


  loadPage( page: number ) {

  }
}
