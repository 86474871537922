<div class="container " style="height: 90vh">
  <app-site-error></app-site-error>
  <form class="col-sm-10 col-md-6 col-lg-5 m-auto py-5" [formGroup]="loginForm" (ngSubmit)="do_login()">
    <div class="mb-3 row">
      <label for="loginEmail" class="form-label fw-bold col-sm-4">Email address:</label>
      <div class="col-sm-8 col-lg-7">
        <input type="email" class="form-control" id="loginEmail" aria-describedby="email" formControlName="login_email">

         <div *ngIf="getFormControlError( loginForm, 'login_email', submitted ); let errors" class="d-inline-block"> 
           <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
             {{ error.value }} 
           </div>
         </div>

      </div>
    </div>
    <div class="mb-3 row">
      <label for="loginPassword" class="form-label fw-bold col-sm-4">Password:</label>
      <div class="col-sm-8 col-lg-7">
        <input type="password" class="form-control" id="logPassword" formControlName="login_password">

        <div *ngIf="getFormControlError( loginForm, 'login_password', submitted ); let errors" class="d-inline-block"> 
          <div *ngFor="let error of errors" class="d-inline-block  alert alert-danger py-1 my-0 mx-3" role="alert">
            {{ error.value }} 
          </div>
        </div>


      </div>
    </div>
    <button type="submit" class="btn btn-primary float-end">Login</button>
  </form>
</div>