import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, switchMap, debounceTime } from 'rxjs';
import { SiteService } from '../../services/site.service';
import { CommandService } from '../../services/command.service';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { CommandInstance } from '../../models/command-instance.model';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Command } from '../../models/command.model';
import { objectAttributes } from '../../../../shared/interfaces/object-attributes';
import { SiteErrorService } from '../../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { LoadingModalService } from '../../../../shared/modules/rw-utils/modals/loading/loading-modal.service';


@Component({
  selector: 'app-command-instances-tab-create',
  templateUrl: './command-instances-tab-create.component.html',
  styleUrl: './command-instances-tab-create.component.css',
})
export class CommandInstancesTabCreateComponent {

  private commandInstanceCreate$: Subject<number> = new Subject();

  public InstanceConfigForm!: FormGroup; 
  public currentCommand$!: BehaviorSubject<Command>; // = new Subject<Command>();

  constructor( 
    private CommandService: CommandService, 
    private route: ActivatedRoute,
    private router: Router,
    private siteService: SiteService,
    private LoadingModal: LoadingModalService,
    private SiteError: SiteErrorService
  ) {

  }

  ngOnInit(){

    this.route.params
              .pipe(
                filter( params => params['command_id'] ),
                switchMap((params) => { 
                  return this.CommandService.getCurrentCommand(params['command_id'])  
                })
              )
              .subscribe( command => {

                 let fields: { [name: string ]: Array<any>} = {
                   label: [ '', [ Validators.required, ] ], 
                 }

                 let field_values: { [name: string ]: string } = {
                   label: command.name + ' instance',
                 }

                 command.parameters.forEach( (param) => {

                       fields[param.name] = [ '', [ Validators.required, ] ];

                       if ( param.default ) {
                          field_values[param.name] = param.default;
                       }
                       else {
                          field_values[param.name] = '';
                       }
                 });

                 this.InstanceConfigForm = new FormBuilder().group(fields);

                 this.InstanceConfigForm.setValue(field_values);

                 this.currentCommand$ = new BehaviorSubject<Command>(command);
              });


  }


  get if(): FormGroup { return this.InstanceConfigForm; }

  doNothing() {
      console.log('nothing')
  }

  doAddInstance() {

    if (this.if.invalid) {

      console.log(this.if.controls);
      
      return;
    }

    this.LoadingModal.setLoading();

    let formVals    = this.if.value; 

    let currentCommand = this.CommandService.currentCommand;

    let data: { label: string, attributes: objectAttributes } = { 
        label: formVals.label,
        attributes: {} 
    };

    currentCommand.parameters.forEach( (param) => {
        data['attributes'][param.name +'_CUSTOM'] = formVals[param.name];
    });

    this.CommandService.createInstance(currentCommand, data)
                       .subscribe({
                         next: (instance) => {
                            this.LoadingModal.unsetLoading();
                            this.loadCommandInstanceDetail(instance);
                         },
                         error: (error) => {
                             this.LoadingModal.unsetLoading();
                             this.SiteError.setApiError(error);
                             //console.log(error.response.data)
                         }
                       })
  }

  loadCommandInstanceDetail(commandInstance: CommandInstance) {
    this.router.navigate([ '../../../../instances/'+ commandInstance.uriKey ], { relativeTo: this.route });
  }


}
