<div class="container d-flex flex-column h-100">
  <div class="row px-4">
    <div class="col-8 ps-4 pt-4">
      <a [routerLink]="['create']">
         <button class="btn btn-primary">
            <i class="bi bi-plus-square-fill me-1"></i> Add Command
         </button>
      </a> 
    </div>
    <div class="col-4  px-4 pt-4">
      <!--
       <input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search...">
       -->
    </div>
  </div>
  <div class="row p-4">
    <table class="table table-hover">
        <thead class="table-dark">
          <tr>
            <th class="col-3">Name</th>
            <th class="col-4">Command</th>
            <th class="col-5">Description</th>
          </tr>   
        </thead>
        <tbody *ngIf="(commands$ | async) as commands; else loadingBlock" >
             <tr *ngFor="let command of commands.collection; index as i" (click)="loadCommandDetail(command)">
               <td>{{ command.name }}</td>
               <td>{{ command.command_string }}</td>
               <td>{{ command.description }}</td>
             </tr>   
        </tbody>
    </table>
  </div>
  <div *ngIf="(commands$ | async) as commands" class="row mt-auto mx-auto">
    <app-pager [collection]="commands" (newPage)="loadPage($event)"></app-pager>
  </div>
  <ng-template #loadingBlock>
    <p>Loading...</p>
  </ng-template>
  <ng-template #waitRegBlock>
     <td>Awaiting Registration</td>
  </ng-template>

</div>
