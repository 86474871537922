import { Component } from '@angular/core';

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrl: './user-groups.component.css'
})
export class UserGroupsComponent {

}
