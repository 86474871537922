import { ValidatorFn, AbstractControl, FormGroup  } from '@angular/forms';

export function matchValidator(
                   controlName: string, 
                   matchingControlName: string,
                   errorMsg: string = 'Fields do not match'
                ): ValidatorFn {

  return (abstractControl: AbstractControl) => {
      const control = abstractControl.get(controlName);
      const matchingControl = abstractControl.get(matchingControlName);

      if (matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']) {
          return null;
      }

      if (control!.value !== matchingControl!.value) {
        const error = { confirmedValidator: errorMsg };
        matchingControl!.setErrors(error);
        return error;
      } else {
        matchingControl!.setErrors(null);
        return null;
      }
  }
}

export function getFormErrors( f: FormGroup ): Array<{}>|undefined{

    if (f.invalid) {

      // TODO generalize into some error reporting service
      const result: any[] = [];
      Object.keys(f.controls).forEach(key => {
    
        const controlErrors = f.get(key)?.errors;
        if (controlErrors) {
          Object.keys(controlErrors).forEach(keyError => {
            result.push({
              'control': key,
              'error': keyError,
              'value': controlErrors[keyError]
            });
          });
        }
      });

      return result;
    }

    return undefined
}
