import { ApiCollection } from "./api-coillection.model";

export class BaseApiObject {
   public id!: string;
   public collection?: ApiCollection<any>;

   public get writable(): Array<string| (() => {})> { return [] };
   public get onCreate(): Array<string| (() => {})> { return [] };

   constructor(
      private data?: Object
   ) { 
      Object.assign(this, data);
      delete this.data;
   } 

   //getTT(prop: string) {
   //   let key = prop as keyof typeof this;

   //   return  this[key];
  
   //   // return '';
   //   //#return  this[key];
   //}


   onCreateData(): { [ name: string ]: any } {

      let ret : { [ name: string ]: any } = {};

      this.onCreate.forEach((prop: string | ( () => {} ) ) => { 
         if ( typeof prop === 'string' )  {
           let key = prop as keyof typeof this;
           ret[prop] = this[key] 
         }
         else if (typeof prop === 'function' ) {
            let prop_ret = prop();
            ret = { ...ret, ...prop_ret }
         }
      });

      return ret;

   }

   updateData(): { [ name: string ]: any } {
      return this.getWritableData();
   }

   getWritableData() {
   
      let ret : { [ name: string ]: any } = { id: this.id };

      this.writable.forEach((prop: string | ( () => {} ) ) => { 
         if ( typeof prop === 'string' )  {
           let key = prop as keyof typeof this;
           ret[prop] = this[key] 
         }
         // writable can contain functions that return 
         // attribute names.
         else if (typeof prop === 'function' ) {
            let prop_ret = prop();
            ret = { ...ret, ...prop_ret }
         }
      });

      return ret;
   }

   // Local Object storage
   public deserialize(input: any): this {
       Object.assign(this, JSON.parse(input));
       return this;
   }

   public serialize(): string {
      return JSON.stringify(this);
   }

}
