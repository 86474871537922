import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { SiteErrorService } from './site-error.service';
//import $ from 'jquery';
//import * as bootstrap from 'bootstrap';
import Modal from 'bootstrap/js/dist/modal';

@Component({
  selector: 'app-site-error',
  templateUrl: './site-error.component.html',
  styleUrl: './site-error.component.css'
})


export class SiteErrorComponent {

  public errorMsg!: String;
  public errorId!: String;


  private errorSubscription: Subscription = new Subscription();

  constructor( 
    private SiteError: SiteErrorService
  ) {

  }

  ngOnInit(){
      this.errorSubscription = this.SiteError.errors$
                                             .subscribe( (error) => { 
                                              console.log(error) 
                                            
                                              this.errorMsg = error.msg;
                                              this.errorId =  error.request_id;

                                              //let modal = $('#errorModal');
                                              let bs = new Modal('#errorModal',{} ) 
                                              bs.show();
                                             })
  }

  ngOnDestroy(): void {
     this.errorSubscription.unsubscribe();
  }

}
