import { Injectable } from '@angular/core';
import { AxiosError } from 'axios';
import { ApiErrorMessage } from '../../../../interfaces/api-responses';
import { Subject } from 'rxjs';

export type SiteError = { msg: String, request_id: String };


@Injectable({
  providedIn: 'root'
})
export class SiteErrorService {

  errors$: Subject<SiteError> = new Subject();

  constructor() { }

  public setApiError( api_error: AxiosError) {

      let error_data  = <ApiErrorMessage>api_error.response?.data;
      let request_id  = error_data.request_id;
      let error_msg   = error_data.error;
      let error_id    = error_data.error_type_id;

      this.errors$.next({ msg: error_msg, request_id: request_id });
  }

  public setError( error_msg: String, request_id: String ) {

      this.errors$.next({ msg: error_msg, request_id: request_id });
  }


}
