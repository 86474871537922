<div class="col-12 d-flex  flex-column h-100"> 
  <div class="row px-4">
    <div class="col-8">
        
    </div>
    <div class="col-4  px-4 pt-4 mt-auto">
      <!--
       <input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search...">
       -->
    </div>
  </div>
  <div *ngIf="(hostJobs$ | async) as hostJobs" class="row pt-4 px-3">
    <div #hostJobsList class="accordion" id="accordionExample">

      <div *ngFor="let hostJob of hostJobs.collection;" 
           class="accordion-item"
      >
        <h2 class="accordion-header " id="{{ 'header'+ hostJob.id }}">
          <!--<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#headingTwo,#headingThree,#collapseOne,.accordion-header:not(#headingOne)" aria-expanded="true" aria-controls="collapseOne">-->
          <button class="accordion-button collapsed" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  [class]="statusClass(hostJob)"
                  [class.collapsed]="!hostJobOpen(hostJob)"
                  [attr.data-bs-target]="'#collapse'+ hostJob.id " 
                  aria-expanded="true" 
                  [attr.aria-controls]="'#collapse'+ hostJob.id "
                  (click)="openHostJob(hostJob)"
          >
          <h5 class="mb-0"> {{ hostJob.host.hostname }} </h5> 
          </button>
        </h2>
        <div id="{{ 'collapse'+ hostJob.id }}" 
             class="accordion-collapse collapse" 
             [class.show]="hostJobOpen(hostJob)"
             aria-labelledby="headingOne" 
             data-bs-parent="#accordionExample"
        >
          <div  class="accordion-body">
            <div class="row">
              <div class="class col-12 px-3 pb-3">
                <label for="command_string" class="fw-bold mx-auto">
                 Command: {{ hostJob.command_string }}
                </label>
              </div>
            </div>
            <div class="row pb-3">
              <div class="class col-md-6 col-sm-12">
                <div class="class row px-1">
                  <label for="Status" class="fw-bold mx-auto col-8">
                   Status: 
                  </label>
                  <div class="class col-4">
                    {{ hostJob.status }}
                  </div>
                </div>
                <div class="class row px-1">
                  <label for="" class="fw-bold mx-auto col-8">
                   Priority: 
                  </label>
                  <div class="class col-4">
                    {{ hostJob.priority  }}
                  </div>
                </div>
                <div class="class row px-1">
                  <label for="" class="fw-bold mx-auto col-8">
                   Retries: 
                  </label>
                  <div class="class col-4">
                    {{ hostJob.retries  }}
                  </div>
                </div>
              </div>
              <div class="class col-md-6 col-sm-12">
                <div class="class row px-1">
                  <label for="" class="fw-bold mx-auto col-4">
                   Started: 
                  </label>
                  <div class="class col-8">
                    {{ hostJob.localStartDateTime  }}
                  </div>
                </div>
                <div class="class row px-1">
                  <label for="" class="fw-bold mx-auto col-4">
                   Completed:
                  </label>
                  <div class="class col-8">
                    {{ hostJob.localCompletedDateTime  }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col" style="height:50em">
                <app-host-job-log *ngIf="hostJobOpen(hostJob)" [hostJob]="hostJob"></app-host-job-log>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="(hostJobs$ | async) as hostJobs" class="row p-3 flex-grow-1 mx-auto align-items-end">
     <app-pager [collection]="hostJobs" (newPage)="loadPage($event)"></app-pager>
  </div>
</div>
<router-outlet></router-outlet>