import { Component } from '@angular/core';
import { SiteErrorService } from '../../../shared/modules/rw-utils/modals/site-error/site-error.service';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { LoadingModalService } from '../../../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { AccountUsersService } from '../../services/account-users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { sprintf } from 'sprintf-js';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.css'
})
export class UsersListComponent {

  constructor( 
    private SiteError: SiteErrorService,
    private LoadingModal: LoadingModalService,
    private currentUser: CurrentUserService,
    private UserService: AccountUsersService,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(){

        this.UserService.getUserRoles()
                        .subscribe( (collection) => { 
                           //console.log(collection)

                           if ( collection.total == 1 ) {
                              const user = collection.first();
                              this.router.navigate([ user.uriKey ], { relativeTo: this.route });
                           }
                           
                          
                        })
  }
}
