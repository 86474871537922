<div class="container h-100 ms-0 ps-0 d-flex flex-column ">
  <div class="row justify-content-center">
    <div class="pt-4">
      <ul class="nav nav-tabs " id="HostDetailTabs" role="tablist">
          <li class="nav-item ps-2">
            <a class="nav-link inactive_tab" 
                id="config-tab" 
                routerLink="config"
                routerLinkActive="active text-dark bg-white"
            >
            Configuration 
            </a>
          </li>
          <!--
          <li class="nav-item px-1">
            <a class="nav-link inactive_tab" 
               id="hosts-tab" 
               routerLink="documentation"
               routerLinkActive="active text-dark bg-white"
            >
            Documentation 
            </a>
          </li>
          -->
          <li class="nav-item ps-1">
            <a class="nav-link inactive_tab" 
               id="hosts-tab" 
               routerLink="hosts"
               routerLinkActive="active text-dark bg-white"
            >
            Clients 
            </a>
          </li>
          <!--
          <li class="nav-item px-1">
            <a class="nav-link inactive_tab" 
               id="users-tab" 
               routerLink="users"
               routerLinkActive="active text-dark bg-white"
            >
            Users
            </a>
          </li>
          -->
          <li class="nav-item ps-1">
            <a class="nav-link inactive_tab" 
               id="instances-tab" 
               routerLink="instances"
               routerLinkActive="active text-dark bg-white"
            >
            Instances 
            </a>
          </li>
      </ul>
    </div>
  </div>
 <div class="row h-100 d-flex flex-column">
   <router-outlet></router-outlet> 
 </div>

</div>