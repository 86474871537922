import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApiMessage } from '../shared/interfaces/api-responses';
import { SiteErrorService } from '../shared/modules/rw-utils/modals/site-error/site-error.service';
import { LoadingModalService } from '../shared/modules/rw-utils/modals/loading/loading-modal.service';
import { AccessApiService } from '../shared/services/access-api.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent {

  SignUpForm: FormGroup;
  registered: Boolean = false 

  constructor( 
    private accessApi: AccessApiService, 
    private fb: FormBuilder, 
    private SiteError: SiteErrorService,
    private LoadingModal: LoadingModalService,
    private router: Router,

  ) {

      this.SignUpForm = this.fb.group({
        newEmail: ['', [Validators.required, Validators.email] ],
        newAccName: ['', [Validators.required] ],
        newFName: ['', [Validators.required] ],
        newLName: ['', [Validators.required] ],

      });

  }

  get f(): FormGroup { return this.SignUpForm; }

  public doSignUp() {
    console.log(this.f.value);

    if (this.f.invalid) {
      console.log(this.f.errors);
      return;
    }

    this.LoadingModal.setLoading();

    let formVals   = this.f.value; 
    let data = {
      email:        formVals.newEmail, 
      name:         formVals.newAccName, 
      first_name:   formVals.newFName, 
      last_name:    formVals.newLName, 
      init_service: 'sl_065c8a0f9cbe4d2b8a35b7fb190c2510' // Free Excollect
    };

    this.accessApi.post( '/account_api/users/create_account', data ) 
                  .pipe(
                    first()
                  ) 
                  .subscribe({
                    next: (resp) => {
                      this.LoadingModal.unsetLoading();
                      console.log(resp)
                      // change to check email for activation link
                      this.registered = true;
                    },
                    error: (error) => {
                      this.LoadingModal.unsetLoading();
                      this.SiteError.setApiError(error);
                    }
                  })
  }



}
