import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  template: `
    <div class="min-vh-100">
    <p>
      not-found works!
    </p>
    </div>
  `,
  styles: ``
})
export class NotFoundComponent {

}
