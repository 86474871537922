"use strict";

import moment from 'moment-timezone';  

export function getCurrentTimeStamp ( tz_string ) {

  const date_now = new Date();
  const now      = moment.tz( date_now, ( tz_string || 'utc' ) ); 

  // ISO 8601 with fractional seconds and time zone offset.
  return now.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
}  


//export function generate_timestamp;
