import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteErrorComponent } from './modals/site-error/site-error.component';
import { LoadingComponent } from './modals/loading/loading.component';
import { AddDeBounceClickDirective } from './directives/add-de-bounce-click.directive';



@NgModule({
  declarations: [
    SiteErrorComponent,
    LoadingComponent,
    AddDeBounceClickDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SiteErrorComponent,
    LoadingComponent,
    AddDeBounceClickDirective,
  ]

})
export class RwUtilsModule { }
