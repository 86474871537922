import { Injectable } from '@angular/core';
import { AccessApiService, ExcClientRequestCfg  } from '../../../shared/services/access-api.service';
import { first, switchMap, tap, combineLatestWith } from 'rxjs/operators';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { SiteService } from './site.service';
import {sprintf} from "sprintf-js";
import { pipe, skipUntil, filter, of, map, forkJoin, Subject, BehaviorSubject } from 'rxjs';
import { ApiCollection } from '../../../shared/model/api-coillection.model';
import { Site } from '../models/site.model';
import { Host } from '../models/host.model';
import { Command, CommandStub } from '../models/command.model';
import { FormControl } from '@angular/forms';
import { CommandHost } from '../models/command-host.model';
import { CommandInstance, CommandInstanceStub } from '../models/command-instance.model';
import { HostJob } from '../models/host-job.model';
import { CommandInstanceLog } from '../models/command-instance-log.model';


@Injectable({
  providedIn: 'root'
})
export class CommandInstanceService {

  currentInstance: CommandInstance = new CommandInstanceStub();
  currentInstance$: BehaviorSubject<CommandInstance> = new BehaviorSubject<CommandInstance>(new CommandInstanceStub);

  constructor(
    private accessApi: AccessApiService,
    private siteService: SiteService,
    private currentUserService: CurrentUserService
  ) { }

  public setCurrentInstance( instance: CommandInstance ) {
      this.currentInstance = instance;
      this.currentInstance$.next(instance);
  }

  public getCurrentInstance( instanceUriKey: string )  {

     if ( this.currentInstance?.uriKey === instanceUriKey ) {
        return of(this.currentInstance);
     }
     
     return this.getInstance(instanceUriKey)
                .pipe( 
                  tap((instance) => { 
                    this.setCurrentInstance(instance)
                    //this.currentInstance = instance;
                    //this.currentInstance$.next(instance);
                  }) );
  }

  public getInstance( instanceUriKey: string )  {
     
     let cfg = { searchArgs: { attributes: { uri_key: instanceUriKey } } };

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { return this.getInstancesCollectionRequest(site, cfg) }),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }

  public getInstances( cfg?: ExcClientRequestCfg )  {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getInstancesCollectionRequest(site, cfg) 
                  }),
                  first()
                );
  }

  private getInstancesCollectionRequest( site:  Site,  cfg?: ExcClientRequestCfg ) {

      let path = sprintf('/excollect_api/sites/%s/command_instances', site.id );

      return this.accessApi
                 .getCollection(CommandInstance, path ,cfg )
  }

  public updateCurrentInstance( instance: CommandInstance )  {

      return this.updateInstance(instance)
                 .pipe( tap((instance) => { this.currentInstance = instance }) );

  }

  public updateInstance( instance: CommandInstance )  {
     
     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.updateCommandInstanceCollectionRequest(site, [ instance.updateData() ] ) 
                  }),
                  map( (collection) => { return collection.first() }),
                  first()
                );
  }

  public updateCommandInstanceCollectionRequest( site:  Site, data: Array<Object> ) {

      let path = sprintf('/excollect_api/sites/%s/command_instances', site.id);
    
      return this.accessApi
                 .updateCollection(CommandInstance, path, data )
  }

  public getJob( hostJobUriKey: string ) {

     let cfg = { searchArgs: { attributes: { uri_key: hostJobUriKey } } };

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getJobsCollectionRequest( site, this.currentInstance, cfg ) 
                  }),
                  first(),
                  map( (collection) => { return collection.first() })
                );

  } 

  public getJobs( instance: CommandInstance , cfg?: ExcClientRequestCfg ) {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getJobsCollectionRequest(site, instance, cfg) 
                  }),
                  first()
                  //map( (collection) => { return collection.first() })
                );
  }

  private getJobsCollectionRequest( site:  Site, instance: CommandInstance, cfg?: ExcClientRequestCfg ) {

      let path = sprintf('/excollect_api/sites/%s/command_instances/%s/host_jobs', site.id, instance.id );

      return this.accessApi
                 .getCollection(HostJob, path , cfg)
  }

  public getLogs( instance: CommandInstance , cfg?: ExcClientRequestCfg ) {

     return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 
                    return this.getLogsCollectionRequest(site, instance, cfg) 
                  }),
                  first()
                  //map( (collection) => { return collection.first() })
                );
  }

  private getLogsCollectionRequest( site:  Site, instance: CommandInstance, cfg?: ExcClientRequestCfg ) {

      let path = sprintf('/excollect_api/sites/%s/command_instances/%s/logs', site.id, instance.id );

      return this.accessApi
                 .getCollection(CommandInstanceLog, path , cfg)
  }

  public setFinalInstanceCommand(  instance: CommandInstance, cfg?: ExcClientRequestCfg ) {

      let path_t = '/excollect_api/sites/%s/command_instances/%s/set_final_command';

      return this.siteService
                .getActiveSiteUser()
                .pipe( 
                  switchMap( (siteUser) => { 

                    let path = sprintf(path_t, siteUser.site.id, instance.id );

                    return this.accessApi.get( path ) 
                  }),
                  map( (resp) => { return new CommandInstance(resp.data.message.data) }),
                  first()
                );

  }

  public startInstance(  instance: CommandInstance, cfg?: ExcClientRequestCfg ) {

      let path_t = '/excollect_api/sites/%s/command_instances/%s/start';

      return this.siteService
                .getActiveSiteUser()
                .pipe( 
                  switchMap((siteUser) => {
                     return forkJoin([of(siteUser), this.setFinalInstanceCommand(instance)])

                  }),
                  switchMap( ([siteUser, new_instance]) => { 

                    let path = sprintf(path_t, siteUser.site.id, instance.id );

                    let data = {
                      signer_user_id: siteUser.id,
                      signature:      this.accessApi.signCommandInstance( new_instance, siteUser )
                    }

                    return this.accessApi.post( path, data, cfg ) 
                  }),
                  map( (resp) => { return new CommandInstance(resp.data.message.data) }),
                  first()
                );
  }

  public pauseInstance(  instance: CommandInstance, cfg?: ExcClientRequestCfg ) {

    let path_t = '/excollect_api/sites/%s/command_instances/%s/pause';

    return this.runInstanceActionGet( instance, path_t );

  }

  public unPauseInstance(  instance: CommandInstance, cfg?: ExcClientRequestCfg ) {

      let path_t = '/excollect_api/sites/%s/command_instances/%s/unpause';

      return this.runInstanceActionGet( instance, path_t );
      //return this.siteService
      //          .getActiveSite()
      //          .pipe( 
      //            switchMap( (site) => { 

      //              let path = sprintf(path_t, site.id, instance.id );

      //              return this.accessApi.get( path, cfg ) 
      //            }),
      //            first()
      //          );
  }

  public completeInstance(  instance: CommandInstance, cfg?: ExcClientRequestCfg ) {

    let path_t = '/excollect_api/sites/%s/command_instances/%s/complete';

    return this.runInstanceActionGet( instance, path_t );

  }

  public cancelInstance(  instance: CommandInstance, cfg?: ExcClientRequestCfg ) {

    let path_t = '/excollect_api/sites/%s/command_instances/%s/cancel';

    return this.runInstanceActionGet( instance, path_t );

  }


  private runInstanceActionGet( instance: CommandInstance, path_t: string, cfg?: ExcClientRequestCfg ) {

      return this.siteService
                .getActiveSite()
                .pipe( 
                  switchMap( (site) => { 

                    let path = sprintf(path_t, site.id, instance.id );

                    return this.accessApi.get( path, cfg ) 
                  }),
                  map( (resp) => { return new CommandInstance(resp.data.message.data) }),
                  first()
                );
  }

}
