import { Component } from '@angular/core';
import {  BehaviorSubject, combineLatestWith, map, first, switchMap } from 'rxjs';
import { Host } from '../../models/host.model';
import { HostService } from '../../services/host.service';
import { ActivatedRoute } from '@angular/router';
import { SiteService } from '../../services/site.service';


@Component({
  selector: 'app-host-detail',
  templateUrl: './host-detail.component.html',
  styleUrl: './host-detail.component.css'
})
export class HostDetailComponent {

  public host!: BehaviorSubject<Host>

  constructor( 
    private HostService: HostService, 
    private route: ActivatedRoute,
    private siteService: SiteService
  ) {}

  ngOnInit(){

      // this.HostService.getHosts().pipe(first()).subscribe( (host) => {
      //     
      //     this.host$ = new BehaviorSubject<Host>(host);  

      //     //this.hosts$.next(hostCollection)
      // });

     // this.host$ = this.route.params
      //                        .pipe(
   //                               switchMap((params) => { this.HostService.getHost()  })
                              //)

      // this.route.params
      //           .pipe(
      //              combineLatestWith( this.siteService.isLoaded() ) ,
      //              map( ([params, loaded]) => params  ),
      //              first()
      //           )
      //           .subscribe( params => { 
      //                //if ( !this.route.firstChild?.snapshot.params['site_id'] ) {
      //                //}
      //             
      //                //console.log(params)
      //           }); 
  }


}
