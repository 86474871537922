<div class="container d-flex flex-column h-100">
  <div class="row px-4">
    <div class="col-8 pt-4">
        
    </div>
    <div class="col-4  px-4 pt-4 mt-auto">
      <!--
       <input class="form-control" list="datalistOptions" id="exampleDataList" placeholder="Type to search...">
      -->
    </div>
  </div>
  <div class="row p-4">
    <table class="table table-hover">
        <thead class="table-dark">
          <tr>
            <th class="col-3 text-center">Command</th>
            <th class="col-4 text-center">Label</th>
            <th class="col-1 text-center">Status</th>
            <th class="col-2 text-center">Created</th>
            <th class="col-2 text-center">Completed</th>
          </tr>   
        </thead>
        <tbody *ngIf="(commandInstances$ | async) as commandInstances; else loadingBlock" >
             <tr *ngFor="let instance of commandInstances.collection; " 
                 (click)="loadCommandInstanceDetail(instance)" 
             >
               <td class="col-3 text-center">{{ instance.command.name }}</td>
               <td class="col-4 text-center">{{ instance.label }}</td>
               <td class="col-1 text-center">{{ instance.status }}</td>
               <td class="col-2 text-center">{{ instance.localCreatedDateTime }}</td>
               <td class="col-2 text-center">{{ instance.localCompleteDateTime }}</td>
             </tr>   
        </tbody>
    </table>
  </div>
  <div *ngIf="(commandInstances$ | async) as commandInstances" class="row mt-auto mx-auto">
    <app-pager [collection]="commandInstances" (newPage)="loadPage($event)"></app-pager>
  </div>
  <ng-template #loadingBlock>
    <p>Loading...</p>
  </ng-template>
  <ng-template #waitRegBlock>
     <td>Awaiting Registration</td>
  </ng-template>

</div>
