import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ContainerComponent } from './components/container.component';

import { RouterModule, Routes } from '@angular/router';
//import { NavbarComponent } from './components/navbar.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { UserSettingsComponent } from './components/user-detail/user-settings.component';

const routes: Routes = [
  { path: 'account', component: ContainerComponent,
    children: [
      {
        path:'', redirectTo: 'users', pathMatch: 'full' 
      },
      {
        path: 'users', component: UsersListComponent
      },
      {
        path: 'users/:user_id', component: UserDetailComponent,
        children: [
           {
            path: '', redirectTo: 'settings', pathMatch: 'full'
           },
           {
            path: 'settings', component: UserSettingsComponent
           },
        ]

      }

    ]
  },
];

//  imports: [RouterModule.forRoot(routes)],
//  exports: [RouterModule]


@NgModule({
  declarations: [
    ContainerComponent,
    SideNavComponent,
    UsersListComponent,
    UserDetailComponent,
    UserSettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AccountModule { }
