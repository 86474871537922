<div class="col-12 h-100">
  <div class="row">
    <div class="col">
    
    </div>
  </div>
  <div class="row">
    <table class="table table-hover mb-0">
        <thead class="table-dark">
          <tr>
            <th class="col-md-3  text-center">DateTime</th>
            <th class="col-md-2  text-center">Level</th>
            <th class="col-md-7  text-center">Text</th>
          </tr>   
        </thead>
    </table>
    <div class="h-100 overflow-auto px-0" style="height: 90% !important">
      <table class="table table-hover mb-0">
        <tbody *ngIf="(hostJobLogs$ | async) as hostJobLogs; else loadingBlock">
          <tr *ngFor="let hostJobLog of hostJobLogs.collection">
            <td class="col-md-3  text-center ">{{ hostJobLog.localDateTime }}</td>
            <td class="col-md-2  text-center">
                <div class="text-bg-primary mx-3 mt-2"
                    [class]="levelClass(hostJobLog)" 
                >
                {{ hostJobLog.level }}
                </div>
            </td>
            <td class="col-md-7  text-center" 
                style="white-space: pre-line; line-height: 1.0em;">
                {{ hostJobLog.text }}
            </td>
          </tr>   
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #loadingBlock>
  <p>Loading...</p>
</ng-template>
