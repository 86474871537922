import { Component, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import { filter, switchMap, BehaviorSubject, takeUntil, Subscription, Subject, timer, tap, forkJoin, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CommandInstanceService } from '../../services/command-instance.service';
import { ApiCollection } from '../../../../shared/model/api-coillection.model';
import { HostJob, HostJobStub } from '../../models/host-job.model';
import { HostJobService } from '../../services/host-job.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-instance-hosts-tab',
  templateUrl: './instance-hosts-tab.component.html',
  styleUrl: './instance-hosts-tab.component.css'
})
export class InstanceHostsTabComponent {

  //@ViewChild("hostJobsList") elHostJobs!: ElementRef;

  public hostJobs$!: BehaviorSubject<ApiCollection<HostJob>>;
  public currentHostJobSub!: Subscription;
  public currentHostJob!:  HostJob;
  //public currentHostJob$:  BehaviorSubject<HostJob> = new BehaviorSubject<HostJob>(new HostJobStub);

  private refreshTimer!: Observable<number>;
  private stopRefresh!: Subject<Boolean>;


  constructor(
    private CommandInstanceService: CommandInstanceService, 
    private HostJobService: HostJobService,
    private route: ActivatedRoute,
    private router: Router,
  ) {

  }

  ngOnInit(){
    // hostJobsList
    //console.log('init');
    this.refreshTimer = timer(environment.host_jobs_refresh,environment.host_jobs_refresh);
    this.stopRefresh = new Subject<Boolean>();


    this.HostJobService.clearCurrentHostJob();

    this.route.params
              .pipe(
                filter( params => params['instance_id'] ),
                switchMap((params) => { 
                  //console.log(params)
                  return this.CommandInstanceService.getCurrentInstance(params['instance_id'])  
                }),
                switchMap((instance) => { 
                  let cfg = { searchAttrs: { order_by: { asc: 'status' } } };

                  if ( instance.status !== 'queuing' ) {
                    this.stopRefresh.next(true);
                  }

                  return this.CommandInstanceService.getJobs(instance, cfg)  
                })
              )
              .subscribe( jobs => {
                     if (!this.hostJobs$ ) {
                        this.hostJobs$ = new BehaviorSubject<ApiCollection<HostJob>>(jobs)
                    }
              });

    this.currentHostJobSub = this.HostJobService.currentHostJob$
                                 .pipe(
                                    filter( currentHost => !currentHost.is_stub )
                                 )
                                 .subscribe( currentHostJob => { 

                                     // if we are starting from the host job child 
                                     // route nothing will have set currentHostJob
                                     // or the hostJob collection observable.
                                     if ( this.currentHostJob?.id !== currentHostJob.id ) {
                                         if ( currentHostJob.collection ) {
                                            if (this.hostJobs$) {
                                               this.hostJobs$.next(currentHostJob.collection);
                                            }
                                            else {
                                               this.hostJobs$ = new BehaviorSubject<ApiCollection<HostJob>>(currentHostJob.collection)
                                            }
                                         }

                                        //console.log('new current HostJob '+ currentHostJob.id );
                                        this.currentHostJob = currentHostJob;
                                        //
                                     }
                                 })

    this.refreshTimer
        .pipe(
          tap(() => {
            let instance = this.CommandInstanceService.currentInstance$.value;

            if ( instance.status !== 'queuing' ) {
              //console.log(instance.status);
              this.stopRefresh.next(true);
              this.stopRefresh.complete();
            }

          }),
          takeUntil(this.stopRefresh),
          switchMap((int) => { 
            let instance = this.CommandInstanceService.currentInstance$.value;
            let cur_jobs = this.hostJobs$.value; 

            let cfg = { searchAttrs: cur_jobs.searchAttrs, searchArgs: cur_jobs.searchArgs };

            return forkJoin([
                 this.CommandInstanceService.getInstance(instance.uriKey),
                 this.CommandInstanceService.getJobs(instance, cfg)  
            ])
          }),
        )
        .subscribe( ([instance, hostJobs]) => {
           this.hostJobs$.next(hostJobs);
           this.CommandInstanceService.setCurrentInstance(instance)

           if ( instance.status !== 'queuing' ) {
             //console.log(instance.status);
             this.stopRefresh.next(true);
             this.stopRefresh.complete();
           }

        });

      
  }

  // TODO probably should be named toggleOpenHostJob
  openHostJob(hostJob: HostJob) {

    // Closing current open job and navigate back
    // to jobs list.
    if ( this.currentHostJob?.id === hostJob.id ) {
       this.currentHostJob = new HostJobStub();
       this.router.navigate([ '../hosts' ], { relativeTo: this.route });
    }
    // Update current job to trigger opening accordian
    // and navigate to job child route.
    else {
        this.currentHostJob = hostJob;

        this.router.navigate([ hostJob.uriKey ], { relativeTo: this.route });
    }
  }

  hostJobOpen(hostJob: HostJob) {
      //console.log('called for '+ hostJob.id +' '+ this.currentHostJob?.id );
      return this.currentHostJob?.id === hostJob.id;
  }

  statusClass(hostJob: HostJob) {
    let status = hostJob.status;
    let statusMap: { [name: string]: string } = {
        'error'    : 'text-bg-danger',
        'running'  : 'text-light blinking_running',
        'paused'   : 'text-bg-info text-light',
        'queued'   : 'text-light blinking_queued',
        'deferred' : 'text-bg-warning',
        'init'     : 'text-bg-secondary',
        'complete' : 'text-bg-light text-dark',
        'canceled' : 'text-bg-dark'
    }

    return statusMap[status]
  }


  ngOnDestroy() {
     //console.log('destory');
     this.currentHostJobSub.unsubscribe();
     this.stopRefresh.next(true);
     this.stopRefresh.complete();

  }

  loadPage( page: number ) {
      let hostJobs = this.hostJobs$.value;

      let cfg = { searchAttrs: { 
                    limit:     hostJobs.searchAttrs.limit, 
                    order_by:  hostJobs.searchAttrs.order_by,
                    page:      page 
                  } 
                };

      this.CommandInstanceService
          .currentInstance$
          .pipe(
            switchMap((instance) => { 
              return this.CommandInstanceService.getJobs(instance, cfg)  
            })
          )
          .subscribe( jobs => {
               this.hostJobs$.next(jobs)
          });

  }


}
